import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Context as SearchConditionContext } from '../provider/SearchConditionProvider'

const SearchFilterLabels = () => {
  const searchCondition = useContext(SearchConditionContext)!

  const [searchJournalNames, setSearchJournalNames] = useState<string[]>([])
  const [searchAuthorNames, setSearchAuthorNames] = useState<string[]>([])
  const [searchPublishYears, setSearchPublishYears] = useState<string[]>([])
  const [searchKeywords, setSearchKeywords] = useState<string[]>([])
  const [searchSources, setSearchSources] = useState<string[]>([])
  const [searchCategoryPaths, setSearchCategoryPaths] = useState<string[]>([])

  useEffect(() => {
    setSearchCategoryPaths(searchCondition.latestSnapshot.categories.map((x) => x.value))
    setSearchJournalNames(searchCondition.latestSnapshot.journals.map((x) => x.value))
    setSearchAuthorNames(searchCondition.latestSnapshot.authors.map((x) => x.value))
    setSearchPublishYears(searchCondition.latestSnapshot.publishYears.map((x) => x.value))
    setSearchKeywords(searchCondition.latestSnapshot.keywords.map((x) => x.value))
    setSearchSources(searchCondition.latestSnapshot.sources.map((x) => x.value))
  }, [searchCondition.latestSnapshot])
  const isSearchExpression = useCallback(() => {
    return (
      searchCondition.searchText.trim().startsWith('(') &&
      searchCondition.searchText.trim().endsWith(')')
    )
  }, [searchCondition.searchText])
  return (
    <div className="search-condition above">
      {searchCondition.searchText && searchCondition.searchText.trim() !== '' && (
        <dl key="search-text">
          <dt>{isSearchExpression() ? '검색식' : '검색어'}</dt>
          <dd>{searchCondition.searchText}</dd>
        </dl>
      )}
      {searchCategoryPaths.length > 0 && (
        <dl key="search-category">
          <dt>분야</dt>
          <dd>{searchCategoryPaths.join(' OR ')}</dd>
        </dl>
      )}
      {searchJournalNames.length > 0 && (
        <dl key="search-journal">
          <dt>저널</dt>
          <dd>{searchJournalNames.join(' OR ')}</dd>
        </dl>
      )}
      {searchAuthorNames.length > 0 && (
        <dl key="search-author">
          <dt>저자</dt>
          <dd>{searchAuthorNames.join(' OR ')}</dd>
        </dl>
      )}
      {searchPublishYears.length > 0 && (
        <dl key="search-publish-year">
          <dt>연도</dt>
          <dd>{searchPublishYears.join(' OR ')}</dd>
        </dl>
      )}
      {searchKeywords.length > 0 && (
        <dl key="search-keyword">
          <dt>주제</dt>
          <dd>{searchKeywords.join(' OR ')}</dd>
        </dl>
      )}
      {searchSources.length > 0 && (
        <dl key="search-source">
          <dt>출처</dt>
          <dd>{searchSources.join(' OR ')}</dd>
        </dl>
      )}
    </div>
  )
}

export default SearchFilterLabels
