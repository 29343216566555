import React, { useCallback, useMemo, useState } from 'react'
import { createProject } from '../repository/TactoProjectRepository'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

type Props = {
  fetchProjects: () => void
  closeModal: () => void
}

const CreateProject = ({ fetchProjects, closeModal }: Props) => {
  const [name, setName] = useState<string>('')
  const [description, setDescription] = useState<string>('')

  const disabledCreateButton = useMemo(() => {
    return !name
  }, [name])

  const onCreateProject = useCallback(() => {
    if (disabledCreateButton) {
      return
    }
    createProject({
      name,
      description,
    }).then(fetchProjects)
    setName('')
    setDescription('')
    closeModal()
  }, [disabledCreateButton, closeModal, name, description, fetchProjects])

  return (
    <div className="project-creation-modal">
      <div className="title">새로운 프로젝트</div>
      <div className="item">
        <div className="label">프로젝트 이름</div>
        <input
          className="project-name"
          value={name}
          onChange={(e) => setName(e.currentTarget.value)}
          placeholder="신규 프로젝트 이름"
        />
      </div>
      <div className="item">
        <div className="label">프로젝트 설명</div>
        <textarea
          className="project-description"
          value={description}
          onChange={(e) => setDescription(e.currentTarget.value)}
          placeholder="프로젝트 설명"
        />
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="만들기" onClick={onCreateProject} />
      </div>
    </div>
  )
}

export default CreateProject
