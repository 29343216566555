const map = new Map<string, string[]>()
map.set('전체', [''])
map.set('A-B', ['A', 'B'])
map.set('C-D', ['C', 'D'])
map.set('E-F', ['E', 'F'])
map.set('G-H', ['G', 'H'])
map.set('I-J', ['I', 'J'])
map.set('K-L', ['K', 'L'])
map.set('M-N', ['M', 'N'])
map.set('O-P', ['O', 'P'])
map.set('Q-R', ['Q', 'R'])
map.set('S-T', ['S', 'T'])
map.set('U-V', ['U', 'V'])
map.set('W-X', ['W', 'X'])
map.set('Y-Z', ['Y', 'Z'])
map.set('가', ['가'])
map.set('나', ['나'])
map.set('다', ['다'])
map.set('라', ['라'])
map.set('마', ['마'])
map.set('바', ['바'])
map.set('사', ['사'])
map.set('아', ['아'])
map.set('자', ['자'])
map.set('차', ['차'])
map.set('카', ['카'])
map.set('타', ['타'])
map.set('파', ['파'])
map.set('하', ['하'])

export const headCharacterMap = map
