type Props = {
  isOpen: boolean
  close: () => void
}

const PrivateTerms = ({ isOpen, close }: Props) => {
  return (
    <div className={`pop-wrap ${isOpen ? 'on' : ''}`} id="privacy">
      <div className="pop-window">
        <h1>
          <span>개인정보 수집 및 이용</span>
        </h1>
        <div className="pop-cnt">
          <div className="doc">
            <h1>개인정보의 수집목적 및 이용목적</h1>
            <p>
              ‘개인정보’라 함은 생존하는 개인에 관한 정보로서, 정보에 포함되어 있는 성명, 전자우편
              등의 사항에 의하여 개인을 식별할 수 있는 정보(정보만으로는 특정 개인을 식별할 수
              없더라도 다른 정보와 용이하게 결합하여 식별할 수 있는 것을 포함)를 말합니다.
            </p>
            <p>
              회사는 홈페이지를 통해 회원이 자발적이고 구체적으로 기입할 때만 개인정보를 수집하고
              있으며 향후 각 서비스별로 개인정보를 추가 수집할 수도 있습니다.
            </p>
            <h2>가. 수집목적</h2>
            <ol>
              <li>
                <p>
                  닉네임, 아이디, 비밀번호, 생년월일 : 서비스 이용에 따른 민원사항의 처리를 위한
                  본인식별, 연령제한 서비스의 제공
                </p>
              </li>
              <li>
                <p>
                  전자우편 주소 : 개인회원 인증, 본인식별, 고지사항 전달, 본인 의사 확인, 불만처리
                  등 의사소통 경로 확보, 새로운 원문이나 서비스, 이벤트 정보 등 최신 정보 안내
                </p>
              </li>
              <li>
                <p>그 외 선택항목 : 개인맞춤서비스를 제공하기 위한 자료</p>
              </li>
            </ol>
            <h2>나. 수집방법</h2>
            <ol>
              <li>
                <p>홈페이지 회원가입, 이벤트 응모, 생성정보 수집 등을 통한 수집</p>
              </li>
              <li>
                <p>회원정보수정</p>
              </li>
              <li>
                <p>전화, 고객센터 문의하기</p>
              </li>
            </ol>
          </div>
        </div>
        <button type="button" className="close" aria-label="close-privacy" onClick={close}>
          닫기
        </button>
      </div>
    </div>
  )
}

export default PrivateTerms
