import React from 'react'

const ServiceGuide = () => (
  <section className="content">
    <h1 className="normal-head">서비스 이용방법</h1>
    <div className="guide-wrap">
      <h2 className="normal-head">메인화면</h2>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-01.png" />
      </p>
      <p>
        ① 메인 메뉴 영역
        <br />
        "학술논문"은 KDiscovery에서 서비스하는 논문 목록을 178개 학문분야별로 표시합니다.
        <br />
        "저널"은 KDiscovery에서 서비스하는 논문의 저널 목록을 가나다순 및 ABC 순으로 표시합니다.
        <br />
        "저자"은 KDiscovery에서 서비스하는 논문의 저자 목록을 가나다순 및 ABC 순으로 표시합니다.
        <br />
        "발행기관"은 K-Discovery에서 서비스하는 논문의 발행기관 목록을 가나다순 및 ABC 순으로
        표시합니다.
        <br />
        "트렌드 이슈"는 178개 학문분야별 최근 6년간의 트렌드 이슈를 분석합니다.
      </p>
      <p>
        ② 검색
        <br />
        검색어를 입력하면 일반적인 통합검색을 실행합니다.
        <br />
        검색옵션(논문, 저자, 저널, 발행기관) : 각각 해당 영역에서 검색어가 포함된 결과를 표시합니다.
        예컨대 저널을 선택하고 검색어를 입력하면 저널 이름에 "경제"가 포함된 논문의 목록을 결과로
        표시합니다.
      </p>
      <p>
        ③ 상세검색
        <br />
        AND / OR / NOT 연산자를 사용하여 검색할 수 있습니다.
      </p>
      <p>④ 통계 영역 Kdiscovery에서 서비스되는 저널수, 논문수, 발행기관수, 특허수를 표시합니다.</p>
      <h2 className="normal-head">학술논문</h2>
      <h3 className="normal-head">논문 목록</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-02-01.png" />
      </p>
      <p>
        KDiscovery에서 서비스되는 학술논문을 9개 대분류, 178개 소분류의 학문분야로 카테고리화하여
        목록을 표시합니다.
      </p>
      <p>왼쪽 트리에서 학문분야를 선택하면 그 분야의 논문 목록이 오른쪽에 표시됩니다.</p>
      <p>
        로그인했을 경우 ① 의 보관함 기능을 사용할 수 있습니다. 로그인하지 않았을 경우 버튼을
        클릭하면 로그인이 필요하다는 메시지가 표시됩니다. 원하는 논문을 체크하고 보관함에 저장을
        선택하면 “내서재”에 논문 서지정보가 저장됩니다. 내서재 기능은 회원 가입하여 로그인 햇을
        때에만 사용할 수 있습니다.
      </p>
      <p>
        ② 논문 목록은 논문제목이나 발행연도 순 또는 역순으로 정렬시켜 조회할 수 있습니다. 또 한
        화면당 10, 20, 50, 100건씩 조회할 수 있습니다.
      </p>
      <p>
        ③ 논문 목록에는 논문제목, 출처(저널 및 발행연도), 저자, 초록, 키워드가 표시되고 상세보기
        버튼과 사이트로 이동 버튼이 표시됩니다.
      </p>
      <h3 className="normal-head">논문 상세 정보</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-02-02.png" />
      </p>
      <p>① 에서 이 논문이 게재된 저널과 저널의 학문분야가 표시됩니다.</p>
      <p>
        로그인했을 경우 보관함 기능 ② 를 이용할 수 있습니다. 로그인하지 않았을 경우 로그인이
        필요하다는 메시지가 표시됩니다.
      </p>
      <p>
        ③ 에는 논문의 서지사항이 표시됩니다. 제목, 부제목, 출처, 저널명, 발행년도, 권호, 페이지,
        DOI, 저자, 초록, 키워드가 표시됩니다. 권호와 페이지가 확인되지 않았을 경우에는 표시되지
        않습니다. 키워드를 클릭하면 그 키워드로 검색을 실행합니다.
      </p>
      <p>
        ④ 에서 원문을 볼 수 있을 경우에는 원문보기 버튼이 표시되고 그렇지 않을 경우에는 표시되지
        않습니다. 원문을 다운로드 받을 수 있을 경우에는 원문 다운로드 버튼이 표시됩니다. "사이트로
        이동"은 이 논문이 발행된 사이트로 이동합니다. 로그인했을 경우 "북마크" 기능을 이용할 수
        있습니다.
      </p>
      <p>
        이 저자의 다른 논문 ⑤ 은 논문의 저자가 식별되었을 경우 같은 저자의 다른 논문들을 목록으로
        제공합니다. 이 저자의 다른 논문을 클릭하면 그 저자의 상세정보로 이동합니다.
      </p>
      <h2 className="normal-head">저널</h2>
      <h3 className="normal-head">저널 목록</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-03-01.png" />
      </p>
      <p>
        저널은 KDiscovery에서 서비스되는 주요 저널을 가나다순 또는 ABC 순으로 조회할 수 있습니다.
      </p>
      <p>①에서 조건을 선택하면 해당되는 저널이 표시됩니다.</p>
      <p>조회된 저널 목록②에서 저널의 명칭, 발행기관(학회), ISSN, 발행국가, 논문수가 표시됩니다.</p>
      <h3 className="normal-head">저널 검색</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-03-02.png" />
      </p>
      <p>
        저널목록의 검색창에 단어를 입력하고 검색을 실행하면 그 단어가 저널제목이나 발행기관에 포함된
        저널을 조회할 수 있습니다.
      </p>
      <p className="note">
        현재 "가"를 선택한 상태에서 검색을 실행하면 선택된 조건이 초기화되고 전체 저널 목록에서
        검색을 실행합니다.
      </p>
      <h3 className="normal-head">저널 상세 정보</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-03-03.png" />
      </p>
      <p>
        저널을 선택하면 해당 저널의 논문 목록을 볼 수 있습니다. 왼쪽 위에 저널 이미지가 있을
        경우에는 이미지가 표시되고, 그 외에 발행기관, 발행주기, ISSN이 표시됩니다.
      </p>
      <p>
        저널은 발행연도별로 목록이 표시됩니다. ① 에서 연도를 선택하면 해당연도에 발행된 논문의
        목록이 오른쪽에 표시되며 ② 에서 현재 목록의 발행연도를 표시합니다.
      </p>
      <p>
        논문 목록을 ③ 에서 논문제목이나 발행연도 순 또는 역순으로 정렬시켜 조회할 수 있습니다. 또 한
        화면당 10, 20, 50, 100건씩 조회할 수 있습니다.
      </p>
      <h2 className="normal-head">발행기관</h2>
      <h3 className="normal-head">발행기관 목록</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-04-01.png" />
      </p>
      <p>발헹기관은 학회와 출판사를 포함하고 있습니다.</p>
      <p>① 에서 선택하면 선택한 조건으로 발행기관 목록이 표시됩니다.</p>
      <p>② 에서 발행기관을 검색할 수 있습니다.</p>
      <p>③ 에서 발행기관은 한 화면에 10건씩 페이지네이션되어 표시됩니다.</p>
      <p className="note">
        현재 "가"를 선택한 상태에서 검색을 실행하면 선택된 조건이 초기화되고 전체 저널 목록에서
        검색을 실행합니다.
      </p>
      <h3 className="normal-head">발행기관의 저널 목록</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-04-02.png" />
      </p>
      <p>
        발행기관의 를 클릭하면 그 발행기관의 저널 목록이 표시됩니다. 저널을 클릭하면 저널 상세정보로
        이동합니다.
      </p>
      <h2 className="normal-head">트렌드 이슈</h2>
      <h3 className="normal-head">초기 화면</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-05-01.png" />
      </p>
      <p>
        트렌드 이슈는 학문분야별로 최근 6년간 (2018년~2023년 )의 키워드를 분석하여 연도별로 핫
        이슈가 되는 상위 키워드 100위까지 한 눈에 보여줍니다.
      </p>
      <p>초기화면에서는 학문분야별로 대표적인 핫 이슈를 보여줍니다.</p>
      <h3 className="normal-head">학문 분야별 트렌드 이슈</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-05-02.png" />
      </p>
      <p>
        학문 분야를 선택하면 그 분야의 최근 6년(2018년~2023년)간 핫 이슈 연도별 상위 100건을
        보여줍니다.
      </p>
      <p>① 에서 현재 선택한 학문 분야와 선택 조건을 보여줍니다.</p>
      <p>② 에서 한국어, 영어, 전체를 선택할 수 있습니다.</p>
      <p>③ 에서 키워드를 클릭하면 팝업창으로 키워드를 사용한 논문 목록을 보여줍니다.</p>
      <h3 className="normal-head">키워드 상세 정보</h3>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-manual-05-03.png" />
      </p>
      <p>
        트렌드 이슈에서 키워드를 클릭하면 팝업창으로 키워드 상세정보를 보여줍니다. 키워드 상세정보는
        키워드 정보와 논문 목록으로 구성되어 있습니다.
      </p>
      <p>① 에서 현재 선택한 키워드와 조건을 보여줍니다.</p>
      <p>② 에서 키워드 정보를 팝업창으로 보여줍니다. 키워드 정보는 현재 준비중입니다.</p>
      <p>③ 에서 논문 제목을 선택하면 별도탭으로 논문 상세정보로 이동합니다.</p>
      <p>④ 에서 저널 제목을 선택하면 별도탭으로 저널 상세정보로 이동합니다.</p>
    </div>
  </section>
)

export default ServiceGuide
