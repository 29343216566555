import Article from '../domain/Article'
import ArticleExportConverter from './ArticleExportConverter'
import { forceString, isNotEmpty } from '../../common/utils/TextUtils'

export interface BibTexFormatArticle {
  author: string
  title: string
  journal: string
  keyword: string
  abstract: string
  publisher: string
  year: string
  researchAreas: string
}

export default class BibTexExportConverter extends ArticleExportConverter {
  convert(article: Article): string {
    const converted = this.convertToModel(article)
    const propArr = [
      this.getPropertyString('Author', converted.author),
      this.getPropertyString('Title', converted.title),
      this.getPropertyString('Journal', converted.journal),
      this.getPropertyString('Keyword', converted.keyword),
      this.getPropertyString('Abstract', converted.abstract),
      this.getPropertyString('Publisher', converted.publisher),
      this.getPropertyString('Year', converted.year),
      this.getPropertyString('Research-Areas', converted.researchAreas),
    ]
    return `@article{${propArr.filter((prop) => isNotEmpty(prop)).join(',')}}`
  }

  convertToModel(article: Article): BibTexFormatArticle {
    const authorNames = this.getValidAuthorNames(article)
    const keywords = this.getJoinedKeywords(article, ', ')
    const categoryNames = article.categoryNames.filter((name) => name.trim() !== '')
    return {
      title: forceString(article.entireTitle),
      journal: forceString(article.articleSource.journal),
      author: authorNames.length > 0 ? authorNames.join(';') : '',
      publisher: forceString(article.articleSource.publisher),
      year: forceString(article.articleSource.publishYear),
      abstract: forceString(article.entireAbstracts),
      keyword: forceString(keywords),
      researchAreas: categoryNames.length > 0 ? categoryNames.join(',') : '',
    }
  }

  private getPropertyString(propName: string, value: string) {
    return value.trim() !== '' ? `${propName}={${value}}` : ''
  }
}
