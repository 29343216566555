import AxiosUserStorageRepository, { ArticlesParams } from './AxiosUserStorageRepository'
import Page from '../../common/domain/Page'

import Article from '../../article/domain/Article'

export default interface UserStorageRepository {
  addArticles(storageId: number, articles: number[]): Promise<void>

  deleteArticles(storageId: number, articles: number[]): Promise<void>

  getArticlesInStorage(
    storageId: number,
    searchText: string,
    params: ArticlesParams
  ): Promise<Page<Article>>
}

export const userStorageRepository = new AxiosUserStorageRepository()
