import TopicTreeNode from './TopicTreeNode'

export const initialTactoProjectDetail: TactoProjectDetail = {
  id: 0,
  name: '',
  description: '',
  rootNode: null,
  memoCount: 0,
  articleCount: 0,
  materialCount: 0,
}

interface TactoProjectDetail {
  id: number
  name: string
  description: string
  rootNode: TopicTreeNode | null
  memoCount: number
  articleCount: number
  materialCount: number
}

export default TactoProjectDetail
