import React from 'react'
import { useNavigate } from 'react-router-dom'
import Button from '../../common/component/Button'
import { serviceDescription } from '../../common/constant/ServiceConstant'

function JoinRequested() {
  const navigate = useNavigate()
  return (
    <main className="member">
      <hgroup aria-label="main-logo" onClick={() => navigate('/main')}>
        <h1>K·DISCOVERY</h1>
        <h2>{serviceDescription}</h2>
      </hgroup>
      <section>
        <h1>회원가입</h1>
        <p className="announce join-02">
          회원가입이 완료되었습니다.
          <br />
          메인 화면으로 이동 후 서비스를 이용해주세요.
        </p>
        <div className="buttons">
          <Button ariaLabel="main-button" onClick={() => navigate('/main')}>
            메인화면
          </Button>
        </div>
      </section>
    </main>
  )
}

export default JoinRequested
