import Article from '../domain/Article'
import ArticleExportConverter from './ArticleExportConverter'
import { isNotEmpty } from '../../common/utils/TextUtils'

export interface EndNoteFormatArticle {
  pt: string
  au: string
  ti: string
  so: string
  de: string
  ab: string
  pu: string
  py: string
  sc: string
}

export default class EndNoteExportConverter extends ArticleExportConverter {
  convert(article: Article): string {
    const model = this.convertToModel(article)
    const propArr = [
      model.pt,
      model.au,
      model.ti,
      model.so,
      model.de,
      model.ab,
      model.pu,
      model.py,
      model.sc,
    ]
    return propArr.filter((prop) => isNotEmpty(prop)).join('\n')
  }

  convertToModel(article: Article): EndNoteFormatArticle {
    const authorNames = this.getValidAuthorNames(article)
    const keywords = this.getJoinedKeywords(article, ', ')
    const categoryNames = article.categoryNames.filter((name) => name.trim() !== '')
    return {
      pt: 'PT J',
      au: authorNames.length > 0 ? `AU ${authorNames.join('\n   ')}` : '',
      ti: this.getPropertyString('TI', article.entireTitle) ? `TI ${article.entireTitle}` : '',
      so: this.getPropertyString('SO', article.articleSource.journal),
      de: this.getPropertyString('DE', keywords),
      ab: this.getPropertyString('AB', article.entireAbstracts),
      pu: this.getPropertyString('PU', article.articleSource.publisher),
      py: this.getPropertyString('PY', article.articleSource.publishYear),
      sc: categoryNames.length > 0 ? `SC ${categoryNames.join(', ')}` : '',
    }
  }

  private getPropertyString(propName: string, value: string) {
    return isNotEmpty(value) ? `${propName} ${value}` : ''
  }
}
