import TopicTreeNode from '../domain/TopicTreeNode'
import { drawTreeAside } from '../util/topic-tree'
import { RightSection } from './TopicTreeManagement'

type Props = {
  rootNode?: TopicTreeNode | null
  selectedId: string
  onSelectTopic: (id: string) => void
  onSetCurrentRightSection: (rightSection: RightSection) => void
}

const EditableTopicTreeSection = ({
  rootNode,
  selectedId,
  onSelectTopic,
  onSetCurrentRightSection,
}: Props) => {
  return (
    <div aria-label="topic-tree-section" className="editable-topic-tree-section">
      {rootNode?.children ? (
        drawTreeAside(rootNode.children, selectedId, onSelectTopic, onSetCurrentRightSection)
      ) : (
        <div className="empty-info">새로운 토픽을 만들어주세요.</div>
      )}
    </div>
  )
}

export default EditableTopicTreeSection
