import { createContext, useState } from 'react'
import SelectedIdContext from '../domain/SelectedIdContext'

export const Context = createContext<SelectedIdContext | null>(null)

const SelectedIdProvider = ({ children }: { children: JSX.Element }) => {
  const [selectedId, setSelectedId] = useState<number>(0)

  return (
    <Context.Provider
      value={{
        selectedId,
        setSelectedId,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default SelectedIdProvider
