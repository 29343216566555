import React, { Fragment } from 'react'
import BbsItem from '../domain/BbsItem'
import BbsTableReportItem from './BbsTableReportItem'

type Props = {
  moveToDetail: (id: number) => void
  items: BbsItem[]
}

const BbsTableReports = ({ moveToDetail, items }: Props) => {
  return (
    <Fragment>
      {items.length === 0 && <div className="empty-items">게시글을 찾을 수 없습니다.</div>}
      {items.length > 0 && (
        <ul className="table-type bbs-list">
          {items.map((item) => (
            <BbsTableReportItem
              key={item.id}
              moveToDetail={() => moveToDetail(item.id)}
              bbsItem={item}
              isWideCategory={false}
            />
          ))}
        </ul>
      )}
    </Fragment>
  )
}

export default BbsTableReports
