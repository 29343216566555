import { useCallback, useContext, useEffect, useState } from 'react'

import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

import { getMemo, saveMemo } from '../repository/MaterialsRepository'
import Memo from '../domain/Memo'
import { Context } from '../provider/TactoProjectProvider'

type Props = {
  materialId: number
  materialTitle: string
  closeModal: () => void
}

const MaterialMemo = ({ materialId, materialTitle, closeModal }: Props) => {
  const { refreshItems } = useContext(Context)!
  const [memo, setMemo] = useState<Memo>({ content: '', id: 0, title: '' })

  useEffect(() => {
    getMemo(materialId).then((response) => setMemo(response))
  }, [materialId])

  const onChangeMemo = useCallback(
    (key: string, value: string) => {
      setMemo({ ...memo, [key]: value })
    },
    [memo]
  )

  const onSave = useCallback(() => {
    saveMemo(materialId, memo).then(refreshItems).finally(closeModal)
  }, [materialId, memo, refreshItems, closeModal])

  return (
    <div className="material-memo-modal">
      <div className="title">메모</div>
      <div className="material-title">{materialTitle}</div>
      <div className="item">
        <div className="label">제목</div>
        <input
          type="text"
          aria-label="title"
          value={memo.title}
          onChange={(e) => {
            onChangeMemo('title', e.target.value)
          }}
        />
      </div>
      <div className="item">
        <div className="label">내용</div>
        <textarea
          aria-label="content"
          value={memo.content}
          onChange={(e) => {
            onChangeMemo('content', e.target.value)
          }}
        />
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="저장" onClick={onSave} />
      </div>
    </div>
  )
}

export default MaterialMemo
