import { useState } from 'react'

type Props = {
  name: string
  description: string
  onChangeTopic: ({ name, description }: { name: string; description: string }) => void
}

const ModifyTopicSection = ({ name, description, onChangeTopic }: Props) => {
  const [topicName, setTopicName] = useState<string>(name)
  const [topicDescription, setTopicDescription] = useState<string>(description)
  return (
    <div className="modify-topic-section">
      <div className="title">토픽 편집</div>
      <div className="modify-topic-items">
        <div className="modify-topic-item">
          <div className="label">토픽 이름</div>
          <input
            aria-label="topic-name"
            className="text-box"
            type="text"
            value={topicName}
            onChange={(e) => setTopicName(e.target.value)}
          />
        </div>
        <div className="modify-topic-item">
          <div className="label">토픽 설명</div>
          <input
            aria-label="topic-description"
            className="text-box"
            type="text"
            value={topicDescription}
            onChange={(e) => setTopicDescription(e.target.value)}
          />
        </div>
        <div className="buttons">
          <button
            className="button"
            onClick={() =>
              onChangeTopic({
                name: topicName,
                description: topicDescription,
              })
            }
          >
            확인
          </button>
        </div>
      </div>
    </div>
  )
}

export default ModifyTopicSection
