import axios from 'axios'
import { MainRepository } from './MainRepository'
import { ResourceTotalCount } from '../domain/ResourceTotalCount'
import { HomeSlideTab } from '../domain/HomeSlideTab'

class AxiosMainRepository implements MainRepository {
  async getResourceTotalCount(): Promise<ResourceTotalCount> {
    const [journalResponse, articlesResponse, publishersResponse, patentResponse] =
      await Promise.all([
        axios.get('/app/api/journals/count'),
        axios.get('/app/api/articles/count'),
        axios.get('/app/api/publishers/count'),
        axios.get('/app/api/patents/count'),
      ])

    return Promise.resolve({
      journalsCount: journalResponse.data,
      articlesCount: articlesResponse.data,
      publishersCount: publishersResponse.data,
      patentsCount: patentResponse.data,
    })
  }

  async getHomeSlideImages(): Promise<HomeSlideTab[]> {
    const { data } = await axios.get('/app/api/home/tabs')
    return data
  }
}

export default AxiosMainRepository
