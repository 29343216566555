import React, { ForwardedRef, forwardRef } from 'react'
import ValidateInput from './ValidateInput'
import { validateEmailFormat } from '../utils/FormatChecker'

const InputEmail = forwardRef(
  (
    props: { validationCallback?: () => void; value?: string; readOnly?: boolean },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const emailValidator = (email: string) => {
      if (props.validationCallback) {
        props.validationCallback()
      }
      return validateEmailFormat(email)
    }

    return (
      <ValidateInput
        ref={ref}
        label="email-input"
        type="text"
        placeholder="이메일 주소를 입력해주세요."
        value={props.value}
        readOnly={props.readOnly}
        validators={[
          {
            validator: emailValidator,
            errorMessage: '이메일 주소의 형식이 올바르지 않습니다.',
          },
        ]}
      />
    )
  }
)

export default InputEmail
