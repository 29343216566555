import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Location from '../../common/component/Location'
import Button from '../../common/component/Button'

import InformationMenu from '../component/InformationMenu'
import BbsItem from '../domain/BbsItem'
import { bbsRepository } from '../repository/BbsRepository'
import { BbsButton, BbsReportButton } from '../component/BbsProtectedButton'
import BbsItemComment from '../component/BbsItemComment'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { isNotEmpty } from '../../common/utils/TextUtils'

const INITIAL_BBS_ITEM: BbsItem = {
  id: 0,
  type: '',
  title: '',
  content: '',
  status: '',
  userId: 0,
  writer: '',
  createdDate: '',
  readCount: 0,
  commentCount: 0,
  comments: [],
}

const BbsItemDetail = () => {
  const { userInfo, isLoggedIn } = useContext(UserContext)!
  const navigate = useNavigate()
  const { id, type } = useParams()
  const commentTextarea = useRef<HTMLTextAreaElement>(null)
  const [bbsItem, setBbsItem] = useState<BbsItem>(INITIAL_BBS_ITEM)

  const getBbsItem = useCallback(() => {
    if (id) {
      bbsRepository.getBulletinBoardDetail(+id).then((item) => setBbsItem(item))
    }
  }, [id])

  const createComment = useCallback(() => {
    const commentText = commentTextarea.current?.value || ''
    if (id && isNotEmpty(commentText)) {
      bbsRepository
        .createComment({
          boardId: parseInt(id),
          content: commentText,
        })
        .then(() => {
          alert('댓글이 등록되었습니다')
          commentTextarea.current!.value = ''
          getBbsItem()
        })
    }
  }, [id, commentTextarea, getBbsItem])

  useEffect(() => {
    getBbsItem()
  }, [getBbsItem])

  const moveBack = () => {
    navigate(-1)
  }

  const location = useMemo(() => {
    switch (type) {
      case 'notices':
        return '공지사항'
      case 'faqs':
        return 'FAQ'
      case 'newses':
        return '뉴스'
      default:
        return '공지사항'
    }
  }, [type])

  const moveToEditor = useCallback(() => {
    navigate(`/bbs/edit/${type}?id=${id}`)
  }, [navigate, type, id])

  const EditButton = useMemo(
    () =>
      type === 'reports' ? (
        <BbsReportButton writerUserId={bbsItem.userId} onClick={moveToEditor}>
          수정
        </BbsReportButton>
      ) : (
        <BbsButton writerUserId={bbsItem.userId} onClick={moveToEditor}>
          수정
        </BbsButton>
      ),
    [type, bbsItem, moveToEditor]
  )

  const addCommentComponent = useMemo(() => {
    const isLoggedInUser = userInfo !== null && isLoggedIn
    const userHasRightPermission = userInfo?.level === 'EDITOR' || userInfo?.level === 'ADMIN'
    const userIsWriter = userInfo?.id === bbsItem.userId
    return isLoggedInUser && (userHasRightPermission || userIsWriter) ? (
      <>
        <textarea className="comment" ref={commentTextarea} placeholder="댓글 남기기" />
        <div className="button-wrap destro">
          <Button ariaLabel="list-button" onClick={createComment}>
            등록
          </Button>
        </div>
      </>
    ) : (
      <Fragment />
    )
  }, [userInfo, isLoggedIn, commentTextarea, createComment, bbsItem])

  const comments = useMemo(
    () =>
      type === 'reports' && bbsItem.comments ? (
        <ul>
          {bbsItem.comments.map((comment) => (
            <li key={comment.id}>
              <BbsItemComment
                writerName={comment.userName}
                content={comment.content}
                createdAt={comment.createdAt}
              />
            </li>
          ))}
        </ul>
      ) : (
        <Fragment />
      ),
    [type, bbsItem]
  )

  return (
    <main>
      <div className="container">
        <Location paths={[location]} />
        <div className="content-wrap">
          <InformationMenu />
          <section className="content">
            <h1 className="normal-head">{bbsItem.title}</h1>
            <div className="button-wrap destro">
              {EditButton}
              <Button ariaLabel="list-button" onClick={moveBack}>
                목록
              </Button>
            </div>
            <ul className="bbs-info">
              <li>{bbsItem.writer}</li>
              <li>{bbsItem.createdDate} 작성</li>
              <li>{bbsItem.readCount} 조회</li>
            </ul>
            <article className="bbs-view">
              <div
                className="ql-editor"
                dangerouslySetInnerHTML={{
                  __html: bbsItem.content!,
                }}
              ></div>
            </article>
            {addCommentComponent}
            {comments}
          </section>
        </div>
      </div>
    </main>
  )
}

export default BbsItemDetail
