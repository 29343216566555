type Props = {
  name: string
  onDeleteTopic: () => void
  onClose: () => void
}

const DeleteTopicSection = ({ name, onDeleteTopic, onClose }: Props) => {
  return (
    <div className="delete-topic-section">
      <div className="title">토픽 삭제</div>
      <div className="delete-form-wrap">
        <div className="delete-form">
          <div className="delete-alert" />
          <div className="message">
            <span>{name}</span> 토픽을 삭제합니다.
          </div>
          <div className="warning">
            토픽을 삭제하면 하위 토픽을 포함한 해당 토픽의 모든 자료가 삭제됩니다.
          </div>
          <div className="buttons">
            <button className="button white" onClick={onDeleteTopic}>
              네, 삭제합니다.
            </button>
            <button className="button" onClick={onClose}>
              아니오, 삭제하지 않습니다.
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DeleteTopicSection
