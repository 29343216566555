import { MouseEvent, ReactNode } from 'react'

export type Props = {
  className?: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  ariaLabel?: string
  children?: ReactNode
}

const Button = (props: Props) => {
  return (
    <button
      className={`${props.className} normal`}
      onClick={props.onClick}
      aria-label={props.ariaLabel}
    >
      {props.children}
    </button>
  )
}

export default Button
