import React, { MouseEvent } from 'react'

export type Props = {
  name: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
}

const CancelButton = ({ name, onClick }: Props) => {
  return (
    <button className="tacto cancel" onClick={onClick}>
      {name}
    </button>
  )
}

export default CancelButton
