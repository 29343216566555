import Quill from 'quill'
import { bbsRepository } from '../../information/repository/BbsRepository'

export const quillModule = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    [{ font: [] }],
    ['link', 'image'],
    ['bold', 'italic', 'underline', 'strike'],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ direction: 'rtl' }, { align: [] }],
    [{ color: [] }, { background: [] }],
    ['clean'],
  ],
}

export const imageHandler = (editor: Quill) => () => {
  const input = document.createElement('input')
  input.setAttribute('type', 'file')
  input.setAttribute('accept', 'image/*')
  input.addEventListener('change', (event) => {
    const inputElement = event.target as HTMLInputElement
    const file = inputElement.files?.item(0)
    if (file) {
      const { index } = editor.getSelection(true)
      bbsRepository.uploadImage(file).then((url) => {
        editor.insertEmbed(index, 'image', url)
        editor.setSelection(index + 1, 1)
      })
    }
  })
  input.click()
  return input
}
