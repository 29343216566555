import React, { createContext, useCallback, useState } from 'react'
import { AdvancedSearchContextType } from '../domain/AdvancedSearchContextType'

export const Context = createContext<AdvancedSearchContextType | null>(null)
const AdvancedSearchModalProvider = ({ children }: { children: JSX.Element }) => {
  const [isOpen, setOpen] = useState<boolean>(false)

  const openModal = useCallback(() => {
    setOpen(true)
  }, [])

  const closeModal = useCallback(() => {
    setOpen(false)
  }, [])

  return <Context.Provider value={{ isOpen, openModal, closeModal }}>{children}</Context.Provider>
}

export default AdvancedSearchModalProvider
