import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'

import ModalWindow from '../../common/component/ModalWindow'
import Location from '../../common/component/Location'
import AllCheckBox from '../../common/component/AllCheckBox'
import CheckBox from '../../common/component/CheckBox'
import Button from '../../common/component/Button'
import Pagination from '../../common/component/Pagination'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import NiceSelect from '../../common/component/NiceSelect'
import { usePageable } from '../../common/hooks/usePageable'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { userStorageRepository } from '../../user/repository/UserStorageRepository'
import MyArticlesStorageBox from '../../user/component/MyArticlesStorageBox'

import { Context as CategoryContext } from '../provider/CategoryProvider'
import ArticleComponent from '../component/ArticleComponent'
import CategoryNavigator from '../component/CategoryNavigator'
import Article from '../domain/Article'
import ArticleCountSummary from '../domain/ArticleCountSummary'
import Category from '../domain/Category'
import ArticleCategoryResponse from '../domain/ArticleCategoryResponse'
import SearchFilterValue from '../domain/SearchFilterValue'
import { articleRepository } from '../repository/ArticleRepository'

type SelectedCategory = {
  id: number
  paths: string[]
}

function Articles() {
  const { pageInfo, setupPage } = usePageable()
  const { isLoggedIn } = useContext(UserContext)!
  const { categoryTree } = useContext(CategoryContext)!
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const [currentCategoryCounts, setCurrentCategoryCounts] = useState<ArticleCountSummary<number>[]>(
    []
  )
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<SearchFilterValue<number>>({ key: 10, value: '10개' })
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory | null>(null)
  const [instantCategoryTree, setInstanceCategoryTree] = useState<Category | null>(null)
  const [articles, setArticles] = useState<Article[]>([])

  const pageSizeOptions = useMemo(
    () =>
      new Map([
        ['10', '10개'],
        ['20', '20개'],
        ['50', '50개'],
        ['100', '100개'],
      ]),
    []
  )

  const openArticlesSaveModal = () => {
    if (!isLoggedIn) {
      global.alert('로그인이 필요합니다.')
      return
    }
    if (checkedItems.size === 0) {
      global.alert('보관함에 저장할 학술논문을 선택해주세요.')
      return
    }
    setModalOpen(true)
  }

  const successAddArticlesHandler = () => {
    alert('학술논문이 선택하신 보관함에 저장되었습니다.')
    setModalOpen(false)
    clearAllChecked()
  }

  const selectStorage = (storageId: number) => {
    userStorageRepository
      .addArticles(storageId, Array.from(checkedItems))
      .then(successAddArticlesHandler)
  }

  const getArticlesSuccessHandler = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    (_: number) => (articlesResponse: ArticleCategoryResponse) => {
      setArticles(articlesResponse.articles.content)
      setupPage(articlesResponse.articles, articlesResponse.articles.pageable.pageNumber)
      setCategory(articlesResponse.categoryCounts)
    },
    // eslint-disable-next-line
    [setupPage]
  )

  const setCategory = useCallback(
    (categoryCounts: ArticleCountSummary<number>[]) => {
      setCurrentCategoryCounts(categoryCounts)
      if (categoryTree !== null && categoryTree.children !== null) {
        const newCategoryTree: Category = {
          ...categoryTree!,
        }
        const categoryCountMap = new Map(categoryCounts.map((count) => [count.key, count.count]))
        newCategoryTree.children!.forEach((topLevel) => {
          let topLevelCount = categoryCountMap.has(topLevel.id)
            ? categoryCountMap.get(topLevel.id)!
            : 0
          topLevel.children?.forEach((nextLevel) => {
            nextLevel.articleCount = categoryCountMap.has(nextLevel.id)
              ? categoryCountMap.get(nextLevel.id)!
              : 0
            topLevelCount += nextLevel.articleCount
          })
          topLevel.articleCount = topLevelCount
        })
        setInstanceCategoryTree(newCategoryTree)
      }
    },
    [categoryTree]
  )

  const selectCategory = (id: number, paths: string[]) => {
    setSelectedCategory({
      id,
      paths,
    })
  }

  const moveToPage = useCallback(
    (currentPage: number) => {
      articleRepository
        .getArticlesInCategory(
          {
            categoryId: selectedCategory && selectedCategory.id,
          },
          {
            page: currentPage,
            size: pageSize.key,
          }
        )
        .then(getArticlesSuccessHandler(currentPage))
    },
    [selectedCategory, getArticlesSuccessHandler, pageSize]
  )

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [selectedCategory, moveToPage])

  useEffect(() => {
    setCategory(currentCategoryCounts)
  }, [categoryTree, currentCategoryCounts, setCategory])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageInfo])

  return (
    <main>
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <MyArticlesStorageBox isModifiable={false} selectStorage={selectStorage} />
      </ModalWindow>
      <div className="container">
        <Location
          paths={
            selectedCategory != null
              ? selectedCategory?.paths?.filter((item) => item !== 'ROOT')
              : ['전체']
          }
        />
        <h1 className="head">학술논문</h1>
        <div className="content-wrap someother">
          <CategoryNavigator
            key={1}
            categoryTree={instantCategoryTree?.children}
            selectCategory={selectCategory}
            showCount={true}
            markOnlyLeafNodeSelection={false}
          />
          <section className="content">
            <div className="result-summary">
              <div className="func">
                <AllCheckBox
                  isAllChecked={isAllChecked}
                  onAllCheckHandler={(e) => onAllCheckHandler(e, articles)}
                />
                <Button
                  className="keep"
                  ariaLabel="storage-save-button"
                  onClick={openArticlesSaveModal}
                >
                  보관함에 저장
                </Button>
              </div>
              <fieldset className="sort">
                <NiceSelect
                  ariaLabel="page-size"
                  options={pageSizeOptions}
                  onSelectCallback={(pageSize) => {
                    setPageSize({
                      key: parseInt(pageSize),
                      value: pageSizeOptions.get(pageSize)!,
                    })
                  }}
                  selectedValue={pageSize.value}
                />
              </fieldset>
            </div>
            <ul className="thesis-list above">
              {articles.map((article) => {
                return (
                  <div key={article.id} className="thesis-item">
                    <CheckBox
                      onChange={(event) => onCheckedHandler(event, article.id)}
                      className="sgl-check unit"
                      ariaLabel={`checkbox-${article.id}`}
                      isAllChecked={isAllChecked}
                      checked={checkedItems.has(article.id)}
                    />
                    <ArticleComponent article={article} />
                  </div>
                )
              })}
            </ul>
            <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
          </section>
        </div>
      </div>
    </main>
  )
}

export default Articles
