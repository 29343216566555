import Page from '../../common/domain/Page'
import Publisher from '../domain/Publisher'
import AxiosPublisherRepository from './AxiosPublisherRepository'

export interface PublisherRequest {
  page: number
  size: number
  searchName: string
  nation?: string | null
  headCharacter: string
}

export default interface PublisherRepository {
  getPublishers(params: PublisherRequest): Promise<Page<Publisher>>
}

export const publisherRepository = new AxiosPublisherRepository()
