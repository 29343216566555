import React, { useContext } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'

import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { isAuthenticatedUser, RuleParams } from '../utils/RouteRules'

type Props = {
  accessRule?: (params: RuleParams) => boolean
  redirectPath?: string
}

const PrivateRouter = ({ accessRule = isAuthenticatedUser, redirectPath = '/main' }: Props) => {
  const location = useLocation()
  const { isLoggedIn, userInfo } = useContext(UserContext)!
  return accessRule({ isLoggedIn, userInfo, path: location.pathname }) ? (
    <Outlet />
  ) : (
    <Navigate replace to={redirectPath} />
  )
}

export default PrivateRouter
