import axios from 'axios'

import Category from '../domain/Category'
import { CategoryRepository } from './CategoryRepository'

class AxiosCategoryRepository implements CategoryRepository {
  getCategoryTree = async (): Promise<Category> => {
    const { data } = await axios.get('/app/api/categories')
    return {
      ...data,
      children: this.parseChildren(data?.children),
    }
  }

  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  private parseChildren(children: any) {
    const result = new Map()
    if (!children) {
      return null
    }
    for (const key in children) {
      result.set(+key, {
        ...children[key],
        children: this.parseChildren(children[key].children),
      })
    }
    return result
  }
}

export default AxiosCategoryRepository
