import PageRequest from '../../common/domain/PageRequest'
import Page from '../../common/domain/Page'
import MyArticle from '../domain/MyArticle'
import { stubMyArticle } from '../../mock/stub/myArticle'

export const getMyArticles = async (
  projectId: number,
  keyword: string,
  pageRequest: PageRequest
): Promise<Page<MyArticle>> => {
  console.log(projectId)
  console.log(keyword)
  console.log(pageRequest)
  return {
    content: [stubMyArticle, { ...stubMyArticle, isCompleted: true }],
    pageable: { pageNumber: 0 },
    size: 10,
    totalElements: 2,
    totalPages: 1,
  }
}
