import axios from 'axios'

import UpdateUserInfoRequest from '../domain/request/UpdateUserInfoRequest'
import ChangePasswordRequest from '../domain/request/PasswordChangeRequest'
import CreateArticlesStorageRequest from '../domain/request/CreateArticlesStorageRequest'
import ModifyArticlesStorageRequest from '../domain/request/ModifyArticlesStorageRequest'
import UserInfo from '../domain/UserInfo'

import UserInfoRepository from './UserInfoRepository'

class AxiosUserInfoRepository implements UserInfoRepository {
  async getLoggedInUserInfo(): Promise<UserInfo> {
    const { data } = await axios.get('/app/api/user-info')
    return data
  }

  async updateUserInfo(request: UpdateUserInfoRequest): Promise<void> {
    const { nickName } = request
    try {
      await axios.put(`/app/api/user-info`, { nickName })
      return Promise.resolve()
    } catch (err) {
      const error = err as Error
      throw new Error(error.message)
    }
  }

  async deleteUserInfo(): Promise<void> {
    try {
      await axios.delete(`/app/api/user-info`)
      return Promise.resolve()
    } catch (err) {
      const error = err as Error
      throw new Error(error.message)
    }
  }

  async changeUserPassword(request: ChangePasswordRequest): Promise<void> {
    const { oldPassword, newPassword } = request
    try {
      await axios.put(`/app/api/user-info/password`, { oldPassword, newPassword })
      return Promise.resolve()
    } catch (err) {
      const error = err as Error
      throw new Error(error.message)
    }
  }

  async createArticlesStorage(request: CreateArticlesStorageRequest): Promise<void> {
    const { storageName } = request
    try {
      await axios.post(`/app/api/user-info/storages`, { storageName })
      return Promise.resolve()
    } catch (err) {
      const error = err as Error
      throw new Error(error.message)
    }
  }

  async deleteArticlesStorage(storageId: number): Promise<void> {
    return axios.delete(`/app/api/user-info/storages/${storageId}`)
  }

  async updateArticlesStorage(request: ModifyArticlesStorageRequest): Promise<void> {
    const { storageId, storageName } = request
    try {
      await axios.put(`/app/api/user-info/storages/${storageId}`, { storageName })
      return Promise.resolve()
    } catch (err) {
      const error = err as Error
      throw new Error(error.message)
    }
  }
}

export default AxiosUserInfoRepository
