import axios from 'axios'

import Page from '../../common/domain/Page'
import Author from '../domain/Author'
import AuthorRepository, { AuthorRequest } from './AuthorRepository'

class AxiosAuthorRepository implements AuthorRepository {
  async getAuthors(params: AuthorRequest): Promise<Page<Author>> {
    const { data } = await axios.get('/app/api/authors', {
      params,
    })
    return {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      size: data.size,
      pageable: {
        pageNumber: data.pageable.pageNumber,
      },
      content: data.content,
    }
  }

  async getAuthor(id: number): Promise<Author> {
    const { data } = await axios.get(`/app/api/author/${id}`)
    return data
  }
}

export default AxiosAuthorRepository
