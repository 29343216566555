import { useCallback, useState } from 'react'

type Props = {
  onCreateTopic: (topicName: string) => void
}

const CreateTopicSection = ({ onCreateTopic }: Props) => {
  const [topicName, setTopicName] = useState<string>('')

  const onCreateTopicHandler = useCallback(() => {
    if (!topicName) {
      return
    }
    onCreateTopic(topicName)
    setTopicName('')
  }, [topicName, onCreateTopic])

  return (
    <div className="create-topic-section">
      <div className="create-form-wrap">
        <div className="title">새 토픽 만들기</div>
        <div className="create-form">
          <div className="label">토픽 이름</div>
          <div className="text-box-wrap">
            <input
              style={{ border: '1px solid black' }}
              value={topicName}
              onChange={(event) => setTopicName(event.target.value)}
              aria-label="topic-name"
            />
            <button onClick={onCreateTopicHandler}>생성</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CreateTopicSection
