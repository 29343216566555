import Article from '../domain/Article'
import ArticleExportConverter from './ArticleExportConverter'

export interface ExcelFormatArticle {
  title: string
  journal: string
  authors: string
  publisher: string
  publishYear: string
  abstracts: string
  keywords: string
}

export default class ExcelExportConverter extends ArticleExportConverter {
  convert(article: Article): ExcelFormatArticle {
    return {
      title: article.entireTitle,
      journal: article.articleSource.journal,
      authors: article.authors
        .map((author) => this.getValidData(author.name, author.subName))
        .filter((authorName) => authorName.trim() !== '')
        .join(','),
      publishYear: article.articleSource.publishYear,
      publisher: article.articleSource.publisher,
      abstracts: article.entireAbstracts,
      keywords: this.getJoinedKeywords(article, ','),
    }
  }
}
