import React, { useCallback, useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import ModalWindow from '../../common/component/ModalWindow'
import Location from '../../common/component/Location'
import Button from '../../common/component/Button'
import CheckBox from '../../common/component/CheckBox'
import Pagination from '../../common/component/Pagination'
import AllCheckBox from '../../common/component/AllCheckBox'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import { usePageable } from '../../common/hooks/usePageable'

import MyArticlesStorageBox from '../../user/component/MyArticlesStorageBox'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { userStorageRepository } from '../../user/repository/UserStorageRepository'

import ArticleComponent from '../../article/component/ArticleComponent'
import ExportArticlesDropdown from '../../article/component/ExportArticlesDropdown'
import ArticleExporter from '../../article/utils/ArticleExporter'

import { FilterOptionSelector } from '../../article/component/FilterOptionSelector'
import Article from '../../article/domain/Article'
import ArticleJournalResponse from '../../article/domain/ArticleJournalResponse'
import ArticleCountSummary from '../../article/domain/ArticleCountSummary'
import { articleRepository } from '../../article/repository/ArticleRepository'

import JournalDetails from '../component/JournalDetails'
import Journal from '../domain/Journal'
import { journalRepository } from '../repository/JournalRepository'

const JournalArticles = () => {
  const navigate = useNavigate()
  const { id } = useParams()
  const { isLoggedIn } = useContext(UserContext)!
  const { pageInfo, setupPage } = usePageable()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [journal, setJournal] = useState<Journal>({} as Journal)
  const [articles, setArticles] = useState<Article[]>([])
  const [publishYearCounts, setPublishYearCounts] = useState<ArticleCountSummary<string>[]>([])
  const [checkedPublishYears, setCheckedPublishYears] = useState(new Set<string>())
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const getArticlesSuccessHandler = useCallback(
    (currentPage: number) => (response: ArticleJournalResponse) => {
      setupPage(response.articles, currentPage)
      setArticles(response.articles.content)
      setPublishYearCounts(response.publishYearCounts)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      const journalId = id ? +id : 0
      articleRepository
        .getArticlesInJournal(journalId, Array.from(checkedPublishYears), {
          page: currentPage,
          size: 10,
          sort: null,
        })
        .then(getArticlesSuccessHandler(currentPage))
    },
    [id, checkedPublishYears, getArticlesSuccessHandler]
  )

  const openArticlesSaveModal = () => {
    if (!isLoggedIn) {
      global.alert('로그인이 필요합니다.')
      return
    }
    if (checkedItems.size === 0) {
      global.alert('보관함에 저장할 학술논문을 선택해주세요.')
      return
    }
    setModalOpen(true)
  }

  const successAddArticlesHandler = () => {
    alert('학술논문이 선택하신 보관함에 저장되었습니다.')
    setModalOpen(false)
    clearAllChecked()
  }

  const selectStorage = (storageId: number) => {
    userStorageRepository
      .addArticles(storageId, Array.from(checkedItems))
      .then(successAddArticlesHandler)
  }

  const getJournalDetail = (journalId: number) => {
    journalRepository
      .getJournal(journalId)
      .then((journal) => setJournal(journal))
      .catch(() => {
        alert('잘못된 URL 입니다.')
        navigate(-1)
      })
  }

  useEffect(() => {
    getJournalDetail(parseInt(id ?? '0'))
    moveToPage(0)
    // eslint-disable-next-line
  }, [id, moveToPage])

  return (
    <main>
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <MyArticlesStorageBox isModifiable={false} selectStorage={selectStorage} />
      </ModalWindow>
      <div className="container">
        <Location paths={['저널']} />
        <div className="content-wrap reverse">
          <aside className="aside">
            <JournalDetails journal={journal} />
            <p />
            <FilterOptionSelector
              title={'연도별'}
              options={publishYearCounts}
              setSelectedOptions={(selectedYears) => {
                setCheckedPublishYears(new Set(selectedYears.keys()))
              }}
              selectedIds={Array.from(checkedPublishYears)}
              isLoading={false}
            />
          </aside>
          <section className="content">
            <hgroup className="journal-head">
              <h1>{journal.name}</h1>
              {journal.memo && journal.memo.trim() !== '' && <h2>{journal.memo}</h2>}
            </hgroup>
            <div className="result-summary above">
              <div className="func">
                <AllCheckBox
                  isAllChecked={isAllChecked}
                  onAllCheckHandler={(e) => onAllCheckHandler(e, articles)}
                />
                <Button
                  ariaLabel="storage-save-button"
                  className="keep"
                  onClick={openArticlesSaveModal}
                >
                  보관함에 저장
                </Button>
                <ExportArticlesDropdown
                  ariaLabel="export"
                  onSelectCallback={(articleExporter: ArticleExporter) => {
                    if (checkedItems.size === 0) {
                      alert('내보낼 논문을 선택하세요')
                      return
                    }
                    const selectedArticles: Article[] = articles.filter((article) =>
                      checkedItems.has(article.id)
                    )
                    articleExporter.exportArticle(selectedArticles)
                  }}
                />
              </div>
            </div>
            <ul className="thesis-list">
              {articles.map((article) => (
                <div key={article.id} className="thesis-item">
                  <CheckBox
                    onChange={(event) => onCheckedHandler(event, article.id)}
                    className="sgl-check unit"
                    ariaLabel={`checkbox-${article.id}`}
                    isAllChecked={isAllChecked}
                    checked={checkedItems.has(article.id)}
                  />
                  <ArticleComponent article={article} />
                </div>
              ))}
            </ul>
            <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
          </section>
        </div>
      </div>
    </main>
  )
}

export default JournalArticles
