import axios from 'axios'

export const setupAxiosErrorResponseFormat = () => {
  axios.interceptors.response.use(
    function (response) {
      return response
    },
    function (error) {
      if (error.response) {
        const { data, status: httpStatus } = error.response
        return Promise.reject({ ...data, httpStatus })
      }
      return Promise.reject(error)
    }
  )
}
