const roleHierarchy = {
  ADMIN: ['ADMIN', 'EDITOR', 'NORMAL'],
  EDITOR: ['EDITOR', 'NORMAL'],
  NORMAL: ['NORMAL'],
}

export const isAccessible = (userLevel: string, targetLevel: string): boolean => {
  switch (userLevel) {
    case 'ADMIN':
      return roleHierarchy.ADMIN.includes(targetLevel)
    case 'EDITOR':
      return roleHierarchy.EDITOR.includes(targetLevel)
    case 'NORMAL':
      return roleHierarchy.NORMAL.includes(targetLevel)
    default:
      return false
  }
}
