import Author from '../domain/Author'

interface AuthorParam {
  author: Author
}

function AuthorComponent({ author }: AuthorParam) {
  return (
    <div>
      <a
        aria-label={`author-detail-link-${author.authorId}`}
        href={`/authors/${author.authorId}`}
        target="_blank"
        rel="noreferrer"
      >
        <h1>
          <span className="ko">{author.koreanName}</span>
          <span className="en">{author.englishName}</span>
        </h1>
        <p className="title">
          <span className="main">{author.recentArticle.title}</span>
          <span className="sub">{author.recentArticle.subTitle}</span>
        </p>
        <p className="journal">
          {author.recentArticle.subTitle} 외 {author.issueCount - 1}개
        </p>
      </a>
    </div>
  )
}

export default AuthorComponent
