import ArticleExportConverter from './ArticleExportConverter'
import Article from '../domain/Article'
import { isNotEmpty } from '../../common/utils/TextUtils'

export interface RefWorksFormatArticle {
  rt: string
  a1: string
  t1: string
  jf: string
  k1: string
  ab: string
  pb: string
  yr: string
}

export default class RefWorksExportConverter extends ArticleExportConverter {
  convert(article: Article): string {
    const model = this.convertToModel(article)
    const propArr = [model.rt, model.a1, model.t1, model.jf, model.k1, model.ab, model.pb, model.yr]
    return propArr.filter((prop) => isNotEmpty(prop)).join('\n')
  }

  convertToModel(article: Article): RefWorksFormatArticle {
    const authorNames = this.getValidAuthorNames(article)
    const keywords = this.getJoinedKeywords(article, ', ')
    return {
      rt: 'RT Journal Article',
      a1:
        authorNames.length > 0
          ? `${authorNames.map((authorName) => `A1 ${authorName}`).join('\n')}`
          : '',
      t1: this.getPropertyString('T1', article.entireTitle),
      jf: this.getPropertyString('JF', article.articleSource.journal),
      k1: this.getPropertyString('K1', keywords),
      ab: this.getPropertyString('AB', article.entireAbstracts),
      pb: this.getPropertyString('PB', article.articleSource.publisher),
      yr: this.getPropertyString('YR', article.articleSource.publishYear),
    }
  }

  private getPropertyString(propName: string, value: string) {
    return isNotEmpty(value) ? `${propName} ${value}` : ''
  }
}
