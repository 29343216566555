import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Location from '../../common/component/Location'
import NiceSelect from '../../common/component/NiceSelect'
import Button from '../../common/component/Button'
import AllCheckBox from '../../common/component/AllCheckBox'
import CheckBox from '../../common/component/CheckBox'
import Pagination from '../../common/component/Pagination'
import Page from '../../common/domain/Page'
import { usePageable } from '../../common/hooks/usePageable'
import { useCheckbox } from '../../common/hooks/useCheckbox'

import ArticleComponent from '../../article/component/ArticleComponent'
import Article from '../../article/domain/Article'

import UserInfoMenu from '../component/UserInfoMenu'
import ArticlesStorage from '../domain/ArticlesStorage'
import { Context as UserContext } from '../provider/UserInfoProvider'
import { userStorageRepository } from '../repository/UserStorageRepository'
import ArticleExporter from '../../article/utils/ArticleExporter'
import ExportArticlesDropdown from '../../article/component/ExportArticlesDropdown'

const DEFAULT_STORAGE: ArticlesStorage = {
  id: 0,
  storageName: '',
}

const UserStorage = () => {
  const { id } = useParams()
  const { userInfo } = useContext(UserContext)!
  const { pageInfo, setupPage } = usePageable()

  const [pageSize, setPageSize] = useState<number>(10)
  const [storage, setStorage] = useState<ArticlesStorage>(DEFAULT_STORAGE)
  const [articles, setArticles] = useState<Article[]>([])
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const successDeleteHandler = () => {
    moveToPage(0)
    clearAllChecked()
    alert('선택하신 학술논문이 보관함에서 삭제되었습니다.')
  }

  const deleteArticles = () => {
    if (checkedItems.size <= 0) {
      alert('삭제할 학술논문을 선택해주세요.')
      return
    }
    userStorageRepository
      .deleteArticles(storage.id, Array.from(checkedItems))
      .then(successDeleteHandler)
  }

  const getArticlesSuccessHandler = useCallback(
    (currentPage: number) => (articlesPage: Page<Article>) => {
      setArticles(articlesPage.content)
      setupPage(articlesPage, currentPage)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      const storageId = id ? +id : 0
      userStorageRepository
        .getArticlesInStorage(storageId, '', {
          page: currentPage,
          size: pageSize,
        })
        .then(getArticlesSuccessHandler(currentPage))
    },
    [id, pageSize, getArticlesSuccessHandler]
  )

  const changePageSize = (value: string) => {
    setPageSize(+value)
  }

  const searchPage = () => {
    moveToPage(0)
  }

  useEffect(() => {
    if (id && userInfo) {
      const storage = userInfo.articlesStorages.find((storage) => storage.id === +id)
      setStorage(storage ?? DEFAULT_STORAGE)
    }
    moveToPage(0)
  }, [id, userInfo, moveToPage])

  const paths = useMemo(
    () =>
      storage.storageName
        ? ['마이페이지', '내서재', storage.storageName]
        : ['마이페이지', '내서재'],
    [storage]
  )

  return (
    <main>
      <div className="container">
        <Location paths={paths} />
        <h1 className="head">마이페이지</h1>
        <div className="content-wrap">
          <UserInfoMenu myArticles={true} />
          <section className="content">
            <h1 className="keep-head">
              <span className="name">{storage.storageName}</span>
              <span className="sum">{pageInfo.totalElements}건</span>
            </h1>
            <div className="result-summary above">
              <div className="func">
                <AllCheckBox
                  isAllChecked={isAllChecked}
                  onAllCheckHandler={(e) => onAllCheckHandler(e, articles)}
                />
                <Button className="keep delete" onClick={deleteArticles}>
                  삭제
                </Button>
                <ExportArticlesDropdown
                  ariaLabel="export"
                  onSelectCallback={(articleExporter: ArticleExporter) => {
                    if (checkedItems.size === 0) {
                      alert('내보낼 논문을 선택하세요')
                      return
                    }
                    const selectedArticles: Article[] = articles.filter((article) =>
                      checkedItems.has(article.id)
                    )
                    articleExporter.exportArticle(selectedArticles)
                  }}
                />
              </div>
              <fieldset className="sort">
                <NiceSelect
                  ariaLabel="page-size-box"
                  options={
                    new Map([
                      ['10', '10개'],
                      ['20', '20개'],
                      ['50', '50개'],
                      ['100', '100개'],
                    ])
                  }
                  onSelectCallback={changePageSize}
                />
                <Button ariaLabel="search-btn" onClick={searchPage}>
                  검색
                </Button>
              </fieldset>
            </div>
            <ul className="thesis-list">
              {articles.map((article) => {
                return (
                  <div key={article.id} className="thesis-item">
                    <CheckBox
                      onChange={(event) => onCheckedHandler(event, article.id)}
                      className="sgl-check unit"
                      ariaLabel={`checkbox-${article.id}`}
                      isAllChecked={isAllChecked}
                      checked={checkedItems.has(article.id)}
                    />
                    <ArticleComponent article={article} />
                  </div>
                )
              })}
            </ul>
            <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
          </section>
        </div>
      </div>
    </main>
  )
}

export default UserStorage
