import React from 'react'
import BbsItem from '../domain/BbsItem'

type Props = {
  moveToDetail: () => void
  bbsItem: BbsItem
  isWideCategory?: boolean
}

const BbsTableItem = ({ moveToDetail, bbsItem, isWideCategory }: Props) => {
  return (
    <li onClick={moveToDetail}>
      <div className="info">
        <dl className={`category ${isWideCategory && 'wide'}`}>
          <dt>분류</dt>
          <dd>{bbsItem.type}</dd>
        </dl>
        <dl className={`title ${isWideCategory && 'narrow'}`}>
          <dt>제목</dt>
          <dd>{bbsItem.title}</dd>
        </dl>
        <dl className="writer">
          <dt>글쓴이</dt>
          <dd>{bbsItem.writer}</dd>
        </dl>
        <dl className="date">
          <dt>작성일</dt>
          <dd>{bbsItem.createdDate}</dd>
        </dl>
        <dl className="view">
          <dt>조회</dt>
          <dd>{bbsItem.readCount}</dd>
        </dl>
      </div>
    </li>
  )
}

export default BbsTableItem
