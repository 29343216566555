import Category from '../domain/Category'
import React, { Fragment, useCallback, useState } from 'react'
import CategorySelectorCheckbox from './CategorySelectorCheckbox'
import RingLoader from 'react-spinners/RingLoader'

interface Props {
  topLevelCategories: Map<number, Category>
  setSelectedCategories: (categoryIds: number[]) => void
  selectedIds: number[]
  isLoading: boolean
}

const CategorySelector = ({
  topLevelCategories,
  setSelectedCategories,
  selectedIds,
  isLoading,
}: Props) => {
  const [limitHeight, setLimitHeight] = useState(true)

  const articleCountsLoaded = useCallback(() => {
    return (
      Array.from(topLevelCategories.values()).filter((category) => category.articleCount > 0)
        .length > 0
    )
  }, [topLevelCategories])

  if (!topLevelCategories || topLevelCategories.size === 0) {
    return <Fragment />
  }

  return (
    <ul id="tree-check">
      <li className="on">
        <p className="head">
          <span>분야별</span>
        </p>
        {isLoading || !articleCountsLoaded() ? (
          <div style={{ display: 'flex' }}>
            <div style={{ margin: '0 auto' }}>
              <RingLoader size={20} color="#C63DEE" />
            </div>
          </div>
        ) : (
          <ul className={limitHeight ? 'limit-height' : ''}>
            <CategorySelectorCheckbox
              topLevelCategoryMap={topLevelCategories}
              setSelectedCategories={setSelectedCategories}
              selectedIds={selectedIds}
            />
          </ul>
        )}
        <button onClick={() => setLimitHeight(!limitHeight)} type="button" className="list-toggle">
          {limitHeight ? '펼쳐보기' : '줄이기'}
        </button>
      </li>
    </ul>
  )
}

export default CategorySelector
