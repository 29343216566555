import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import RingLoader from 'react-spinners/RingLoader'
import { useSearchParams } from 'react-router-dom'

import Location from '../../common/component/Location'
import ModalWindow from '../../common/component/ModalWindow'
import CheckBox from '../../common/component/CheckBox'
import Button from '../../common/component/Button'
import Pagination from '../../common/component/Pagination'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import AllCheckBox from '../../common/component/AllCheckBox'
import NiceSelect from '../../common/component/NiceSelect'
import { usePageable } from '../../common/hooks/usePageable'
import { formatNumber } from '../../common/utils/NumberFormatter'

import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { userStorageRepository } from '../../user/repository/UserStorageRepository'
import MyArticlesStorageBox from '../../user/component/MyArticlesStorageBox'

import { Action, createBrowserHistory } from 'history'

import { Context as CategoryContext } from '../provider/CategoryProvider'
import { Context as SearchConditionContext } from '../provider/SearchConditionProvider'

import ArticleComponent from '../component/ArticleComponent'
import SearchFilters from '../component/SearchFilters'
import SearchFilterLabels from '../component/SearchFilterLabels'
import ExportArticlesDropdown from '../component/ExportArticlesDropdown'

import Article from '../domain/Article'
import ArticleSearchResponse from '../domain/ArticleSearchResponse'
import ArticleCountSummary from '../domain/ArticleCountSummary'
import SearchCounts from '../domain/SearchCounts'
import CategoryResolver from '../domain/CategoryResolver'
import SearchFilterValue from '../domain/SearchFilterValue'
import SelectedSearchFilter, { isDifferentCondition } from '../domain/SelectedSearchFilter'

import { articleRepository } from '../repository/ArticleRepository'

import ArticleExporter from '../utils/ArticleExporter'

function ArticleSearch() {
  const { pageInfo, setupPage } = usePageable()
  const [searchParams] = useSearchParams()
  const searchTextFromUrl = searchParams.get('searchText')

  const searchInResult = useRef<HTMLInputElement>(null)

  const { isLoggedIn } = useContext(UserContext)!
  const { categoryTree } = useContext(CategoryContext)!
  const searchCondition = useContext(SearchConditionContext)!

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [history] = useState(createBrowserHistory())
  const [locationKeys, setLocationKeys] = useState<string[]>([])
  const [snapshotHistory, setSnapshotHistory] = useState<SelectedSearchFilter[]>([])
  const [snapshotPopped, setSnapshotPopped] = useState<boolean>(false)
  const [pageSize, setPageSize] = useState<SearchFilterValue<number>>({ key: 10, value: '10개' })
  const [sort, setSort] = useState<'title' | 'publish_year' | null>(null)
  const [direction, setDirection] = useState<'acs' | 'desc' | null>(null)
  const [articles, setArticles] = useState<Article[]>([])
  const [errorMessage, setErrorMessage] = useState('')
  const [currentCategoryCounts, setCurrentCategoryCounts] = useState<ArticleCountSummary<number>[]>(
    []
  )
  const [categoryResolver, setCategoryResolver] = useState<CategoryResolver>(
    new CategoryResolver(null, [])
  )
  const [searchCounts, setSearchCounts] = useState<SearchCounts>({
    journals: [],
    authors: [],
    publishYears: [],
    keywords: [],
    sources: [],
  })
  const [isLoading, setIsLoading] = useState(false)
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const pageSizeOptions = useMemo(
    () =>
      new Map([
        ['10', '10개'],
        ['20', '20개'],
        ['50', '50개'],
        ['100', '100개'],
      ]),
    []
  )

  const sortFieldOptions = useMemo(
    () =>
      new Map([
        ['none', '정렬기준'],
        ['publish_year', '발행년도'],
      ]),
    []
  )

  const sortDirectionOptions = useMemo(
    () =>
      new Map([
        ['none', '정렬순서'],
        ['acs', '오름차순'],
        ['desc', '내림차순'],
      ]),
    []
  )

  const openArticlesSaveModal = () => {
    if (!isLoggedIn) {
      global.alert('로그인이 필요합니다.')
      return
    }
    if (checkedItems.size === 0) {
      global.alert('보관함에 저장할 학술논문을 선택해주세요.')
      return
    }
    setModalOpen(true)
  }

  const successAddArticlesHandler = () => {
    alert('학술논문이 선택하신 보관함에 저장되었습니다.')
    setModalOpen(false)
    clearAllChecked()
  }

  const selectStorage = (storageId: number) => {
    userStorageRepository
      .addArticles(storageId, Array.from(checkedItems))
      .then(successAddArticlesHandler)
  }

  const getArticlesSuccessHandler = useCallback(
    (searchResponse: ArticleSearchResponse) => {
      setArticles(searchResponse.articles.content)
      setupPage(searchResponse.articles, searchResponse.articles.pageable.pageNumber)
      setSearchCounts({
        journals: searchResponse.journalCounts,
        authors: searchResponse.authorCounts,
        publishYears: searchResponse.publishYearCounts,
        keywords: searchResponse.keywordCounts,
        sources: searchResponse.sourceCounts,
      })
      setErrorMessage(searchResponse.errorMessage || '')
      if (searchResponse.categoryCounts && searchResponse.categoryCounts.length > 0) {
        setCategory(searchResponse.categoryCounts)
      }
      setIsLoading(false)
    },
    // eslint-disable-next-line
    [setupPage]
  )

  const setCategory = useCallback(
    (categoryCounts: ArticleCountSummary<number>[]) => {
      setCurrentCategoryCounts(categoryCounts)

      if (categoryTree !== null && categoryTree.children !== null) {
        const categoryResolver = new CategoryResolver(categoryTree, categoryCounts)
        setCategoryResolver(categoryResolver)
      }
    },
    [categoryTree]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      setIsLoading(true)
      searchCondition.saveCurrentSnapshot(categoryResolver.categoryPathMap)
      let searchText =
        searchCondition.searchText === '' && searchTextFromUrl !== ''
          ? searchTextFromUrl || ''
          : searchCondition.searchText || ''
      if (searchInResult.current?.checked) {
        const lastSearchText = searchCondition.latestSnapshot.searchText
        const currentSearchText = searchCondition.searchText
        if (!currentSearchText.includes(lastSearchText)) {
          searchText = `${lastSearchText} ${currentSearchText}`
        }
      }

      if (searchText !== searchCondition.searchText) {
        searchCondition.setSearchText(searchText)
      }

      articleRepository
        .searchInArticles(
          searchText,
          searchCondition.categoryIds,
          Array.from(searchCondition.journals.keys()),
          Array.from(searchCondition.authors.keys()),
          Array.from(searchCondition.publishYears.keys()),
          Array.from(searchCondition.keywords.keys()),
          Array.from(searchCondition.sources.keys()),
          {
            page: currentPage,
            size: pageSize.key,
            sort: sort === null || direction === null ? null : `${sort},${direction}`,
          }
        )
        .then(getArticlesSuccessHandler)
    },
    // eslint-disable-next-line
    [getArticlesSuccessHandler, searchCondition, pageSize, sort, direction, searchTextFromUrl]
  )

  const searchResultElement = useCallback(() => {
    if (errorMessage.trim() !== '') {
      return <p className="sum">{errorMessage}</p>
    }

    const isSearchExpression =
      searchCondition.searchText.trim().startsWith('(') &&
      searchCondition.searchText.trim().endsWith(')')

    return articles && articles.length > 0 ? (
      <div className="search-result-count">
        <p className="sum" data-testid="total-search-count">
          총 <strong className="number">{formatNumber(pageInfo.totalElements)}</strong>
          건의 검색 결과가 있습니다.
        </p>
        {!isSearchExpression && (
          <label className="checkbox search">
            <input type="checkbox" ref={searchInResult} />
            <span>결과 내 검색</span>
          </label>
        )}
      </div>
    ) : (
      <p className="sum">
        <strong className="number">"{searchCondition.searchText}"</strong>에 대한 검색 결과가
        없습니다.
      </p>
    )
  }, [articles, errorMessage, searchCondition.searchText, pageInfo.totalElements])

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [searchCondition.manualSearchTrigger])

  useEffect(() => {
    setCategory(currentCategoryCounts)
  }, [categoryTree, currentCategoryCounts, setCategory])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageInfo])

  useEffect(() => {
    return history.listen((update) => {
      switch (update.action) {
        case Action.Pop:
          setLocationKeys((previous) => [...previous, update.location.key])
          if (snapshotHistory.length > 1) {
            const newHistory = snapshotHistory.slice()
            newHistory.pop()
            const previousSnapshot = newHistory[newHistory.length - 1]
            searchCondition.setSearchText(previousSnapshot.searchText)
            searchCondition.setCategoryIds(
              previousSnapshot.categories.map((category) => category.key)
            )
            searchCondition.setJournals(
              new Map(previousSnapshot.journals.map((journal) => [journal.key, journal.value]))
            )
            searchCondition.setAuthors(
              new Map(previousSnapshot.authors.map((author) => [author.key, author.value]))
            )
            searchCondition.setPublishYears(
              new Map(
                previousSnapshot.publishYears.map((publishYear) => [
                  publishYear.key,
                  publishYear.value,
                ])
              )
            )
            searchCondition.setKeywords(
              new Map(previousSnapshot.keywords.map((keyword) => [keyword.key, keyword.value]))
            )
            searchCondition.setSources(
              new Map(previousSnapshot.sources.map((source) => [source.key, source.value]))
            )
            searchCondition.saveCurrentSnapshot(categoryResolver.categoryPathMap)
            searchCondition.setManualSearchTrigger(!searchCondition.manualSearchTrigger)
            setSnapshotHistory(newHistory)
            setSnapshotPopped(true)
            history.push('/article-search')
          } else {
            history.back()
          }
          break
        case Action.Push:
          setLocationKeys([update.location.key])
          break
        default:
          break
      }
    })
  }, [
    history,
    locationKeys,
    snapshotHistory,
    searchCondition,
    snapshotPopped,
    categoryResolver.categoryPathMap,
  ])

  useEffect(() => {
    if (!snapshotPopped) {
      const searchTextIsNotBlank = searchCondition.latestSnapshot.searchText !== ''
      if (searchTextIsNotBlank) {
        const pushHistory = () => {
          const newHistory = snapshotHistory.slice()
          newHistory.push(searchCondition.latestSnapshot)
          setSnapshotHistory(newHistory)
        }

        if (snapshotHistory.length === 0) {
          pushHistory()
        } else if (snapshotHistory.length > 0) {
          const currentSnapshot = snapshotHistory[snapshotHistory.length - 1]
          if (isDifferentCondition(searchCondition.latestSnapshot, currentSnapshot)) {
            pushHistory()
          }
        }
      }
    } else {
      setSnapshotPopped(false)
    }
    // eslint-disable-next-line
  }, [searchCondition.latestSnapshot, snapshotPopped])

  return (
    <main>
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <MyArticlesStorageBox isModifiable={false} selectStorage={selectStorage} />
      </ModalWindow>
      <div className="container">
        <Location paths={['검색결과']} />
        <div className="result-summary">
          {isLoading ? (
            <div>
              <RingLoader size={30} color="#C63DEE" />
              <p style={{ marginTop: '10px' }} className="sum">
                검색중입니다...
              </p>
            </div>
          ) : (
            searchResultElement()
          )}
        </div>
        {articles && articles.length > 0 && !isLoading && (
          <>
            <SearchFilterLabels />
            <div className="content-wrap reverse">
              <SearchFilters
                isLoading={isLoading}
                topLevelCategories={categoryResolver?.topLevelCategories}
                searchCounts={searchCounts}
              />
              <section className="content">
                <div className="result-summary">
                  <div className="func">
                    <AllCheckBox
                      isAllChecked={isAllChecked}
                      onAllCheckHandler={(e) => onAllCheckHandler(e, articles)}
                    />
                    <Button
                      className="keep"
                      ariaLabel="storage-save-button"
                      onClick={openArticlesSaveModal}
                    >
                      보관함에 저장
                    </Button>
                    <ExportArticlesDropdown
                      ariaLabel="export"
                      onSelectCallback={(articleExporter: ArticleExporter) => {
                        if (checkedItems.size === 0) {
                          alert('내보낼 논문을 선택하세요')
                          return
                        }
                        const selectedArticles: Article[] = articles.filter((article) =>
                          checkedItems.has(article.id)
                        )
                        articleExporter.exportArticle(selectedArticles)
                      }}
                    />
                  </div>
                  <fieldset className="sort">
                    <NiceSelect
                      ariaLabel="sort-field"
                      options={sortFieldOptions}
                      onSelectCallback={(field: string) => {
                        if (field === 'title' || field === 'publish_year') {
                          setSort(field)
                        } else {
                          setSort(null)
                        }
                      }}
                      selectedValue={sortFieldOptions.get(sort || '정렬기준')}
                    />
                    <NiceSelect
                      ariaLabel="sort-direction"
                      options={sortDirectionOptions}
                      onSelectCallback={(direction: string) => {
                        if (direction === 'acs' || direction === 'desc') {
                          setDirection(direction)
                        } else {
                          setDirection(null)
                        }
                      }}
                      selectedValue={sortDirectionOptions.get(direction || '정렬순서')}
                    />
                    <NiceSelect
                      ariaLabel="page-size"
                      options={pageSizeOptions}
                      onSelectCallback={(pageSize) => {
                        setPageSize({
                          key: parseInt(pageSize),
                          value: pageSizeOptions.get(pageSize)!,
                        })
                      }}
                      selectedValue={pageSize.value}
                    />
                    <Button ariaLabel="sort-button" onClick={() => moveToPage(0)} />
                  </fieldset>
                </div>
                <ul className="thesis-list above">
                  {articles.map((article) => {
                    return (
                      <div key={article.id} className="thesis-item">
                        <CheckBox
                          onChange={(event) => onCheckedHandler(event, article.id)}
                          className="sgl-check unit"
                          ariaLabel={`checkbox-${article.id}`}
                          isAllChecked={isAllChecked}
                          checked={checkedItems.has(article.id)}
                        />
                        <ArticleComponent article={article} />
                      </div>
                    )
                  })}
                </ul>
                <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
              </section>
            </div>
          </>
        )}
      </div>
    </main>
  )
}

export default ArticleSearch
