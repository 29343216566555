import React, { useContext, useRef, useState } from 'react'

import ModalWindow from '../../common/component/ModalWindow'
import Button from '../../common/component/Button'

import StorageNameModifyModal from './StorageNameModifyModal'
import { Context as UserContext } from '../provider/UserInfoProvider'
import { userInfoRepository } from '../repository/UserInfoRepository'

import { errorMessageHandler } from '../../common/utils/AlertMessage'
import { useNavigate } from 'react-router-dom'

type Props = {
  isModifiable?: boolean
  selectStorage: (storageId: number) => void
}

const MyArticlesStorageBox = ({ isModifiable = true, selectStorage }: Props) => {
  const navigate = useNavigate()
  const { userInfo, loadUserInfo } = useContext(UserContext)!

  const storageNameRef = useRef<HTMLInputElement>(null)
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [storageId, setStorageId] = useState<number>(0)
  const [storageName, setStorageName] = useState<string>('')

  const clearStorageNameInput = () => {
    if (storageNameRef.current) {
      storageNameRef.current.value = ''
    }
  }

  const createSuccessHandler = () => {
    loadUserInfo()
    clearStorageNameInput()
  }

  const deleteSuccessHandler = () => {
    loadUserInfo().then((userInfo) => {
      alert('정상적으로 삭제되었습니다.')
      let redirectPath = '/user-info/storages'
      if (userInfo && userInfo.articlesStorages.length > 0) {
        redirectPath = redirectPath + `/${userInfo.articlesStorages[0].id}`
      }
      navigate(redirectPath, { replace: true })
    })
  }

  const createStorage = () => {
    const storageName = storageNameRef.current?.value
    if (!storageName || storageName.trim() === '') {
      alert('보관함 이름을 작성하세요.')
      return
    }
    userInfoRepository
      .createArticlesStorage({ storageName })
      .then(createSuccessHandler)
      .catch(errorMessageHandler)
  }

  const deleteStorage = (storageId: number) => {
    const isConfirmed = window.confirm(
      '보관함에 저장된 모든 학술논문도 함께 삭제됩니다. 진행하시겠습니까?'
    )
    if (!isConfirmed) {
      return
    }
    userInfoRepository.deleteArticlesStorage(storageId).then(deleteSuccessHandler)
  }

  const openModifyPopup = (storageId: number, storageName: string) => {
    setModalOpen(true)
    setStorageId(storageId)
    setStorageName(storageName)
  }

  return (
    <div className={`articles-storage ${isModifiable ? '' : 'popup'}`}>
      {isModifiable && (
        <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
          <StorageNameModifyModal
            storageId={storageId}
            storageName={storageName}
            closeModal={() => setModalOpen(false)}
          />
        </ModalWindow>
      )}
      <ul className="my-keep">
        <div className={`storages ${isModifiable ? '' : 'modal-mode'}`}>
          {userInfo?.articlesStorages.map((storage) => (
            <li key={`articles-storage-${storage.id}`}>
              <span
                onClick={() => selectStorage(storage.id)}
                className={` ${isModifiable ? '' : 'modal-mode'}`}
              >
                {storage.storageName}
              </span>
              {isModifiable && (
                <div className="func">
                  <button
                    aria-label="storage-modify-button"
                    className="edit"
                    onClick={() => openModifyPopup(storage.id, storage.storageName)}
                  >
                    수정
                  </button>
                  <button
                    aria-label="storage-delete-button"
                    className="delete"
                    onClick={() => deleteStorage(storage.id)}
                  >
                    삭제
                  </button>
                </div>
              )}
            </li>
          ))}
        </div>
        <li className="add">
          <input
            type="text"
            aria-label="storage-name-input"
            ref={storageNameRef}
            placeholder="새폴더"
          />
          <Button onClick={createStorage} ariaLabel="storage-create-button">
            추가
          </Button>
        </li>
      </ul>
    </div>
  )
}

export default MyArticlesStorageBox
