import { createContext, useState } from 'react'
import TactoProjectContextType from '../domain/TactoProjectContextType'

export const Context = createContext<TactoProjectContextType | null>(null)

type Reloadable = {
  refreshItems: () => void
}

const TactoProjectProvider = ({ children }: { children: JSX.Element }) => {
  const [reloadable, setReloadable] = useState<Reloadable>({
    refreshItems: () => {},
  })
  return (
    <Context.Provider
      value={{
        refreshItems: reloadable.refreshItems,
        registerRefreshItems: (callback: () => void) => {
          setReloadable({ refreshItems: callback })
        },
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default TactoProjectProvider
