import React from 'react'

interface Props {
  paths: string[]
}

const Location = ({ paths }: Props) => {
  return (
    <ol className="location">
      <li>
        <span className="icon-home">Home</span>
      </li>
      {paths.map((path, index) => (
        <li key={`${path}-${index}`}>
          {index !== paths.length - 1 ? <span>{path}</span> : <strong>{path ? path : '-'}</strong>}
        </li>
      ))}
    </ol>
  )
}

export default Location
