import Category from './Category'
import ArticleCountSummary from './ArticleCountSummary'

export default class CategoryResolver {
  private readonly _categoryTree: Category | null = null
  private readonly _topLevelCategories: Map<number, Category>
  private readonly _categoryPathMap: Map<number, string[]>

  constructor(entireCategoryTree: Category | null, searchCounts: ArticleCountSummary<number>[]) {
    this._categoryTree = { ...entireCategoryTree! }
    if (this.isNotEmptyCategory(entireCategoryTree)) {
      this.calculateCountsInCategoryTree(this._categoryTree, searchCounts)
      this._topLevelCategories = this._categoryTree.children!
      this._categoryPathMap = this.buildCategoryPathMap(this._categoryTree)
    } else {
      this._topLevelCategories = new Map()
      this._categoryPathMap = new Map()
    }
  }

  get categoryTree(): Category | null {
    return this._categoryTree
  }

  get topLevelCategories(): Map<number, Category> {
    return this._topLevelCategories
  }

  get categoryPathMap(): Map<number, string[]> {
    return this._categoryPathMap
  }

  private isNotEmptyCategory = (category: Category | null) =>
    category !== null && category.children !== null

  private calculateCountsInCategoryTree = (
    categoryTree: Category,
    counts: ArticleCountSummary<number>[]
  ) => {
    const categoryCountMap = new Map(counts.map((count) => [count.key, count.count]))
    categoryTree.children!.forEach((topLevel) => {
      let topLevelCount = categoryCountMap.has(topLevel.id) ? categoryCountMap.get(topLevel.id)! : 0
      topLevel.children?.forEach((nextLevel) => {
        nextLevel.articleCount = categoryCountMap.has(nextLevel.id)
          ? categoryCountMap.get(nextLevel.id)!
          : 0
        topLevelCount += nextLevel.articleCount
      })
      topLevel.articleCount = topLevelCount
    })
  }

  private buildCategoryPathMap = (category: Category) => {
    const allCategoryPathMap: Map<number, string[]> = new Map()
    category.children!.forEach((topLevelCategory) => {
      allCategoryPathMap.set(topLevelCategory.id, topLevelCategory.paths)
      if (topLevelCategory.children != null && topLevelCategory.children.size > 0) {
        topLevelCategory.children.forEach((child) => {
          allCategoryPathMap.set(child.id, child.paths)
        })
      }
    })
    return allCategoryPathMap
  }
}
