import AxiosUserRepository from './AxiosUserRepository'

import UserInfo from '../domain/UserInfo'
import { ResetPasswordRequest } from '../domain/request/ResetPasswordRequest'

export default interface UserRepository {
  isEmailDuplicated(userId: string): Promise<boolean>

  isLoggedIn(): boolean

  logout(): void

  parseUserInfo(): UserInfo | null

  resetPassword(email: string): Promise<void>

  validateResetPasswordToken(email: string, token: string): Promise<boolean>

  changePassword(request: ResetPasswordRequest): Promise<void>
}

export const userRepository = new AxiosUserRepository()
