import { Fragment, ReactNode } from 'react'
import TopicTreeNode from '../domain/TopicTreeNode'

type Props = {
  rootNode?: TopicTreeNode | null
  selectedId: string
  onSelectTopic: (id: string) => void
  openCollectModal: (topicTreeNode: TopicTreeNode) => void
}

function drawTree(
  treeNodeMap: { [key in string]: TopicTreeNode },
  selectedId: string,
  onSelectTopic: (id: string) => void,
  openCollectModal: (topicTreeNode: TopicTreeNode) => void
): ReactNode {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => onSelectTopic(value.id)}
        >
          <div className={`icon open-folder ${selectedId === value.id && 'on'}`} />
          <div>{value.name}</div>
          {selectedId === value.id && (
            <button
              className="icon collect"
              aria-label="collect-button"
              onClick={() => openCollectModal(value)}
            />
          )}
        </div>
        {value.children && (
          <ul className="children">
            {drawTree(value.children, selectedId, onSelectTopic, openCollectModal)}
          </ul>
        )}
      </Fragment>
    )
  })
}

const TopicTreeSection = ({ rootNode, selectedId, onSelectTopic, openCollectModal }: Props) => {
  return (
    <>
      {rootNode?.children && (
        <div className="topic-tree-section">
          {drawTree(rootNode.children, selectedId, onSelectTopic, openCollectModal)}
        </div>
      )}
    </>
  )
}

export default TopicTreeSection
