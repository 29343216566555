import React, { useCallback, useRef, useState } from 'react'
import NiceSelect from '../../common/component/NiceSelect'

interface Prop {
  isOpen: boolean
  searchCallback: (formula: string) => void
  closeCallback: () => void
}

type SearchType = 'Title' | 'Author' | 'Journal' | 'Keywords' | 'All'
type OperationType = 'AND' | 'OR' | 'NOT'
type SourceType =
  | 'NONE'
  | 'KCI'
  | 'ScienceDirect'
  | 'pubmed'
  | 'PMC'
  | 'KoreaPatent'
  | 'AIP'
  | 'ACS'
  | 'APS'
  | 'NEJM'
  | 'RSC'

const searchTypeOptions = new Map([
  ['Title', '제목'],
  ['Author', '저자'],
  ['Journal', '저널'],
  ['Keywords', '키워드'],
  ['All', '전체'],
])

const operationTypeOptions = new Map([
  ['AND', 'AND'],
  ['OR', 'OR'],
  ['NOT', 'NOT'],
])

const sourceTypeOptions = new Map([
  ['NONE', '(선택)'],
  ['KoreaPatent', '국내 특허'],
  ['AIP', 'AIP'],
  ['ACS', 'ACS'],
  ['APS', 'APS'],
  ['IEEE', 'IEEE'],
  ['KCI', 'KCI'],
  ['Nature', 'Nature'],
  ['NEJM', 'NEJM'],
  ['pubmed', 'Pubmed'],
  ['PMC', 'PMC'],
  ['RSC', 'RSC'],
  ['ScienceDirect', 'ScienceDirect'],
])

const yearOptions = new Map([
  ['NONE', '(선택)'],
  ['2018', '2018'],
  ['2019', '2019'],
  ['2020', '2020'],
  ['2021', '2021'],
  ['2022', '2022'],
  ['2023', '2023'],
])

const AdvancedSearchHandlerComponent = ({ isOpen, searchCallback, closeCallback }: Prop) => {
  const [formulaText, setFormulaText] = useState('')
  const [currentSearchType, setCurrentSearchType] = useState<SearchType>('Title')
  const [currentOperationType, setCurrentOperationType] = useState<OperationType>('AND')
  const [currentSourceType, setCurrentSourceType] = useState<SourceType>('NONE')
  const [yearStart, setYearStart] = useState('NONE')
  const [yearEnd, setYearEnd] = useState('NONE')
  const searchTextInput = useRef<HTMLInputElement>(null)

  const initConditions = useCallback(() => {
    if (searchTextInput.current) {
      searchTextInput.current.value = ''
    }
    setCurrentSearchType('Title')
    setCurrentOperationType('AND')
    setCurrentSourceType('NONE')
    setYearStart('NONE')
    setYearEnd('NONE')
  }, [])

  const addSearchCondition = useCallback(() => {
    const appendFormulaText = (conditionToAdd: string) => {
      if (formulaText === '') {
        setFormulaText(conditionToAdd)
      } else {
        setFormulaText(`(${formulaText} ${currentOperationType} ${conditionToAdd})`)
      }
    }

    const appendCondition = (previousCondition: string, conditionToAdd: string) => {
      if (previousCondition === '') {
        return conditionToAdd
      }
      return `(${previousCondition} AND ${conditionToAdd})`
    }

    const hasSearchText =
      searchTextInput.current !== null && searchTextInput.current.value.trim() !== ''
    let conditionToAdd = ''
    if (hasSearchText) {
      conditionToAdd = `(${searchTextInput.current.value}[${currentSearchType}])`
      searchTextInput.current.value = ''
    }

    if (currentSourceType !== 'NONE') {
      const sourceCondition = `(${currentSourceType}[Source])`
      conditionToAdd = appendCondition(conditionToAdd, sourceCondition)
    }

    const onlyHasYearStart = yearStart !== 'NONE' && yearEnd === 'NONE'
    const onlyHasYearEnd = yearStart === 'NONE' && yearEnd !== 'NONE'
    const hasBothYearStartAndEnd = yearStart !== 'NONE' && yearEnd !== 'NONE'
    if (onlyHasYearStart) {
      const yearCondition = `(${yearStart}[Year])`
      conditionToAdd = appendCondition(conditionToAdd, yearCondition)
    } else if (onlyHasYearEnd) {
      const yearCondition = `(${yearEnd}[Year])`
      conditionToAdd = appendCondition(conditionToAdd, yearCondition)
    } else if (hasBothYearStartAndEnd) {
      if (parseInt(yearStart) > parseInt(yearEnd)) {
        alert('시작 연도는 끝 연도보다 이전이어야 합니다')
        return
      }
      const yearCondition =
        yearStart === yearEnd ? `(${yearEnd}[Year])` : `(${yearStart}-${yearEnd}[Year])`
      conditionToAdd = appendCondition(conditionToAdd, yearCondition)
    }

    appendFormulaText(conditionToAdd)
    initConditions()
  }, [
    initConditions,
    formulaText,
    currentSearchType,
    currentOperationType,
    currentSourceType,
    yearStart,
    yearEnd,
  ])

  const onSearchTypeSelectCallback = useCallback((searchType: string) => {
    switch (searchType) {
      case 'Title':
        setCurrentSearchType('Title')
        break
      case 'Author':
        setCurrentSearchType('Author')
        break
      case 'Journal':
        setCurrentSearchType('Journal')
        break
      case 'Keywords':
        setCurrentSearchType('Keywords')
        break
      case 'All':
        setCurrentSearchType('All')
        break
      default:
        setCurrentSearchType('Title')
        break
    }
  }, [])

  const onOperationTypeSelectCallback = useCallback((operationType: string) => {
    switch (operationType) {
      case 'AND':
        setCurrentOperationType('AND')
        break
      case 'OR':
        setCurrentOperationType('OR')
        break
      case 'NOT':
        setCurrentOperationType('NOT')
        break
      default:
        setCurrentOperationType('AND')
        break
    }
  }, [])

  const onSourceTypeSelectCallback = useCallback((sourceType: string) => {
    switch (sourceType) {
      case 'KCI':
        setCurrentSourceType('KCI')
        break
      case 'ScienceDirect':
        setCurrentSourceType('ScienceDirect')
        break
      case 'pubmed':
        setCurrentSourceType('pubmed')
        break
      case 'PMC':
        setCurrentSourceType('PMC')
        break
      case 'KoreaPatent':
        setCurrentSourceType('KoreaPatent')
        break
      case 'ACS':
        setCurrentSourceType('ACS')
        break
      case 'AIP':
        setCurrentSourceType('AIP')
        break
      case 'APS':
        setCurrentSourceType('APS')
        break
      case 'NEJM':
        setCurrentSourceType('NEJM')
        break
      case 'RSC':
        setCurrentSourceType('RSC')
        break
      default:
        setCurrentSourceType('NONE')
        break
    }
  }, [])

  const initFormula = useCallback(() => {
    initConditions()
    setFormulaText('')
  }, [initConditions])

  const closeModal = useCallback(() => {
    initFormula()
    closeCallback()
  }, [initFormula, closeCallback])
  return (
    <div
      aria-label="advanced-search-modal"
      className={`pop-detail-search ${isOpen ? 'on' : ''}`}
      id="adv-search"
    >
      <div className="pop-detail-window">
        <h1>
          <span>상세검색</span>
        </h1>
        <div className="pop-cnt">
          <fieldset className="advanced-search">
            <div className="condition">
              <div className="unit">
                <NiceSelect
                  ariaLabel="search-type"
                  options={searchTypeOptions}
                  onSelectCallback={onSearchTypeSelectCallback}
                  selectedValue={searchTypeOptions.get(currentSearchType)}
                />
                <input
                  aria-label="searchText"
                  type="search"
                  placeholder="검색어를 입력해주세요"
                  ref={searchTextInput}
                />
                <NiceSelect
                  ariaLabel="operation-type"
                  options={operationTypeOptions}
                  onSelectCallback={onOperationTypeSelectCallback}
                  selectedValue={operationTypeOptions.get(currentOperationType)}
                />
                <button type="button" className="add-condition" onClick={addSearchCondition}>
                  검색 조건 추가
                </button>
              </div>
            </div>

            <div className="selection">
              <div className="unit">
                <label htmlFor="source-type">데이터 소스</label>
                <NiceSelect
                  ariaLabel="source-type"
                  options={sourceTypeOptions}
                  onSelectCallback={onSourceTypeSelectCallback}
                  selectedValue={sourceTypeOptions.get(currentSourceType)}
                />
              </div>
              <div className="unit">
                <label htmlFor="">발행 연도</label>
                <NiceSelect
                  ariaLabel="year-start"
                  options={yearOptions}
                  onSelectCallback={setYearStart}
                  selectedValue={yearOptions.get(yearStart)}
                />
                <span>-</span>
                <NiceSelect
                  ariaLabel="year-end"
                  options={yearOptions}
                  onSelectCallback={setYearEnd}
                  selectedValue={yearOptions.get(yearEnd)}
                />
              </div>
            </div>
            <p className="info">
              * 출처와 연도 조건을 추가하려면 [검색조건추가] 버튼을 클릭하세요.
            </p>
            <p className="label">검색식</p>
            <div aria-label="formula-text" className="formula">
              {formulaText}
            </div>
            <div className="button-wrap centro">
              <button
                className="search"
                onClick={() => {
                  searchCallback(formulaText)
                }}
              >
                검색
              </button>
              <button className="init" onClick={initFormula}>
                초기화
              </button>
            </div>
          </fieldset>
        </div>
        <button type="button" className="close" onClick={closeModal}>
          닫기
        </button>
      </div>
    </div>
  )
}

export default AdvancedSearchHandlerComponent
