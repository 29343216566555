import React, { ChangeEvent, useContext, useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import SnsLogin from '../component/SnsLogin'
import { Context as UserInfoContext } from '../provider/UserInfoProvider'
import { userRepository } from '../repository/UserRepository'
import { serviceDescription } from '../../common/constant/ServiceConstant'

function Login() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const { login } = useContext(UserInfoContext)!

  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const onChangeEmail = (event: ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const onChangePassword = (event: ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  useEffect(() => {
    const userInfo = userRepository.parseUserInfo()
    if (document.cookie && userInfo !== null) {
      login()
      navigate('/main')
    }
  }, [login, navigate])

  useEffect(() => {
    const error = searchParams.get('error')
    if (error) {
      const message = decodeURIComponent(atob(error))
      alert(message)
      searchParams.delete('error')
      setSearchParams(searchParams)
    }
  }, [searchParams, setSearchParams])

  return (
    <main className="member">
      <hgroup onClick={() => navigate('/main')} aria-label="main-logo">
        <h1>K·DISCOVERY</h1>
        <h2>{serviceDescription}</h2>
      </hgroup>
      <section>
        <h1>로그인</h1>
        <form method="post" action="/auth/api/user/sign-in" aria-label="form">
          <ul className="my-form full">
            <li>
              <input
                aria-label="email-id-input"
                type="text"
                name="email"
                onChange={onChangeEmail}
                value={email}
                placeholder="이메일 아이디"
              />
            </li>
            <li>
              <input
                aria-label="password-input"
                type="password"
                name="password"
                onChange={onChangePassword}
                value={password}
                placeholder="비밀번호"
              />
            </li>
          </ul>
          <div className="add">
            <label className="checkbox">
              <input type="checkbox" name="remember-me" />
              <span>로그인 유지</span>
            </label>
            <ul className="member">
              <li>
                <div aria-label="find-password-button" onClick={() => navigate('/password')}>
                  비밀번호 찾기
                </div>
              </li>
              <li>
                <div aria-label="join-button" onClick={() => navigate('/join/intro')}>
                  회원가입
                </div>
              </li>
            </ul>
          </div>
          <button type="submit" className={`strong normal`} aria-label="sign-in-button">
            로그인
          </button>
        </form>
        <p className="hr">
          <span>또는</span>
        </p>
        <SnsLogin isLogin={true} />
        <ul className="note">
          <li>내서재, 문의하기, 오류신고를 하려면 로그인 해야 합니다.</li>
        </ul>
      </section>
      <p className="cr">Copyright ⓒ K·DISCOVERY 2023</p>
    </main>
  )
}

export default Login
