import React, { useCallback, useEffect, useState } from 'react'
import { trendKeywordRepository } from '../repository/TrendKeywordRepository'
import RelatedArticle from '../domain/RelatedArticle'
import Page from '../../common/domain/Page'
import { usePageable } from '../../common/hooks/usePageable'

interface Props {
  keyword: string
  categoryName: string
  frequencyId: string
  publishYear: '2018' | '2019' | '2020' | '2021' | '2022' | '2023' | null
}

const RelatedArticles = ({ keyword, categoryName, frequencyId, publishYear }: Props) => {
  const { setupPage } = usePageable()
  const [relatedArticles, setRelatedArticles] = useState<RelatedArticle[]>([])

  const getRelatedArticlesSuccessHandler = useCallback(
    (currentPage: number) => (articlesPage: Page<RelatedArticle>) => {
      setRelatedArticles(articlesPage.content)
      setupPage(articlesPage, currentPage)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      trendKeywordRepository
        .getRelatedArticles({
          page: currentPage,
          size: 100,
          keyword: keyword,
          frequencyId: frequencyId,
        })
        .then(getRelatedArticlesSuccessHandler(currentPage))
    },
    [keyword, frequencyId, getRelatedArticlesSuccessHandler]
  )

  useEffect(() => {
    moveToPage(0)
  }, [moveToPage])

  return (
    <>
      <header className="popup">
        <dl>
          <dt>[키워드]</dt>
          <dd>{keyword}</dd>
        </dl>
        <dl>
          <dt>[분야]</dt>
          <dd>{categoryName}</dd>
        </dl>
        <dl>
          <dt>[년도]</dt>
          <dd>{publishYear}년</dd>
        </dl>
      </header>

      <main className="popup">
        <div className="table-wrapper">
          <table className="f14">
            <colgroup>
              <col style={{ width: '40px' }} />
              <col style={{ width: '400px' }} />
              <col style={{ width: '150px' }} />
              <col style={{ width: '100px' }} />
              <col style={{ width: '80px' }} />
            </colgroup>

            <thead>
              <tr>
                <th>No</th>
                <th>논문제목</th>
                <th>저자</th>
                <th>발행년도</th>
                <th>저널</th>
              </tr>
            </thead>
            <tbody>
              {relatedArticles.length > 0 &&
                relatedArticles.map((relatedArticle, index) => (
                  <tr key={`related-article-${index}`}>
                    <td className="number">{index + 1}</td>
                    <td>
                      <a
                        className="link"
                        href={`/articles/${relatedArticle.articleId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {relatedArticle.articleTitle}
                      </a>
                    </td>
                    <td>{relatedArticle.authorNames}</td>
                    <td className="number">{relatedArticle.publishYear}</td>
                    <td>
                      <a
                        className="link"
                        href={`/journals/${relatedArticle.journalId}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {relatedArticle.journalTitle}
                      </a>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </main>
    </>
  )
}

export default RelatedArticles
