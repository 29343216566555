import React, { Fragment } from 'react'
import BbsItem from '../domain/BbsItem'
import BbsTableItem from './BbsTableItem'

type Props = {
  moveToDetail: (id: number) => void
  items: BbsItem[]
  isWideCategory?: boolean
}

const BbsTable = ({ moveToDetail, items, isWideCategory }: Props) => {
  return (
    <Fragment>
      {items.length === 0 && <div className="empty-items">게시글을 찾을 수 없습니다.</div>}
      {items.length > 0 && (
        <ul className="table-type bbs-list">
          {items.map((item) => (
            <BbsTableItem
              key={item.id}
              isWideCategory={isWideCategory}
              moveToDetail={() => moveToDetail(item.id)}
              bbsItem={item}
            />
          ))}
        </ul>
      )}
    </Fragment>
  )
}

export default BbsTable
