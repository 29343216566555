import React from 'react'

const MyPageGuide = () => (
  <section className="content">
    <h1 className="normal-head">내 서재 이용안내</h1>
    <div className="guide-wrap">
      <p>
        회원가입 하시면 마이페이지에서 내서재 기능을 이용할 수 있습니다. 내서재 기능을 이용하면 찾는
        자료를 일목요연하게 정리할 수 있습니다. 내서재는 폴더와 자료목록창으로 구성되어 있습니다.
        왼쪽 하단의 상위폴더 추가 기능을 이용하면 기본 폴더를 추가할 수 있습니다.
      </p>
      <h2 className="normal-head">폴더 구성</h2>
      <p className="figure">
        <img src="/static/information/img-library-01.png" alt="이미지-1" />
      </p>
      <p>기본 폴더를 만들면 하위 폴더를 추가할 수 있습니다.</p>
      <p>
        원하는 폴더에 마우스를 대고 오른쪽 버튼을 클릭하면 "삭제", "하위폴더 추가", "이름변경"
        메뉴가 표시됩니다.
      </p>
      <h2 className="normal-head">보관함 저장 방법</h2>
      <p>
        원하는 항목을 체크하고 "보관함"에 저장을 선택하면 "보관함"에 저장되어 내 서재에서 확인할 수
        있습니다.
      </p>
      <h2 className="normal-head">내 서재 구조</h2>
      <p className="figure">
        <img src="/static/information/img-library-02.png" alt="이미지-2" />
      </p>
      <p>내 서재는 보관함에 저장한 목록이 표시됩니다. 한 페이지에 100개까지 표시할 수 있습니다.</p>
    </div>
  </section>
)

export default MyPageGuide
