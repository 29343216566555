import { useCallback, useState } from 'react'

import Page from '../domain/Page'
import PageInfo from '../domain/PageInfo'

export const DEFAULT_PAGE_INFO: PageInfo = {
  totalPages: 10,
  totalElements: 100,
  size: 10,
  currentPage: 0,
}

export const usePageable = () => {
  const [pageInfo, setPageInfo] = useState<PageInfo>(DEFAULT_PAGE_INFO)

  const setupPage = useCallback(
    (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      pageInfo: Page<any>,
      currentPage: number
    ) => {
      setPageInfo({
        totalPages: pageInfo.totalPages,
        totalElements: pageInfo.totalElements,
        size: pageInfo.size,
        currentPage: currentPage,
      })
    },
    []
  )

  return { pageInfo, setupPage }
}
