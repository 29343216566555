import { Fragment, useContext } from 'react'

import Button, { Props } from '../../common/component/Button'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { isAccessible } from '../../common/utils/RoleHierarchy'

type BbsProps = {
  writerUserId?: number
} & Props

export const BbsReportButton = (props: BbsProps) => {
  const { userInfo, isLoggedIn } = useContext(UserContext)!
  if (!userInfo || !isLoggedIn) {
    return <Fragment />
  }
  if (props.writerUserId && props.writerUserId !== userInfo.id) {
    return <Fragment />
  }
  return (
    <Button className={props.className} ariaLabel={props.ariaLabel} onClick={props.onClick}>
      {props.children}
    </Button>
  )
}

export const BbsButton = (props: BbsProps) => {
  const { userInfo, isLoggedIn } = useContext(UserContext)!
  if (!isLoggedIn || !userInfo || !isAccessible(userInfo.level, 'EDITOR')) {
    return <Fragment />
  }
  if (userInfo.level === 'EDITOR' && props.writerUserId && props.writerUserId !== userInfo.id) {
    return <Fragment />
  }
  return (
    <Button className={props.className} ariaLabel={props.ariaLabel} onClick={props.onClick}>
      {props.children}
    </Button>
  )
}
