import * as React from 'react'
import { ForwardedRef, forwardRef, MutableRefObject } from 'react'

import ValidateInput from './ValidateInput'
import { validatePasswordFormat } from '../utils/FormatChecker'

export const runPasswordValidator = (passwordRef: MutableRefObject<HTMLInputElement | null>) => {
  passwordRef.current?.focus()
  passwordRef.current?.blur()
}

const InputPassword = forwardRef(
  (
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    props: any,
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const passwordValidator = (password: string) => {
      return validatePasswordFormat(password)
    }

    return (
      <ValidateInput
        ref={ref}
        label="password-input"
        type="password"
        validators={[
          {
            validator: passwordValidator,
            errorMessage: '영문, 숫자, 특수기호 조합 10자리 이상 비밀번호를 입력 바랍니다.',
          },
        ]}
      />
    )
  }
)

export default InputPassword
