import { useNavigate } from 'react-router-dom'
import Button from '../../common/component/Button'
import SnsLogin from '../component/SnsLogin'
import { serviceDescription } from '../../common/constant/ServiceConstant'

const JoinIntro = () => {
  const navigate = useNavigate()

  return (
    <main className="member">
      <hgroup onClick={() => navigate('/main')} aria-label="main-logo">
        <h1>K·DISCOVERY</h1>
        <h2>{serviceDescription}</h2>
      </hgroup>
      <section>
        <h1>회원가입</h1>
        <p className="announce join-01">
          내 서재, 문의하기, 오류접수 등의 서비스는
          <br />
          회원가입 후 가능합니다.
        </p>
        <Button
          className="strong email"
          ariaLabel="email-button"
          onClick={() => navigate('/join/form')}
        >
          이메일로 회원가입
        </Button>
        <p className="hr">
          <span>또는</span>
        </p>
        <SnsLogin />
        <ul className="note">
          <li>
            여러 개의 소셜 로그인을 사용하시는 경우 로그인 계정 간에 내 서재 및 문의 내역 등
            가입자별 정보가 공유되지 않으므로, 반드시 동일한 계정으로 로그인 하시기를 당부드립니다.
          </li>
        </ul>
      </section>
    </main>
  )
}

export default JoinIntro
