import { createContext, useCallback, useEffect, useState } from 'react'

import UserContextType from '../domain/UserContextType'
import UserInfo from '../domain/UserInfo'
import { userRepository } from '../repository/UserRepository'
import { userInfoRepository } from '../repository/UserInfoRepository'

export const Context = createContext<UserContextType | null>(null)

const UserInfoProvider = ({ children }: { children: JSX.Element }) => {
  const [isLoggedIn, setLoggedIn] = useState<boolean>(userRepository.isLoggedIn())
  const [userInfo, setUserInfo] = useState<UserInfo | null>(userRepository.parseUserInfo())

  const successHandler = useCallback((result: UserInfo) => {
    setUserInfo(result)
    return result
  }, [])

  const loadUserInfo = useCallback(() => {
    return userInfoRepository
      .getLoggedInUserInfo()
      .then(successHandler)
      .catch((e) => {
        if (e.httpStatus === 401) {
          logout()
        }
      })
  }, [successHandler])

  const login = () => {
    setLoggedIn(true)
  }

  const logout = () => {
    setUserInfo(null)
    setLoggedIn(false)
    userRepository.logout()
  }

  useEffect(() => {
    if (isLoggedIn) {
      loadUserInfo()
    }
  }, [isLoggedIn, loadUserInfo])

  return (
    <Context.Provider
      value={{
        userInfo,
        isLoggedIn,
        login,
        logout,
        loadUserInfo,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default UserInfoProvider
