import React, { MouseEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'

import Location from '../../common/component/Location'
import ModalWindow from '../../common/component/ModalWindow'

import TactoProjectDetail, { initialTactoProjectDetail } from '../domain/TactoProjectDetail'
import TopicTreeNode from '../domain/TopicTreeNode'
import Material from '../domain/Material'

import { getProject } from '../repository/TactoProjectRepository'
import { searchSelectedTopic } from '../util/topic-tree'
import TopicTreeSection from '../component/TopicTreeSection'
import TopicTreeManagement from '../component/TopicTreeManagement'
import EditProject from '../component/EditProject'
import MaterialDetails from '../component/MaterialDetails'
import MaterialMemo from '../component/MaterialMemo'
import MaterialAttachment from '../component/MaterialAttachment'
import MaterialsSection from '../component/MaterialsSection'
import MemosSection from '../component/MemosSection'
import MyArticlesSection from '../component/MyArticlesSection'

type ContentSection = 'MaterialsInTopic' | 'Memos' | 'MyArticles' | 'MaterialsInProject'

const initialMaterial: Material = {
  attachmentId: 0,
  authors: '',
  id: 0,
  memoId: 0,
  memoTitle: '',
  publishYear: '',
  publisher: '',
  title: '',
  updatedAt: 0,
}

const TactoProject = () => {
  const { id } = useParams()

  const [createModalOpen, setCreateModalOpen] = useState<boolean>(false)
  const [projectNameModalOpen, setProjectNameModalOpen] = useState<boolean>(false)
  const [collectModalOpen, setCollectModalOpen] = useState<boolean>(false)
  const [materialDetailsModalOpen, setMaterialDetailsModalOpen] = useState<boolean>(false)
  const [memoModalOpen, setMemoModalOpen] = useState<boolean>(false)
  const [attachmentModalOpen, setAttachmentModalOpen] = useState<boolean>(false)

  const [projectDetail, setProjectDetail] = useState<TactoProjectDetail>(initialTactoProjectDetail)
  const [rootTopic, setRootTopic] = useState<TopicTreeNode | null>(null)
  const [selectedTopic, setSelectedTopic] = useState<TopicTreeNode | null>(null)
  const [selectedMaterial, setSelectedMaterial] = useState<Material>(initialMaterial)
  const [contentSection, setContentSection] = useState<ContentSection>('MaterialsInTopic')

  const navigate = useNavigate()

  const fetchProjectDetail = useCallback(() => {
    if (id) {
      getProject(+id).then((detail) => setProjectDetail(detail))
    }
  }, [id])

  useEffect(() => {
    fetchProjectDetail()
  }, [fetchProjectDetail])

  useEffect(() => {
    if (projectDetail && projectDetail.rootNode) {
      setRootTopic(projectDetail.rootNode)
      if (projectDetail.rootNode.children) {
        const firstChild = Object.values(projectDetail.rootNode.children)[0]
        setSelectedTopic(firstChild)
      }
    }
  }, [projectDetail])

  const onSelectTopic = useCallback(
    (id: string) => {
      if (rootTopic) {
        const foundChildTopic = searchSelectedTopic(id, rootTopic)
        setSelectedTopic(foundChildTopic)
        setContentSection('MaterialsInTopic')
      }
    },
    [rootTopic]
  )

  const openCollectModal = useCallback((topicTreeNode: TopicTreeNode) => {
    setSelectedTopic(topicTreeNode)
    setCollectModalOpen(true)
  }, [])

  const openMaterialDetail = useCallback((material: Material) => {
    setSelectedMaterial(material)
    setMaterialDetailsModalOpen(true)
  }, [])

  const openAttachment = useCallback((e: MouseEvent<HTMLButtonElement>, material: Material) => {
    e.stopPropagation()
    setSelectedMaterial(material)
    setAttachmentModalOpen(true)
  }, [])

  const openMemo = useCallback((e: MouseEvent<HTMLButtonElement>, material: Material) => {
    e.stopPropagation()
    setSelectedMaterial(material)
    setMemoModalOpen(true)
  }, [])

  const ContentSectionComponent = useMemo(() => {
    switch (contentSection) {
      case 'MaterialsInTopic':
        return (
          <MaterialsSection
            projectDetail={projectDetail}
            selectedTopic={selectedTopic}
            openMaterialDetail={openMaterialDetail}
            openAttachment={openAttachment}
            openMemo={openMemo}
            collectModalOpen={collectModalOpen}
            setCollectModalOpen={setCollectModalOpen}
            isRoot={false}
          />
        )
      case 'Memos':
        return (
          <MemosSection
            projectDetail={projectDetail}
            openMaterialDetail={openMaterialDetail}
            openAttachment={openAttachment}
            openMemo={openMemo}
          />
        )
      case 'MyArticles':
        return <MyArticlesSection projectDetail={projectDetail} />
      case 'MaterialsInProject':
        return (
          <MaterialsSection
            projectDetail={projectDetail}
            selectedTopic={rootTopic ? { ...rootTopic, name: '자료', description: '' } : null}
            openMaterialDetail={openMaterialDetail}
            openAttachment={openAttachment}
            openMemo={openMemo}
            collectModalOpen={collectModalOpen}
            setCollectModalOpen={setCollectModalOpen}
            isRoot={true}
          />
        )
    }
  }, [
    contentSection,
    projectDetail,
    rootTopic,
    selectedTopic,
    openMaterialDetail,
    openAttachment,
    openMemo,
    collectModalOpen,
  ])

  const defaultRootNode = useMemo((): TopicTreeNode => {
    const id = uuidv4()
    return {
      id: id,
      name: projectDetail.name,
      children: null,
      description: projectDetail.description,
      parentId: id,
      seq: 1,
    }
  }, [projectDetail])

  return (
    <main>
      <ModalWindow isOpen={projectNameModalOpen} closeModal={() => setProjectNameModalOpen(false)}>
        <EditProject
          id={projectDetail.id}
          name={projectDetail.name}
          description={projectDetail.description}
          fetchProjectDetail={fetchProjectDetail}
          closeModal={() => setProjectNameModalOpen(false)}
        />
      </ModalWindow>
      {id && (
        <ModalWindow isOpen={createModalOpen} closeModal={() => setCreateModalOpen(false)}>
          <TopicTreeManagement
            projectId={+id}
            fetchProjectDetail={fetchProjectDetail}
            propsRootNode={projectDetail.rootNode || defaultRootNode}
            closeModal={() => setCreateModalOpen(false)}
          />
        </ModalWindow>
      )}
      <ModalWindow
        isOpen={materialDetailsModalOpen}
        closeModal={() => setMaterialDetailsModalOpen(false)}
      >
        <MaterialDetails
          id={selectedMaterial.id}
          closeModal={() => setMaterialDetailsModalOpen(false)}
        />
      </ModalWindow>
      <ModalWindow isOpen={memoModalOpen} closeModal={() => setMemoModalOpen(false)}>
        <MaterialMemo
          materialId={selectedMaterial.id}
          materialTitle={selectedMaterial.title}
          closeModal={() => setMemoModalOpen(false)}
        />
      </ModalWindow>
      <ModalWindow isOpen={attachmentModalOpen} closeModal={() => setAttachmentModalOpen(false)}>
        <MaterialAttachment
          materialId={selectedMaterial.id}
          materialTitle={selectedMaterial.title}
          closeModal={() => setAttachmentModalOpen(false)}
        />
      </ModalWindow>
      <div className="container">
        <Location paths={['마이페이지', '워크스페이스', projectDetail.name]} />
        <div className="top-section">
          <div className="back" aria-label="back-button" onClick={() => navigate('/tacto')} />
          <div className="project-info">
            <h1 className="head">{projectDetail.name}</h1>
            <div className="description">{projectDetail.description}</div>
          </div>
          <div className="edit" onClick={() => setProjectNameModalOpen(true)}>
            <div className="edit-icon" />
            <button>편집</button>
          </div>
        </div>
        <div className="content-wrap">
          <div className="aside" aria-label="aside-topic-tree">
            <div className="topic-tree-wrap">
              <div className="side-topic-tree">
                <div className="icon tree" />
                <div>{projectDetail.rootNode?.name || projectDetail.name}</div>
                <div
                  onClick={() => setCreateModalOpen(true)}
                  className="icon create-topic-tree"
                  aria-label="topic-creation-icon"
                />
              </div>
              <TopicTreeSection
                rootNode={projectDetail?.rootNode}
                selectedId={selectedTopic?.id ?? ''}
                onSelectTopic={onSelectTopic}
                openCollectModal={openCollectModal}
              />
            </div>
            <div className="menus">
              <div
                className={`menu ${contentSection === 'Memos' ? 'on' : ''}`}
                onClick={() => {
                  setSelectedTopic(null)
                  setContentSection('Memos')
                }}
              >
                <div className="icon memo" />
                <div>
                  메모 <span>{projectDetail.memoCount}</span>
                </div>
              </div>
              <div className="divider" />
              <div
                className={`menu ${contentSection === 'MyArticles' ? 'on' : ''}`}
                onClick={() => {
                  setSelectedTopic(null)
                  setContentSection('MyArticles')
                }}
              >
                <div className="icon article" />
                <div>
                  아티클 <span>{projectDetail.articleCount}</span>
                </div>
              </div>
              <div className="divider" />
              <div
                className={`menu ${contentSection === 'MaterialsInProject' ? 'on' : ''}`}
                onClick={() => {
                  setSelectedTopic(null)
                  setContentSection('MaterialsInProject')
                }}
              >
                <div className="icon material" />
                <div>
                  자료 <span>{projectDetail.materialCount}</span>
                </div>
              </div>
            </div>
          </div>
          <section className="content" aria-label="content">
            {rootTopic === null ? (
              <div className="empty-project">
                <div className="info">프로젝트에 자료가 없습니다.</div>
                <button className="create-topic" onClick={() => setCreateModalOpen(true)}>
                  새 토픽 만들기
                </button>
              </div>
            ) : (
              <>{ContentSectionComponent}</>
            )}
          </section>
        </div>
      </div>
    </main>
  )
}

export default TactoProject
