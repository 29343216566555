import React from 'react'

const SearchGuide = () => (
  <section className="content">
    <h1 className="normal-head">자료검색 안내</h1>
    <div className="guide-wrap">
      <h2 className="normal-head">통합검색</h2>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-search-01.png" />
      </p>
      <p>Kdiscovery는 어디서든지 통합검색을 할 수 있습니다.</p>
      <p>
        통합검색은 논문, 저자, 저널, 발행기관을 조건으로 하여 검색할 수 있습니다. 기본값은
        논문입니다.
      </p>
      <p>논문 검색은 검색어가 제목, 키워드, 초록 등에 포함된 논문을 검색합니다.</p>
      <p>
        저자 검색은 검색어가 저자에 포함된 논문을 검색합니다. 저자 자체를 검색하려면 저자 메뉴로
        들어가서 검색하십시오.
      </p>
      <p>
        저널 검색은 검색어가 저널에 포함된 논문을 검색합니다. 저널 자체를 검색하려면 저널 메뉴로
        들어가서 검색하십시오.
      </p>
      <p>
        발행기관 검색은 검색어가 발행기관에 포함된 논문을 검색합니다. 발행기관 자체를 검색하려면
        발행기관 메뉴를 섬택하여 검색하십시오.
      </p>
      <h2 className="normal-head">검색결과</h2>
      <p>통합 검색을 실행하면 검색 결과 화면이 표시됩니다.</p>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-search-02-01.png" />
      </p>
      <p>① 에서 검색결과 건수와 검색 조건이 표시됩니다. 검색어는 연산자 조건까지 표시됩니다.</p>
      <p>
        ② 에서 검색 필터가 표시됩니다. 검색필터는 분야별, 저널별, 저자별, 연도별, 주제별로 5가
        검색필터가 있습니다. 검색필터의 항목을 클릭하면 그 검색필터가 적용되어 검색 결과 화면이
        표시되고, 원하는 조건 항목들을 체크한 후 "검색필터 적용"을 선택하면 선택된 조건으로
        검색결과가 표시됩니다.
      </p>
      <p>
        분야별 검색필터는 하위분류를 선택할 수 있습니다. ③ 의 하위 분류를 선택하면 다음 화면과 같이
        하위분류가 표시됩니다.
      </p>
      <p>
        모든 검색필터는 최고빈도수 순으로 5개까지 표시되고 이후는 ④ 를 클릭하면 다음 화면과 같이
        표시됩니다.
      </p>
      <p>
        결과내 검색 ⑤ 를 선택하면 현재의 조건에 추가된 검색어를 AND 조건으로 검색합니다. 결과내
        검색은 논문 검색만 가능합니다.
      </p>
      <p>항목 확장 버튼 ③ 을 클릭하면 아래와 같이 항목이 확장됩니다.</p>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-search-02-02.png" />
      </p>
      <p>하위분류 확장 버튼 ④ 를 클릭하면 아래와 같이 하위분류가 확장되어 표시됩니다.</p>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-search-02-03.png" />
      </p>
      <h2 className="normal-head">상세 검색</h2>
      <p>Kdiscovery는 더 정교한 검색을 위해 확장검색(Advanced Search) 기능 제공하고 있습니다.</p>
      <p>상세검색 버튼을 클릭하면 화면과 같이 확장검색 화면이 표시됩니다.</p>
      <p className="figure">
        <img alt="이미지" src="/static/information/img-search-03.png" />
      </p>
      <p>검색범위 ① 에서는 논문, 저널, 저자, 발행기관을 선택할 수 있습니다.</p>
      <p>연산자 추가 ② 를 선택하면 검색조건으로 AND, OR, NOT을 추가할 수 있습니다.</p>
      <p>검색 조건 추가 ③ 으로 새로운 검색 조건을 추가합니다.</p>
      <p>
        컬렉션 ④ 은 KCI 또는 Sciencediret (향후 Pubmed, 특허 추가 예정)만을 대상으로 검색합니다.
      </p>
      <p>대상연도 ⑤ 는 발행연도를 검색조건으로 하여 검색합니다.</p>
      <p>
        검색조건을 시정하면 실시간으로 ⑥ 에 검색식이 구성됩니다. 여기에서 검색식을 수정할 수
        있습니다.
      </p>
    </div>
  </section>
)

export default SearchGuide
