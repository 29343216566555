import { createContext, useState } from 'react'
import SearchConditionContextType from '../domain/SearchConditionContext'
import SelectedSearchFilter from '../domain/SelectedSearchFilter'

export const Context = createContext<SearchConditionContextType | null>(null)

export const EmptySearchConditionSnapshot = {
  searchText: '',
  categories: [],
  journals: [],
  authors: [],
  publishYears: [],
  keywords: [],
  sources: [],
}

const SearchConditionProvider = ({ children }: { children: JSX.Element }) => {
  const [searchText, setSearchText] = useState('')
  const [categoryIds, setCategoryIds] = useState<number[]>([])
  const [journals, setJournals] = useState<Map<number, string>>(new Map())
  const [authors, setAuthors] = useState<Map<number, string>>(new Map())
  const [publishYears, setPublishYears] = useState<Map<string, string>>(new Map())
  const [keywords, setKeywords] = useState<Map<string, string>>(new Map())
  const [sources, setSources] = useState<Map<string, string>>(new Map())
  const [manualSearchTrigger, setManualSearchTrigger] = useState(false)
  const [latestSnapshot, setLatestSnapshot] = useState<SelectedSearchFilter>(
    EmptySearchConditionSnapshot
  )
  const setSearchTextValue = (text: string) => {
    const cleanedText = text.replace(/<\/?[^>]+(>|$)/g, '')
    setSearchText(cleanedText)
  }
  const saveCurrentSnapshot = (categoryPathMap: Map<number, string[]>) => {
    setLatestSnapshot({
      searchText,
      categories: categoryIds.map((id) => {
        return {
          key: id,
          value: categoryPathMap.has(id)
            ? categoryPathMap
                .get(id)!
                .filter((path) => path !== 'ROOT')
                .join('/')
            : '',
        }
      }),
      journals: Array.from(journals).map((entry) => {
        return { key: entry[0], value: entry[1] }
      }),
      authors: Array.from(authors).map((entry) => {
        return { key: entry[0], value: entry[1] }
      }),
      publishYears: Array.from(publishYears).map((entry) => {
        return { key: entry[0], value: entry[1] }
      }),
      keywords: Array.from(keywords).map((entry) => {
        return { key: entry[0], value: entry[1] }
      }),
      sources: Array.from(sources).map((entry) => {
        return { key: entry[0], value: entry[1] }
      }),
    })
  }
  const clearConditions = () => {
    setSearchText('')
    setCategoryIds([])
    setJournals(new Map())
    setAuthors(new Map())
    setPublishYears(new Map())
    setKeywords(new Map())
    setSources(new Map())
  }

  return (
    <Context.Provider
      value={{
        searchText,
        setSearchText: setSearchTextValue,
        manualSearchTrigger,
        setManualSearchTrigger,
        categoryIds,
        setCategoryIds,
        journals,
        setJournals,
        authors,
        setAuthors,
        publishYears,
        setPublishYears,
        keywords,
        setKeywords,
        sources,
        setSources,
        latestSnapshot,
        setLatestSnapshot,
        saveCurrentSnapshot,
        clearConditions,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default SearchConditionProvider
