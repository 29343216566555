import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import Button from '../../common/component/Button'
import InputPassword from '../../common/component/InputPassword'
import InputPasswordConfirm, {
  validatePasswordConfirm,
} from '../../common/component/InputPasswordConfirm'

import { userRepository } from '../repository/UserRepository'
import { validatePasswordFormat } from '../../common/utils/FormatChecker'
import { serviceDescription } from '../../common/constant/ServiceConstant'

const ResetPassword = () => {
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const passwordRef = useRef<HTMLInputElement>(null)
  const passwordConfirmRef = useRef<HTMLInputElement>(null)

  const tokenValidateHandler = useCallback(
    (result: boolean) => {
      if (!result) {
        alert('접속 경로가 유효하지 않습니다.')
        navigate('/main')
      }
    },
    [navigate]
  )

  useEffect(() => {
    const emailParam = searchParams.get('email')
    const tokenParam = searchParams.get('token')
    if (emailParam && tokenParam) {
      setEmail(emailParam)
      setToken(tokenParam)
      userRepository.validateResetPasswordToken(emailParam, tokenParam).then(tokenValidateHandler)
    } else {
      navigate('/main')
    }
  }, [tokenValidateHandler, navigate, searchParams])

  const successChangePasswordHandler = () => {
    alert('비밀번호가 변경되었습니다.')
    navigate('/login')
  }

  const failChangePasswordHandler = (error: Error) => {
    alert(error.message)
    navigate('/main')
  }

  const submitPasswordChange = () => {
    const password = passwordRef.current?.value ?? ''
    if (!validatePasswordFormat(password)) {
      return
    }
    if (!validatePasswordConfirm(passwordConfirmRef, password)) {
      return
    }
    userRepository
      .changePassword({
        email,
        token,
        password,
      })
      .then(successChangePasswordHandler)
      .catch(failChangePasswordHandler)
  }

  return (
    <main className="member">
      <hgroup>
        <h1>K·DISCOVERY</h1>
        <h2>{serviceDescription}</h2>
      </hgroup>
      <section>
        <h1>비밀번호 재설정</h1>
        <p className="announce password-02">
          {email} 님의
          <br />
          본인 확인이 완료되었습니다.
          <br />
          사용하실 비밀번호를 입력해 주세요.
        </p>
        <div>
          <ul className="my-form full">
            <li>
              <label>비밀번호</label>
              <InputPassword ref={passwordRef} />
              <p className="guide">영문+숫자+특수문자 조합 10자리 이상으로 입력해 주세요.</p>
            </li>
            <li>
              <label>비밀번호 확인</label>
              <InputPasswordConfirm passwordRef={passwordRef} ref={passwordConfirmRef} />
            </li>
          </ul>
          <Button className="strong" ariaLabel="confirm-button" onClick={submitPasswordChange}>
            확인
          </Button>
        </div>
      </section>
    </main>
  )
}

export default ResetPassword
