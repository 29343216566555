import TrendKeywordRepository, {
  RelatedArticlesParams,
  TrendKeywordParams,
} from './TrendKeywordRepository'
import Page from '../../common/domain/Page'
import TrendKeyword from '../domain/TrendKeyword'
import axios from 'axios'
import RelatedArticle from '../domain/RelatedArticle'
import PageInfo from '../../common/domain/PageInfo'
import Pageable from '../../common/domain/Pageable'

class AxiosTrendKeywordRepository implements TrendKeywordRepository {
  async getTrendKeywords(params: TrendKeywordParams): Promise<Page<TrendKeyword>> {
    const { data } = await axios.get('/app/api/trend/keywords', {
      params,
    })
    return this.createTrendKeywordsPage(data)
  }

  private createTrendKeywordsPage(
    data: PageInfo & { pageable: Pageable } & { content: TrendKeyword[] }
  ): Page<TrendKeyword> {
    return {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      size: data.size,
      pageable: data.pageable,
      content: data.content,
    }
  }

  async getRelatedArticles(params: RelatedArticlesParams): Promise<Page<RelatedArticle>> {
    const { data } = await axios.get('/app/api/trend/keyword/related-articles', {
      params,
    })
    return this.createRelatedArticlesPage(data)
  }

  private createRelatedArticlesPage(
    data: PageInfo & { pageable: Pageable } & { content: RelatedArticle[] }
  ): Page<RelatedArticle> {
    return {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      size: data.size,
      pageable: data.pageable,
      content: data.content,
    }
  }
}

export default AxiosTrendKeywordRepository
