import React, { ChangeEvent, Fragment, useCallback, useMemo, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Button from '../../common/component/Button'
import InputEmail from '../../common/component/InputEmail'
import ValidateInput from '../../common/component/ValidateInput'
import InputPassword, { runPasswordValidator } from '../../common/component/InputPassword'
import InputPasswordConfirm, {
  runPasswordCheckValidator,
  validatePasswordConfirm,
} from '../../common/component/InputPasswordConfirm'

import PrivateTerms from '../component/PrivateTerms'
import KDiscoveryTerms from '../component/KDiscoveryTerms'
import { joinRepository } from '../repository/JoinRepository'
import { userRepository } from '../repository/UserRepository'

import { validateEmailFormat, validatePasswordFormat } from '../../common/utils/FormatChecker'
import CheckBox from '../../common/component/CheckBox'
import { serviceDescription } from '../../common/constant/ServiceConstant'

type DuplicationStatus = 'duplicated' | 'usable' | 'notVerified'
type TermsType = 'terms' | 'privacy'

function JoinForm() {
  const navigator = useNavigate()

  const [emailDuplicationStatus, setEmailDuplicationStatus] =
    useState<DuplicationStatus>('notVerified')

  const [isAllChecked, setAllChecked] = useState<boolean>(false)
  const [checkedTerms, setCheckedTerms] = useState<TermsType[]>([])
  const [openTerms, setOpenTerms] = useState<TermsType | null>(null)
  const [emailReceiveAgreement, setEmailReceiveAgreement] = useState<boolean>(true)

  const nickNameRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const passwordConfirmRef = useRef<HTMLInputElement>(null)

  const emailCheckOnClick = async () => {
    const email = emailRef.current?.value
    if (email === undefined || !validateEmailFormat(email)) {
      alert('이메일을 입력하세요.')
      return
    }
    const isEmailDuplicated = await userRepository.isEmailDuplicated(email)
    if (isEmailDuplicated) {
      setEmailDuplicationStatus('duplicated')
    } else {
      setEmailDuplicationStatus('usable')
    }
  }

  const onChangeReceiveAgreement = (event: ChangeEvent<HTMLInputElement>) => {
    setEmailReceiveAgreement(event.target.value === 'true')
  }

  const onCheckBoxAllChangeHandler = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setCheckedTerms(['terms', 'privacy'])
    } else {
      setCheckedTerms([])
    }
    setAllChecked(event.target.checked)
  }, [])

  const onCheckBoxChangeHandler = useCallback(
    (event: ChangeEvent<HTMLInputElement>, termsType: TermsType) => {
      const existsSet = new Set(checkedTerms)
      if (event.target.checked) {
        existsSet.add(termsType)
      } else {
        existsSet.delete(termsType)
      }
      setCheckedTerms(Array.from(existsSet))
    },
    [checkedTerms]
  )

  const runNickNameValidator = () => {
    nickNameRef.current?.focus()
    nickNameRef.current?.blur()
  }

  const isUsable = (status: string) => {
    return status === 'usable'
  }

  const validateForm = (password: string, nickName: string) => {
    const isEmailValid = isUsable(emailDuplicationStatus)
    if (!isEmailValid) {
      alert('이메일 중복 확인이 필요합니다.')
      return false
    }
    const isPasswordValid = validatePasswordFormat(password)
    if (!isPasswordValid) {
      runPasswordValidator(passwordRef)
    }
    const passwordConfirm = passwordConfirmRef.current?.value ?? ''
    const isPasswordConfirmValid = validatePasswordConfirm(passwordRef, passwordConfirm)
    if (!isPasswordConfirmValid) {
      runPasswordCheckValidator(passwordConfirmRef)
    }
    const isNickNameValid = nickName !== ''
    if (!isNickNameValid) {
      runNickNameValidator()
    }
    const isTermsValid = checkedTerms.length === 2
    if (!isTermsValid) {
      alert('모든 약관에 동의가 필요합니다.')
      return
    }
    return (
      isEmailValid && isPasswordValid && isPasswordConfirmValid && isNickNameValid && isTermsValid
    )
  }

  async function requestJoin() {
    const password = passwordRef.current?.value ?? ''
    const nickName = nickNameRef.current?.value ?? ''
    if (!validateForm(password, nickName)) {
      return
    }
    const email = emailRef.current?.value ?? ''
    const requestSuccess = await joinRepository.requestJoin({
      email: email,
      password: password,
      nickName: nickName,
      emailReceiveAgreement,
    })
    if (requestSuccess) {
      navigator('/join/requested')
    }
  }

  const emailDuplicateClass = useMemo(() => {
    if (emailDuplicationStatus === 'duplicated') {
      return 'error'
    } else if (emailDuplicationStatus === 'usable') {
      return 'usable'
    }
    return ''
  }, [emailDuplicationStatus])

  const emailDuplicateMessage = useMemo(() => {
    if (emailDuplicationStatus === 'duplicated') {
      return '이미 존재하는 이메일입니다.'
    } else if (emailDuplicationStatus === 'usable') {
      return '사용할 수 있는 이메일입니다.'
    }
    return ''
  }, [emailDuplicationStatus])

  return (
    <Fragment>
      <main className="member">
        <hgroup onClick={() => navigator('/main')} aria-label="main-logo">
          <h1>K·DISCOVERY</h1>
          <h2>{serviceDescription}</h2>
        </hgroup>
        <section>
          <h1>회원가입</h1>
          <ul className="my-form full">
            <li>
              <label>이메일</label>
              <div className="duplicate-check">
                <div className={`duplicate-check-input ${emailDuplicateClass}`}>
                  <div className="with-btn">
                    <InputEmail
                      ref={emailRef}
                      validationCallback={() => setEmailDuplicationStatus('notVerified')}
                    />
                    <Button ariaLabel="email-check-button" onClick={emailCheckOnClick}>
                      중복확인
                    </Button>
                  </div>
                  <div className="message">{emailDuplicateMessage}</div>
                </div>
              </div>
              <p className="guide">이메일은 비밀번호 찾기에 사용되므로 정확하게 입력해 주세요.</p>
            </li>
            <li>
              <label>비밀번호</label>
              <InputPassword ref={passwordRef} />
              <p className="guide">영문+숫자+특수문자 조합 10~30자리로 입력해 주세요.</p>
            </li>
            <li>
              <label>비밀번호 확인</label>
              <InputPasswordConfirm ref={passwordConfirmRef} passwordRef={passwordRef} />
            </li>
            <li>
              <label>닉네임</label>
              <ValidateInput
                ref={nickNameRef}
                label="nickname-input"
                type="text"
                validators={[
                  {
                    validator: (value) => value?.length > 0,
                    errorMessage: '닉네임을 입력하시길 바랍니다.',
                  },
                ]}
              />
            </li>
            <li>
              <label>메일링 수신</label>
              <div className="check-group">
                <label className="radio">
                  <input
                    onChange={onChangeReceiveAgreement}
                    name="email-receive"
                    type="radio"
                    value="true"
                    checked={emailReceiveAgreement}
                  />
                  <span>수신 동의</span>
                </label>
                <label className="radio">
                  <input
                    onChange={onChangeReceiveAgreement}
                    name="email-receive"
                    type="radio"
                    value="false"
                    checked={!emailReceiveAgreement}
                  />
                  <span>수신 거부</span>
                </label>
              </div>
              <p className="guide">
                학술관련 뉴스, 행사소식, 학술대회, DB 업데이트 등 K-Discovery 소식을 메일로
                보내드립니다.
              </p>
            </li>
          </ul>
          <dl className="agree">
            <dt>
              <label className="checkbox">
                <CheckBox
                  ariaLabel="all-checkbox"
                  className="unit"
                  isAllChecked={isAllChecked}
                  onChange={onCheckBoxAllChangeHandler}
                />
                <span>전체 동의합니다.</span>
              </label>
            </dt>
            <dd>
              <label className="checkbox">
                <CheckBox
                  ariaLabel="terms-checkbox"
                  className="unit"
                  onChange={(event) => onCheckBoxChangeHandler(event, 'terms')}
                  isAllChecked={isAllChecked}
                />
                <span>K·DISCOVERY 이용약관에 동의합니다.</span>
              </label>
              <p
                className="call-pop"
                data-pop="terms"
                aria-label="terms"
                onClick={() => setOpenTerms('terms')}
              >
                내용보기
              </p>
            </dd>
            <dd>
              <label className="checkbox">
                <CheckBox
                  ariaLabel="privacy-checkbox"
                  className="unit"
                  onChange={(event) => onCheckBoxChangeHandler(event, 'privacy')}
                  isAllChecked={isAllChecked}
                />
                <span>개인정보 수집 및 이용에 동의합니다.</span>
              </label>
              <p
                className="call-pop"
                data-pop="privacy"
                aria-label="privacy"
                onClick={() => setOpenTerms('privacy')}
              >
                내용보기
              </p>
            </dd>
          </dl>
          <Button className="strong join" ariaLabel="join-button" onClick={requestJoin}>
            가입하기
          </Button>
        </section>
      </main>
      <KDiscoveryTerms isOpen={openTerms === 'terms'} close={() => setOpenTerms(null)} />
      <PrivateTerms isOpen={openTerms === 'privacy'} close={() => setOpenTerms(null)} />
    </Fragment>
  )
}

export default JoinForm
