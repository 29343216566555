import OrganizationInfo from '../domain/OrganizationInfo'
import OrganizationInfoRepository from './OrganizationInfoRepository'
import axios from 'axios'

export class AxiosOrganizationInfoRepository implements OrganizationInfoRepository {
  async getOrganizationInfo(): Promise<OrganizationInfo> {
    const { data } = await axios.get('/app/api/organization-info')
    return data
  }
}
