import { ChangeEvent, useCallback, useState } from 'react'

export const useCheckbox = () => {
  const [isAllChecked, setAllChecked] = useState(false)
  const [checkedItems, setCheckedItems] = useState(new Set<number>())

  const clearAllChecked = useCallback(() => {
    setCheckedItems(new Set())
    setAllChecked(false)
  }, [])

  const onAllCheckHandler = useCallback(
    (
      event: ChangeEvent<HTMLInputElement>,
      // eslint-disable-next-line
      items: any[]
    ) => {
      if (event.target.checked) {
        items.map((item) => checkedItems.add(item.id))
      } else {
        checkedItems.clear()
      }
      setCheckedItems(new Set(checkedItems))
      setAllChecked(event.target.checked)
    },
    [checkedItems]
  )

  const onCheckedHandler = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    if (event.target.checked) {
      checkedItems.add(id)
    } else {
      checkedItems.delete(id)
    }
    setCheckedItems(new Set<number>(checkedItems))
  }

  return { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked }
}
