import React from 'react'
import Journal from '../domain/Journal'

export interface Props {
  journal: Journal
}

const JournalListComponent = ({ journal }: Props) => {
  const journalDetailComponent = (detail: string | undefined) => {
    if (detail && detail.trim() !== '') {
      return <span>{detail}</span>
    }
    return <></>
  }
  const firstIssn = (journal: Journal) => {
    return Array.of(journal.issn, journal.printIssn, journal.electronicIssn).find((issn) => issn)
  }
  return (
    <li>
      <a
        aria-label={`journal-link-${journal.id}`}
        href={`/journals/${journal.id}`}
        target="_blank"
        rel="noreferrer"
      >
        <span>
          <h1>{journal.name}</h1>
          <h2>{journal.publisher}</h2>
          <p className="desc">
            {journalDetailComponent(firstIssn(journal))}
            {journalDetailComponent(journal.country)}
            {journal.articlesCount > 0 && journalDetailComponent(`논문수 ${journal.articlesCount}`)}
          </p>
        </span>
      </a>
    </li>
  )
}

export default JournalListComponent
