import { useEffect, useMemo, useState } from 'react'
import { initOwlCarousel } from '../../owl-carousel.js'
import { HomeSlideItem } from '../domain/HomeSlideItem'
import { mainRepository } from '../repository/MainRepository'

const CoverCarousel = () => {
  const [activeTab, setActiveTab] = useState<string>('doc-latest')
  const [homeSlideTabs, setHomeSlideTabs] = useState<Map<string, HomeSlideItem[]>>(new Map())

  const getTabLabelName = (name: string) => {
    switch (name) {
      case '최신저널':
        return 'doc-latest'
      case '인문사회':
        return 'doc-humanities'
      case '자연과학':
        return 'doc-nature'
      case '의약학':
        return 'doc-medicine'
      case '공업기술':
        return 'doc-industry'
      default:
        return 'doc-latest'
    }
  }

  const changeTab = (tabName: string) => {
    setActiveTab(tabName)
  }

  const activateTab = (tabName: string) => {
    if (tabName === activeTab) {
      return 'on'
    }
    return ''
  }

  const owlCarouselClass = useMemo(() => 'book-list doc-list owl-carousel owl-loaded owl-drag', [])

  useEffect(() => {
    if (homeSlideTabs.size > 0) {
      initOwlCarousel('div.book-list')
    }
  }, [homeSlideTabs])

  useEffect(() => {
    mainRepository.getHomeSlideImages().then((tabs) => {
      const slideTabsMap = new Map<string, HomeSlideItem[]>()
      for (const tab of tabs) {
        slideTabsMap.set(tab.name, tab.items)
      }
      setHomeSlideTabs(slideTabsMap)
    })
  }, [])

  return (
    <section className="neo-home-journal">
      <div className="neo-container">
        <h2 className="neo-home">추천저널</h2>
        <ul className="neo-book-tab">
          {Array.from(homeSlideTabs.keys()).map((name) => (
            <li
              key={`doc-tab-${name}`}
              onClick={() => changeTab(getTabLabelName(name))}
              className={activateTab(getTabLabelName(name))}
              aria-label={getTabLabelName(name)}
            >
              {name}
            </li>
          ))}
        </ul>
        <div className="neo-book-wrap">
          {Array.from(homeSlideTabs.entries()).map((entry) => {
            return (
              <div
                key={`doc-items-${entry[0]}`}
                id={getTabLabelName(entry[0])}
                className={`${owlCarouselClass} ${activateTab(getTabLabelName(entry[0]))}`}
              >
                {entry[1].map((item) => (
                  <div className="book" key={`${getTabLabelName(entry[0])}-${item.id}`}>
                    <a href={item.link} aria-label={`cover-link-${item.id}`}>
                      <img
                        alt={`cover-latest-${item.id}`}
                        src={item.imageSource}
                        title={item.title}
                      />
                    </a>
                  </div>
                ))}
              </div>
            )
          })}
        </div>
      </div>
    </section>
  )
}

export default CoverCarousel
