import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import ArticleExporter, { exporterFactory, ExportType } from '../utils/ArticleExporter'

type Props = {
  ariaLabel: string
  onSelectCallback: (articleExporter: ArticleExporter) => void
}

const ExportArticlesDropdown = ({ ariaLabel, onSelectCallback }: Props) => {
  const [isOpen, changeIsOpen] = useState<boolean>(false)
  const selectBoxRef = useRef<HTMLDivElement>(null)
  const [options] = useState<ExportType[]>(['Endnote', 'RefWorks', 'BibTex', 'Excel'])

  const changeOpenStatus = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation()
    if (isOpen) {
      selectBoxRef.current?.classList.remove('open')
    } else {
      selectBoxRef.current?.classList.add('open')
    }
    changeIsOpen(!isOpen)
  }

  const changeCurrentOption = (option: ExportType) => {
    selectBoxRef.current?.classList.remove('open')
    const exporter = exporterFactory(option)
    onSelectCallback(exporter)
    changeIsOpen(false)
  }

  useEffect(() => {
    const outsideClick = (event: Event) => {
      if (event.target === selectBoxRef.current) {
        return
      }
      selectBoxRef.current?.classList.remove('open')
      changeIsOpen(false)
    }
    window.addEventListener('click', outsideClick)
    return () => {
      window.removeEventListener('click', outsideClick)
    }
  }, [])

  return (
    <div
      ref={selectBoxRef}
      aria-label={ariaLabel}
      className="export-dropdown"
      tabIndex={0}
      onClick={changeOpenStatus}
    >
      <span aria-label={`${ariaLabel}-current`} className="current export">
        내보내기
      </span>
      <ul aria-label={`${ariaLabel}-options`} className="list">
        {options.map((option, index) => (
          <li
            key={`${ariaLabel}-options-${index}`}
            className="option"
            onClick={() => changeCurrentOption(option)}
          >
            {option}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default ExportArticlesDropdown
