import axios from 'axios'

import Page from '../../common/domain/Page'

import BbsItem from '../domain/BbsItem'
import BbsRepository, {
  BbsRequest,
  BulletinBoardItemCommand,
  BulletinBoardItemCommentCommand,
} from './BbsRepository'

class AxiosBbsRepository implements BbsRepository {
  async getBulletinBoardDetail(id: number): Promise<BbsItem> {
    const { data } = await axios.get(`/app/api/bbs/details/${id}`)
    return data
  }

  async getBulletinBoardItems(type: string, params: BbsRequest): Promise<Page<BbsItem>> {
    const { data } = await axios.get(`/app/api/bbs/${type}`, {
      params,
    })
    return data
  }

  async uploadImage(file: File): Promise<string> {
    const formData = new FormData()
    formData.append('file', file)
    const { data } = await axios.post('/app/api/bbs/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    return data
  }

  async createPost(bulletinBoardItemCommand: BulletinBoardItemCommand): Promise<void> {
    await axios.post('/app/api/bbs', bulletinBoardItemCommand)
  }

  async createComment(
    bulletinBoardItemCommentCommand: BulletinBoardItemCommentCommand
  ): Promise<void> {
    await axios.post('/app/api/bbs/comment', bulletinBoardItemCommentCommand)
  }

  async updatePost(id: number, bulletinBoardItemCommand: BulletinBoardItemCommand): Promise<void> {
    await axios.put(`/app/api/bbs/${id}`, bulletinBoardItemCommand)
  }
}

export default AxiosBbsRepository
