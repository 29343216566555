import * as React from 'react'
import { ForwardedRef, forwardRef, MutableRefObject } from 'react'
import ValidateInput from './ValidateInput'

export const runPasswordCheckValidator = (
  passwordConfirmRef: MutableRefObject<HTMLInputElement | null>
) => {
  passwordConfirmRef.current?.focus()
  passwordConfirmRef.current?.blur()
}

export const validatePasswordConfirm = (
  passwordRef: MutableRefObject<HTMLInputElement | null>,
  passwordConfirm: string
) => {
  const password = passwordRef.current?.value
  return password === passwordConfirm
}

const InputPasswordConfirm = forwardRef(
  (
    props: { passwordRef: MutableRefObject<HTMLInputElement | null> },
    ref: ForwardedRef<HTMLInputElement>
  ) => {
    const passwordCheckEmptyValidator = (passwordConfirm: string) => {
      return passwordConfirm !== ''
    }
    const passwordCheckValidator = (passwordConfirm: string) => {
      return validatePasswordConfirm(passwordConfirm)
    }
    const validatePasswordConfirm = (passwordConfirm: string) => {
      const password = props.passwordRef.current?.value
      return password === passwordConfirm
    }
    return (
      <ValidateInput
        ref={ref}
        label="password-confirm-input"
        type="password"
        validators={[
          {
            validator: passwordCheckEmptyValidator,
            errorMessage: '비밀번호를 입력하길 바랍니다.',
          },
          {
            validator: passwordCheckValidator,
            errorMessage: '비밀번호가 일치하지 않습니다.',
          },
        ]}
      />
    )
  }
)

export default InputPasswordConfirm
