type Props = {
  isOpen: boolean
  close: () => void
}

const KDiscoveryTerms = ({ isOpen, close }: Props) => {
  return (
    <div className={`pop-wrap ${isOpen ? 'on' : ''}`} id="terms">
      <div className="pop-window">
        <h1>
          <span>K·DISCOVERY 이용약관</span>
        </h1>
        <div className="pop-cnt">
          <div className="doc">
            <h1>제1장 총칙</h1>
            <h2>제1조 (목적)</h2>
            <p>
              이 약관은 이지메타(주)가(이하 “회사”로 약칭) 운영하는 모든 서비스(사전, 메타데이터 등
              모든 '콘텐츠'와 기타 다양한 서비스를 총칭)를 이용하는 고객(이하 “회원”)과 회사가
              서비스의 이용조건 및 절차에 관한 사항과 기타 필요한 사항을 규정함을 목적으로 합니다.
            </p>
            <h2>제2조 (용어의 정의)</h2>
            <ol>
              <li>
                <p>① 이 약관에서 사용하는 용어의 정의는 다음과 같습니다.</p>
                <ol>
                  <li>
                    <p>
                      ‘이용자’라 함은 이 약관에 따라 회사가 제공하는 서비스를 이용하는 회원 및
                      비회원을 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘개인회원’이라 함은 개인정보를 제공하여 회원가입 후 이용자ID를 부여 받고 이
                      약관에 따라 회사가 제공하는 서비스를 이용하는 이용자를 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘계약된 기관’은 별도의 오프라인 계약을 통하여 회사와 계약된 기관을 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘기관이용자’란 ‘계약된 기관’의 소속원으로서 등록된 기관 내에서 이용하는
                      이용자를 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>‘기관회원’이란 ‘개인회원’이 ‘계약된 기관’의 소속원인 경우를 말합니다.</p>
                  </li>
                  <li>
                    <p>
                      ‘비회원’이라 함은 회원으로 가입하지 않고 서비스를 이용하는 이용자를 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘이용계약’이라 함은 이 약관에 동의하고 서비스의 회원으로 가입하는 행위를
                      말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘이용자ID’라 함은 회원의 식별 및 서비스 이용을 위하여 회원의 신청에 따라
                      회사가 회원별로 부여하는 고유한 문자 또는 숫자를 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘비밀번호’라 함은 이용자ID로 식별되는 회원의 본인 여부를 검증하기 위하여
                      회원이 설정하여 회사에 등록한 고유의 문자 또는 숫자를 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>
                      ‘탈퇴’라 함은 개인회원 또는 기관회원이 이용계약을 해지하고 이용자ID를 삭제하는
                      것을 말합니다.
                    </p>
                  </li>
                  <li>
                    <p>‘해지’라 함은 회사 또는 계약된 기관이 이용계약을 해약하는 것을 말합니다.</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  ② 이 약관에서 사용하는 용어 중 제1항에서 정하지 아니한 것은 관계 법령 및 서비스
                  안내에서 정하는 바에 따르며, 그 외에는 일반 관계에 따릅니다.
                </p>
              </li>
            </ol>
            <h2>제3조 (약관의 효력과 변경)</h2>
            <ol>
              <li>
                <p>
                  ① 서비스는 이 약관에 규정된 조항을 변경 없이 이용자가 수락하는 것을 조건으로
                  제공됩니다. "’이용약관’에 동의하십니까?"라는 물음에 회원이 '동의' 버튼을 클릭함은
                  이 약관에 대하여 이용자가 동의한다는 것을 의미합니다.
                </p>
              </li>
              <li>
                <p>
                  ② 이 약관은 이용자에게 온라인을 통해 공시함으로써 효력을 발생합니다. 회사는 사정
                  변경의 경우와 영업상 중요 사유가 있을 때 약관을 임의로 변경할 수 있으며, 변경된
                  약관은 서비스 화면에 게시되거나 기타의 방법으로 회원에게 공지됨으로써 효력이
                  발생됩니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 회원은 변경된 약관에 동의하지 않을 경우, 서비스 이용을 중단하고 탈퇴할 수
                  있습니다. 약관이 변경된 이후에도 계속적으로 서비스를 이용하는 경우에는 회원이
                  약관의 변경 사항에 동의한 것으로 봅니다.
                </p>
              </li>
            </ol>
            <h2>제4조 (약관 외 준칙)</h2>
            <ol>
              <li>
                <p>
                  ① 이 약관에 명시되지 않은 사항이 국내 관계법령에 규정되어 있을 경우에는 관련 법령
                  규정에 따릅니다.
                </p>
              </li>
              <li>
                <p>
                  ② 약관이 정한 서비스 규정 이외의 개별 서비스 사용에 대한 약관의 동의는 개별 이용
                  동의 절차에 따릅니다.
                </p>
              </li>
            </ol>
            <h1>제2장 회원가입</h1>
            <h2>제5조 (이용계약의 성립)</h2>
            <ol>
              <li>
                <p>
                  ① 이용 계약은 이용자의 이용 신청에 대한 회사의 이용 승낙으로 성립되며, 이용자의
                  약관 내용에 대한 동의는 ‘동의’ 버튼을 누름으로써 성립됩니다.
                </p>
              </li>
              <li>
                <p>
                  ② 회원에 가입하여 서비스를 이용하고자 하는 희망자는 회사가 정한 소정의 양식에 따른
                  개인 신상정보(이용자ID, 비밀번호, 닉네임, 전자우편 등)를 제공해야 합니다. 이용자가
                  제공한 개인정보는 관련법 및 "회사"의 개인정보취급방침에 따라 철저히 보호됩니다.
                  다만, "회사"의 공식 사이트 이외의 링크된 사이트에서는 "회사"의 개인정보취급방침이
                  적용되지 않습니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 가입신청 양식에 기재된 회원 정보는 실제 데이터로 간주됩니다. 실제 정보를
                  입력하지 않은 회원은 법적인 보호를 받을 수 없습니다. 모든 회원은 반드시 회원
                  본인의 정보를 제공하여야만 서비스를 이용할 수 있으며, 타인의 정보를 도용하거나
                  허위의 정보를 등록하는 등 본인의 진정한 정보를 등록하지 않은 회원은 서비스 이용과
                  관련하여 아무런 권리를 주장할 수 없으며, 관계 법령에 따라 처벌 받을 수 있습니다.
                </p>
              </li>
            </ol>
            <h2>제6조 (이용신청의 승낙)</h2>
            <ol>
              <li>
                <p>
                  ① 회사는 회원이 모든 사항을 정확히 기재하여 신청할 경우 서비스 이용을 승낙합니다.
                  단, 제2항의 '이용신청의 제한'에 해당하는 경우 이를 승낙하지 아니 합니다.
                </p>
              </li>
              <li>
                <p>② 이용신청의 제한은 아래와 같은 경우에 해당합니다.</p>
                <ol>
                  <li>
                    <p>다른 사람의 명의를 사용하여 신청하였을 때</p>
                  </li>
                  <li>
                    <p>이용 계약 신청서의 내용을 허위로 기재하였을 때</p>
                  </li>
                  <li>
                    <p>사회의 안녕 질서 혹은 미풍양속을 저해할 목적으로 신청하였을 때</p>
                  </li>
                  <li>
                    <p>회사와 계약된 기관의 소속원이 아닐 때</p>
                  </li>
                  <li>
                    <p>기타 회사가 정한 이용 신청 요건이 미비 되었을 때</p>
                  </li>
                </ol>
              </li>
            </ol>
            <h2>제7조 (서비스 이용 및 제한)</h2>
            <ol>
              <li>
                <p>
                  ① 서비스 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴, 1일
                  24시간을 원칙으로 합니다.
                </p>
              </li>
              <li>
                <p>
                  ② 회사에서 제공하는 서비스 중 일부는 회원에 가입하여 회사에서 인정한 이용자ID와
                  비밀번호를 통해서만 서비스를 받을 수 있습니다.
                </p>
              </li>
            </ol>
            <h2>제8조 (회원의 혜택)</h2>
            <ol>
              <li>
                <p>① 회사의 회원 가입은 무료입니다.</p>
              </li>
              <li>
                <p>
                  ② 회원은 회사에서 주관하는 각종 이벤트나 행사에 자동 가입되어 여러 가지 혜택을
                  받을 수 있습니다. 이와 동시에 발생하는 각종 권리 의무관계는 회원 가입 시 동의한
                  것으로 갈음합니다.
                </p>
              </li>
            </ol>
            <h1>제3장 계약 해지 및 서비스 이용 제한</h1>
            <h2>제9조 (계약 해지 및 이용 제한)</h2>
            <ol>
              <li>
                <p>
                  ① 회원은 서비스 각종 기능과 전자우편을 통해서 회원정보처리에 관한 불만사항을
                  개진할 수 있습니다.
                </p>
              </li>
              <li>
                <p>
                  ② 회원이 서비스 이용 계약을 해지하고자 할 때는 회원 본인이 직접 회원정보수정
                  메뉴의 개인정보관리 페이지에서 해지 신청을 해야 합니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 회사는 회원이 다음 사항에 해당하는 행위를 하였을 경우 사전 통지 없이 이용 계약을
                  해지하거나 또는 기간을 정하여 서비스 이용을 중지할 수 있습니다.
                </p>
                <ol>
                  <li>
                    <p>공공질서 및 미풍양속에 반하는 경우</p>
                  </li>
                  <li>
                    <p>범죄 행위에 관련되는 경우</p>
                  </li>
                  <li>
                    <p>
                      국익 또는 사회적 공익을 저해할 목적으로 서비스 이용을 계획 또는 실행할 경우
                    </p>
                  </li>
                  <li>
                    <p>타인의 이용자ID및 비밀번호를 도용한 경우</p>
                  </li>
                  <li>
                    <p>타인의 명예를 손상시키거나 불이익을 주는 경우</p>
                  </li>
                  <li>
                    <p>서비스에 위해를 가하는 등 건전한 이용을 저해하는 경우</p>
                  </li>
                  <li>
                    <p>기타 관련법령이나 회사가 정한 이용조건에 위배되는 경우</p>
                  </li>
                </ol>
              </li>
              <li>
                <p>
                  ④ 회원이 1년 동안 회사의 서비스를 이용하기 위해 로그인한 기록이 없는 경우, 원활한
                  회원관리를 위하여 휴면계정으로 처리하여 회원자격으로의 활동 일체를 제한할 수
                  있습니다. 다만, 회원이 회사가 정한 절차에 따라 휴면계정 철회를 요청하면 회사는
                  종전의 회원자격을 다시 활성화하여야 합니다.
                </p>
              </li>
            </ol>
            <h2>제10조 (이용 제한 절차)</h2>
            <p>
              회사는 이용제한을 하고자 하는 경우에는 그 사유, 일시 및 기간을 정하여 이용자의
              전자우편 또는 전화 등의 방법에 의하여 해당 이용자 또는 대리인에게 통지합니다. 다만,
              회사가 긴급하게 이용을 정지할 필요가 있다고 인정하는 경우에는 그러하지 아니할 수
              있습니다.
            </p>
            <h1>제4장 책임</h1>
            <h2>제11조 (회사의 의무)</h2>
            <ol>
              <li>
                <p>
                  ① 회사는 특별한 사정이 없는 한 회원이 신청한 서비스를 이용할 수 있도록 합니다.
                </p>
              </li>
              <li>
                <p>
                  ② 회사는 이 약관에서 정한 바에 따라 계속적, 안정적으로 서비스를 제공할 의무가
                  있으며 이를 위해 회사는 노력해야 하며, 부득이한 이유로 서비스가 중단되면 지체 없이
                  이를 수리 복구합니다. 단, 천재지변, 비상사태, 그 밖의 부득이한 경우에는 그
                  서비스를 일시 중단하거나 중지할 수 있습니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 회사는 회원으로부터 제기되는 의견이나 불만이 정당하다고 인정할 경우에는 즉시
                  처리하여야 합니다.
                </p>
              </li>
              <li>
                <p>
                  ④ 회사는 서비스 제공으로 알게 된 회원의 신상정보를 본인의 승낙 없이 제3자에게
                  누설, 배포하지 않습니다. 단, 전기통신기본법 등 법률의 규정에 의해 국가기관이
                  요구하는 경우, 범죄에 대한 수사상의 목적이 있거나 정보통신윤리위원회의 요청이 있는
                  경우, 기타 관계법령에서 정한 절차에 따른 요청이 있는 경우에는 그러하지 않습니다.
                </p>
              </li>
              <li>
                <p>
                  ⑤ 회사와 제휴를 맺은 사이트와 회사의 사이트를 편리하게 이용할 수 있도록 하기 위해
                  회사는 회원의 정보를 제휴 사이트들과 공유할 수 있으며, 공유를 위해 회사는 회원의
                  컴퓨터에 쿠키를 전송할 수 있습니다.
                </p>
              </li>
              <li>
                <p>
                  ⑥ 제5항의 범위 내에서, 회사는 회원의 전체 또는 일부 정보를 업무와 관련된 통계
                  자료로 사용할 수 있습니다.
                </p>
              </li>
            </ol>
            <h2>제11조 (회원의 의무)</h2>
            <ol>
              <li>
                <p>① 이용자ID와 비밀번호에 관한 모든 관리의 책임은 회원에게 있습니다.</p>
              </li>
              <li>
                <p>② 회원은 자신의 이용자ID를 타인에게 양도, 증여할 수 없습니다.</p>
              </li>
              <li>
                <p>
                  ③ 자신의 이용자ID가 부정하게 사용된 경우, 회원은 반드시 회사에 그 사실을
                  전자우편이나 기타 방법을 이용해 통보해야 합니다.
                </p>
              </li>
              <li>
                <p>④ 회원은 이 약관 및 관계법령에서 규정한 사항을 준수하여야 합니다.</p>
              </li>
            </ol>
            <h1>제5장 정보 및 광고의 제공</h1>
            <h2>제12조 (정보 및 광고의 제공)</h2>
            <ol>
              <li>
                <p>
                  ① 회원의 정보는 철저히 보안유지 될 것이며, 이 약관 제11조 제5항의 규정에 따라 공유
                  제공될 수 있습니다.
                </p>
              </li>
              <li>
                <p>
                  ② 회사는 회원에게 필요한 정보를 전자우편이나 서신우편 등의 방법으로 전달할 수
                  있으며, 회원은 이를 원하지 않을 경우 가입신청 메뉴와 회원정보수정 메뉴에서
                  정보수신거부를 할 수 있습니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 회사는 광고주로부터 요청 받은 광고를 회사의 판단에 따라 전자우편을 통해
                  회원들에게 제공할 수 있습니다.
                </p>
              </li>
            </ol>
            <h1>제6장 일반 사항</h1>
            <h2>제13조 (일반 사항)</h2>
            <ol>
              <li>
                <p>
                  ① 이 약관은 대한민국 관련 법률의 적용을 받습니다. 서비스 이용자는 이 사이트의
                  사용으로부터 발생하거나 그와 관련된 모든 분쟁이 발생한 경우 회사 관할 법원에
                  전속적 관할권이 있음에 동의합니다. 따라서 이 약관의 효력을 인정하지 않는
                  관할지역내에서는 이 사이트의 사용이 허가되지 아니합니다.
                </p>
              </li>
              <li>
                <p>
                  ② 이용자는 회사 사이트 이용의 결과로서, 이용자와 회사 간에 어떠한 합작투자,
                  파트너십, 고용 또는 대리인 관계가 존재하지 않는 것에 동의합니다. 회사가 이 약관을
                  수행함에 있어서는 현존하는 법 및 법적 절차의 적용을 받습니다. 이 약관에 규정된
                  어떠한 조항에 의하여도 이 서비스 또는 그 사용과 관련하여 회사가 수집하거나
                  제공받은 정보의 사용과 관련한 정부, 법원 및 법률 집행 당국의 요청 또는 요구사항을
                  준수할 회사의 권리가 훼손되지 아니합니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 앞서 규정한 책임의 부인을 포함하는 이 계약 일부 조항이 적용법에 의하여
                  무효이거나 실행할 수 없는 것으로 판단되는 경우, 그 무효이거나 실행할 수 없는
                  조항은 본래 조항의 취지에 가장 부합하는 것으로 유효하거나 실행 가능한 조항으로
                  대체될 것이며, 계약의 나머지 조항은 계속 유효합니다.
                </p>
              </li>
            </ol>
            <h1>제7장 기타</h1>
            <h2>제14조 (저작권의 귀속 및 이용제한)</h2>
            <ol>
              <li>
                <p>① 회사가 작성한 저작물에 대한 저작권 기타 지적재산권은 회사에 귀속합니다.</p>
              </li>
              <li>
                <p>
                  ② 이용자는 회사를 이용함으로써 얻은 정보를 회사의 사전 승낙 없이 복제, 송신, 출판,
                  배포, 방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는
                  안 됩니다.
                </p>
              </li>
            </ol>
            <h2>제15조 (분쟁해결)</h2>
            <ol>
              <li>
                <p>
                  ① 회사는 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기
                  위하여 피해보상처리기구를 설치•운영합니다.
                </p>
              </li>
              <li>
                <p>
                  ② 회사는 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다.
                  다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해
                  드립니다.
                </p>
              </li>
              <li>
                <p>
                  ③ 회사와 이용자 간에 발생한 분쟁은 전자거래기본법 제28조 및 동 시행령 제15조에
                  의하여 설치된 전자거래분쟁조정위원회의 조정에 따를 수 있습니다.
                </p>
              </li>
            </ol>
            <h2>제16조 (재판권 및 준거법)</h2>
            <ol>
              <li>
                <p>
                  ① 회사와 이용자 간에 발생한 전자거래 분쟁에 관한 소송은 민사소송법상의 관할법원에
                  제기합니다.
                </p>
              </li>
              <li>
                <p>② 회사와 이용자 간에 제기된 전자거래 소송에는 한국법을 적용합니다.</p>
              </li>
            </ol>
            <h1>제8장 특별규정</h1>
            <h2>제17조(법령 및 준용)</h2>
            <p>
              본 약관에 명시하지 않은 사항은 전자거래 기본법, 전자서명법, 방문판매법 및 기타
              관련법령의 회사 적용 규정에 따르며 기타 상관례에 따릅니다.
            </p>
            <div className="supple">
              <h2>(부칙)</h2>
              <p>
                이 약관은 2023년 11월 1일부터 적용되며, 종전의 약관은 이 약관으로 대체합니다. 이
                약관의 적용일자 이전 가입자 또한 이 약관의 적용을 받습니다.
              </p>
            </div>
          </div>
        </div>
        <button type="button" className="close" aria-label="close-terms" onClick={close}>
          닫기
        </button>
      </div>
    </div>
  )
}

export default KDiscoveryTerms
