import Header from './Header'
import React, { Fragment } from 'react'
import Footer from './Footer'
import { Outlet, useLocation } from 'react-router-dom'
import AdvancedSearchModal from './AdvancedSearchModal'

const Layout = () => {
  const location = useLocation()
  return (
    <Fragment>
      <Header isHome={location.pathname === '/main'} />
      <AdvancedSearchModal />
      <Outlet />
      <Footer />
    </Fragment>
  )
}

export default Layout
