import React from 'react'

const Advertisement = () => (
  <aside className="aside">
    <a href="http://classicbooks.kr/" target="_blank" rel="noreferrer" className="ad-bnr">
      <img alt="광고" src="/static/ads/K-DISCOVERY_BNR-02.png" />
    </a>
    <a href="http://kmed.kdata.co.kr/" target="_blank" rel="noreferrer" className="ad-bnr">
      <img alt="광고" src="/static/ads/K-DISCOVERY_BNR-01.png" />
    </a>
    <a
      href="http://caj.ezmeta.co.kr:8080/cajkor/home"
      target="_blank"
      rel="noreferrer"
      className="ad-bnr"
    >
      <img alt="광고" src="/static/ads/K-DISCOVERY_BNR-03.png" />
    </a>
  </aside>
)

export default Advertisement
