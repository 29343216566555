import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import HeadCharButtonGroup from '../../common/component/HeadCharButtonGroup'
import Location from '../../common/component/Location'
import Advertisement from '../../common/component/Advertisement'
import Button from '../../common/component/Button'
import Pagination from '../../common/component/Pagination'
import Page from '../../common/domain/Page'
import { usePageable } from '../../common/hooks/usePageable'

import AuthorComponent from '../component/AuthorComponent'
import Author from '../domain/Author'
import { authorRepository } from '../repository/AuthorRepository'
import { Context as SearchTextContext } from '../../article/provider/SearchConditionProvider'
import RingLoadingProgress from '../../common/component/RingLoadingProgress'

function Authors() {
  const { pageInfo, setupPage } = usePageable()
  const [searchParams] = useSearchParams()

  const [isLoading, setIsLoading] = useState(false)
  const [authors, setAuthors] = useState<Author[]>([])
  const [headChar, setHeadChar] = useState<string[]>([])
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const nameRef = useRef<HTMLInputElement>(null)
  const { manualSearchTrigger } = useContext(SearchTextContext)!

  const getAuthorsSuccessHandler = useCallback(
    (currentPage: number) => (authorPage: Page<Author>) => {
      setAuthors(authorPage.content)
      setupPage(authorPage, currentPage)
      setIsLoading(false)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number, currentName?: string, currentHeadChars?: string[]) => {
      setIsLoading(true)
      const name = currentName ? currentName : (nameRef.current?.value ?? '')
      const headCharacter = currentHeadChars ? currentHeadChars.join(',') : headChar.join(',')
      authorRepository
        .getAuthors({
          page: currentPage,
          size: 10,
          name,
          headCharacter,
        })
        .then(getAuthorsSuccessHandler(currentPage))
    },
    [headChar, nameRef, getAuthorsSuccessHandler]
  )

  const searchByName = () => {
    moveToPage(0, nameRef.current?.value ?? '', [])
  }

  useEffect(() => {
    const authorName = searchParams.get('name')
    if (initialLoad && authorName && authorName.trim() !== '') {
      nameRef.current!.value = authorName
      setInitialLoad(false)
    }
    if (headChar.length > 0) {
      nameRef.current!.value = ''
    }
    moveToPage(0)
  }, [headChar, moveToPage, searchParams, initialLoad])

  useEffect(() => {
    const authorName = searchParams.get('name')
    if (authorName && authorName.trim() !== '') {
      nameRef.current!.value = authorName
      moveToPage(0)
    }
    // eslint-disable-next-line
  }, [manualSearchTrigger, searchParams])

  return (
    <main>
      <div className="container">
        <Location paths={['저자']} />
        <h1 className="head">저자</h1>
        <div className="content-wrap reverse">
          <Advertisement />
          <section className="content">
            <HeadCharButtonGroup headChar={headChar} setHeadChar={setHeadChar} />
            <div className="result-summary above">
              <p></p>
              <fieldset className="sort">
                <input
                  type="text"
                  aria-label="author-search-input"
                  defaultValue=""
                  ref={nameRef}
                  placeholder="저자명을 입력해 주세요"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') searchByName()
                  }}
                />
                <Button ariaLabel="author-search-button" onClick={searchByName}>
                  검색
                </Button>
              </fieldset>
            </div>
            <RingLoadingProgress
              isLoading={isLoading}
              componentToRender={
                <>
                  <ul className="author-list">
                    {authors.map((author, index) => {
                      return (
                        <li key={`author-${index}`}>
                          <AuthorComponent author={author} />
                        </li>
                      )
                    })}
                  </ul>
                  {authors.length > 0 ? (
                    <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
                  ) : (
                    <div className="result-summary" aria-label="no-search-result">
                      <p className="sum">
                        <strong className="number">
                          "{nameRef.current?.value ? nameRef.current!.value : headChar.join(',')}"
                        </strong>
                        에 대한 검색 결과가 없습니다
                      </p>
                    </div>
                  )}
                </>
              }
            />
          </section>
        </div>
      </div>
    </main>
  )
}

export default Authors
