import React, { useContext, useEffect, useRef } from 'react'

import Button from '../../common/component/Button'
import { Context as UserContext } from '../provider/UserInfoProvider'
import { userInfoRepository } from '../repository/UserInfoRepository'

import { errorMessageHandler } from '../../common/utils/AlertMessage'

type Props = {
  storageId: number
  storageName: string
  closeModal: () => void
}

const StorageNameModifyModal = ({ storageId, storageName, closeModal }: Props) => {
  const { loadUserInfo } = useContext(UserContext)!
  const storageNameRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (storageNameRef.current) {
      storageNameRef.current.value = storageName
    }
  }, [storageName])

  const modifySuccessHandler = () => {
    loadUserInfo()
    alert('정상적으로 수정되었습니다.')
    closeModal()
  }

  const modifyHandler = () => {
    const storageName = storageNameRef.current?.value ?? ''
    if (!storageName) {
      alert('보관함 이름을 입력하세요.')
      return
    }
    userInfoRepository
      .updateArticlesStorage({
        storageId,
        storageName,
      })
      .then(modifySuccessHandler)
      .catch(errorMessageHandler)
  }

  return (
    <div className="storage-name">
      <input type="text" className="modify-input" aria-label="modify-input" ref={storageNameRef} />
      <Button className="modify-button" onClick={modifyHandler} ariaLabel="modify-button">
        확인
      </Button>
    </div>
  )
}

export default StorageNameModifyModal
