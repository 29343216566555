import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

import TopicTreeNode from '../domain/TopicTreeNode'
import { saveTopicTree } from '../repository/TactoProjectRepository'
import { searchSelectedTopic } from '../util/topic-tree'
import CreateTopicSection from './CreateTopicSection'
import EditableTopicTreeSection from './EditableTopicTreeSection'
import ModifyTopicSection from './ModifyTopicSection'
import DeleteTopicSection from './DeleteTopicSection'

export type RightSection = 'create' | 'modify' | 'delete' | ''

type Props = {
  projectId: number
  propsRootNode: TopicTreeNode
  fetchProjectDetail: () => void
  closeModal: () => void
}

const TopicTreeManagement = ({
  projectId,
  propsRootNode,
  fetchProjectDetail,
  closeModal,
}: Props) => {
  const [rootNodeName, setRootNodeName] = useState<string>(propsRootNode.name)
  const [rootNodeDescription, setRootNodeDescription] = useState<string>(propsRootNode.description)
  const [selectedTopic, setSelectedTopic] = useState<TopicTreeNode | null>(null)
  const [rootNode, setRootNode] = useState<TopicTreeNode>(JSON.parse(JSON.stringify(propsRootNode)))
  const [currentRightSection, setCurrentRightSection] = useState<RightSection>('')

  useEffect(() => {
    if (selectedTopic !== rootNode) {
      setCurrentRightSection('')
    }
  }, [selectedTopic, rootNode])

  const onSelectTopic = useCallback(
    (id: string) => {
      if (rootNode) {
        const foundTopic = searchSelectedTopic(id, rootNode)
        setSelectedTopic(foundTopic)
      }
    },
    [rootNode]
  )

  const onSaveTopic = useCallback(() => {
    if (rootNode) {
      saveTopicTree(projectId, {
        ...rootNode,
        name: rootNodeName,
        description: rootNodeDescription,
      }).then(fetchProjectDetail)
      closeModal()
    }
  }, [rootNode, projectId, rootNodeName, rootNodeDescription, fetchProjectDetail, closeModal])

  const onCreateTopicHandler = useCallback(
    (topicName: string) => {
      const id = uuidv4()
      if (rootNode && selectedTopic) {
        const foundTopic = searchSelectedTopic(selectedTopic.id, rootNode)!
        if (!foundTopic.children) {
          foundTopic.children = {}
        }
        const size = Object.keys(foundTopic.children).length
        foundTopic.children = {
          ...foundTopic.children,
          [id]: {
            id,
            name: topicName,
            description: '',
            parentId: foundTopic.id,
            seq: size + 1,
            children: {},
          },
        }
        setRootNode({ ...rootNode })
        setSelectedTopic(foundTopic)
      } else {
        const newTopic = {
          id,
          name: topicName,
          description: '',
          parentId: id,
          seq: 1,
          children: {},
        }
        setRootNode(newTopic)
        setSelectedTopic(newTopic)
      }
      setCurrentRightSection('')
    },
    [rootNode, selectedTopic]
  )

  const onChangeTopicHandler = useCallback(
    ({ name, description }: { name: string; description: string }) => {
      if (rootNode && selectedTopic) {
        const foundTopic = searchSelectedTopic(selectedTopic.id, rootNode)!
        foundTopic.name = name
        foundTopic.description = description
        setRootNode({ ...rootNode })
        setSelectedTopic(null)
      }
    },
    [rootNode, selectedTopic]
  )

  const onDeleteTopicHandler = useCallback(() => {
    if (rootNode && selectedTopic) {
      const foundTopic = searchSelectedTopic(selectedTopic.parentId, rootNode)!
      if (foundTopic.children) {
        delete foundTopic.children[selectedTopic.id]
        Object.values(foundTopic.children)
          .sort((node1, node2) => node1.seq - node2.seq)
          .forEach((node, index) => (node.seq = index + 1))
      }
      setRootNode({ ...rootNode })
      setSelectedTopic(null)
    }
  }, [rootNode, selectedTopic])

  const RightSection = useCallback(() => {
    switch (currentRightSection) {
      case 'create':
        return <CreateTopicSection onCreateTopic={onCreateTopicHandler} />
      case 'modify':
        return (
          <ModifyTopicSection
            name={selectedTopic?.name || ''}
            description={selectedTopic?.description || ''}
            onChangeTopic={onChangeTopicHandler}
          />
        )
      case 'delete':
        return (
          <DeleteTopicSection
            name={selectedTopic?.name || ''}
            onDeleteTopic={onDeleteTopicHandler}
            onClose={() => onSelectTopic('')}
          />
        )
      default:
        return <Fragment />
    }
  }, [
    currentRightSection,
    onCreateTopicHandler,
    selectedTopic,
    onChangeTopicHandler,
    onDeleteTopicHandler,
    onSelectTopic,
  ])

  const newTopicHandler = useCallback(() => {
    setSelectedTopic(rootNode)
    setCurrentRightSection('create')
  }, [rootNode])

  return (
    <div aria-label={`project-${projectId}`} className="topic-tree-management-modal">
      <div className="tree-item">
        <div className="label">토픽트리 이름</div>
        <input
          className="text-box"
          aria-label="topic-tree-name"
          value={rootNodeName}
          onChange={(e) => setRootNodeName(e.target.value)}
        />
      </div>
      <div className="tree-item">
        <div className="label">토픽트리 설명</div>
        <input
          className="text-box"
          aria-label="topic-tree-description"
          value={rootNodeDescription}
          onChange={(e) => setRootNodeDescription(e.target.value)}
        />
      </div>
      <div className="management-sections">
        <div aria-label="left-section" className="left-section">
          <div className="section-top">
            <div className="title">현재 토픽트리</div>
            <button onClick={newTopicHandler}>새 토픽</button>
          </div>
          <EditableTopicTreeSection
            rootNode={rootNode}
            selectedId={selectedTopic?.id || ''}
            onSelectTopic={onSelectTopic}
            onSetCurrentRightSection={setCurrentRightSection}
          />
        </div>
        <div className="right-section" aria-label="right-section">
          <RightSection />
        </div>
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="저장" onClick={onSaveTopic} />
      </div>
    </div>
  )
}

export default TopicTreeManagement
