import Page from '../../common/domain/Page'

import AxiosBbsRepository from './AxiosBbsRepository'
import BbsItem from '../domain/BbsItem'

export interface BbsRequest {
  page: number
  size: number
  sort: string
  searchType: string
  key: string
  value: string
}

export type BulletinBoardItemCommand = {
  type: string
  detailType: string
  title: string
  content: string
  writer: string
}

export type BulletinBoardItemCommentCommand = {
  boardId: number
  content: string
}

export default interface BbsRepository {
  getBulletinBoardItems(type: string, params: BbsRequest): Promise<Page<BbsItem>>

  getBulletinBoardDetail(id: number): Promise<BbsItem>

  uploadImage(file: File): Promise<string>

  createPost(bulletinBoardItemCommand: BulletinBoardItemCommand): Promise<void>

  createComment(bulletinBoardItemCommentCommand: BulletinBoardItemCommentCommand): Promise<void>

  updatePost(id: number, bulletinBoardItemCommand: BulletinBoardItemCommand): Promise<void>
}

export const bbsRepository = new AxiosBbsRepository()
