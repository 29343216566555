import React from 'react'

interface TitleComponentProps {
  title: string
  subTitle: string | null | undefined
}

const TitleComponent = ({ title, subTitle }: TitleComponentProps) => {
  return (
    <hgroup className="box">
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
      <h2 dangerouslySetInnerHTML={{ __html: subTitle || '' }} />
    </hgroup>
  )
}

export default TitleComponent
