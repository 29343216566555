import React, { useCallback, useState } from 'react'
import { updateProject } from '../repository/TactoProjectRepository'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

type Props = {
  id: number
  name: string
  description: string
  fetchProjectDetail: () => void
  closeModal: () => void
}

const EditProject = ({ id, name, description, fetchProjectDetail, closeModal }: Props) => {
  const [editedName, setEditedName] = useState(name)
  const [editedDescription, setEditedDescription] = useState(description)

  const onSaveHandler = useCallback(() => {
    updateProject(id, {
      name: editedName,
      description: editedDescription,
    }).then(fetchProjectDetail)
    closeModal()
  }, [id, editedName, editedDescription, fetchProjectDetail, closeModal])

  return (
    <div aria-label="edit-project" className="edit-project-modal">
      <div className="title">프로젝트 편집</div>
      <div className="item">
        <div className="label">프로젝트 이름</div>
        <input
          className="project-name"
          aria-label="project-name"
          type="text"
          value={editedName}
          onChange={(e) => setEditedName(e.currentTarget.value)}
          placeholder="프로젝트 이름"
        />
      </div>
      <div className="item">
        <div className="label">프로젝트 설명</div>
        <textarea
          aria-label="project-description"
          className="project-description"
          value={editedDescription}
          onChange={(e) => setEditedDescription(e.currentTarget.value)}
          placeholder="프로젝트 설명"
        />
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="저장" onClick={onSaveHandler} />
      </div>
    </div>
  )
}

export default EditProject
