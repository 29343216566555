import axios from 'axios'

import Page from '../../common/domain/Page'
import Journal from '../domain/Journal'
import JournalCount from '../domain/JournalCount'
import { JournalRepository, JournalRequest } from './JournalRepository'

class AxiosJournalRepository implements JournalRepository {
  async getJournals({
    page,
    size,
    name,
    headCharacter,
    categoryId,
  }: JournalRequest): Promise<Page<Journal>> {
    const { data } = await axios.get('/app/api/journals', {
      params: {
        page,
        size,
        name: name ?? '',
        headCharacter: headCharacter?.join(',') ?? '',
        categoryId: categoryId ? categoryId : '',
      },
    })
    return data
  }

  async getJournal(journalId: number): Promise<Journal> {
    const { data } = await axios.get(`/app/api/journals/${journalId}`)
    return data
  }

  async getJournalCountPerCategory(): Promise<JournalCount[]> {
    const { data } = await axios.get(`/app/api/categories/counts/journals`)
    return data
  }
}

export default AxiosJournalRepository
