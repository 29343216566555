import { createContext, useCallback, useEffect, useState } from 'react'

import Category from '../domain/Category'
import CategoryContextType from '../domain/CategoryContextType'
import { categoryRepository } from '../repository/CategoryRepository'

export const Context = createContext<CategoryContextType | null>(null)

function CategoryProvider({ children }: { children: JSX.Element }) {
  const [categoryTree, setCategoryTree] = useState<Category | null>(null)
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(0)
  const [categoryPaths, setCategoryPaths] = useState<string[]>([])

  const selectCategory = useCallback((id: number, paths: string[]) => {
    setSelectedCategoryId(id)
    setCategoryPaths(paths)
  }, [])

  const chooseLeafCategory = useCallback((category: Category): Category => {
    const children = category?.children
    if (!children) {
      return category
    }
    const firstChild = Array.from(children.values())[0]
    if (!firstChild) {
      return category
    }
    return chooseLeafCategory(firstChild)
  }, [])

  useEffect(() => {
    categoryRepository.getCategoryTree().then((category) => {
      const leafChild = chooseLeafCategory(category)
      setSelectedCategoryId(leafChild.id)
      setCategoryPaths(leafChild.paths)
      setCategoryTree(category)
    })
  }, [chooseLeafCategory])

  return (
    <Context.Provider
      value={{
        categoryTree,
        selectCategory,
        selectedCategoryId,
        categoryPaths,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default CategoryProvider
