import React, { Fragment } from 'react'

import Category from '../domain/Category'
import CategoryNavigatorTree from './CategoryNavigatorTree'
import SelectedIdProvider from '../provider/SelectedIdProvider'

interface Props {
  key: number
  categoryTree: Map<number, Category> | null | undefined
  selectCategory: (id: number, paths: string[]) => void
  showCount: boolean
  markOnlyLeafNodeSelection: boolean
}

const CategoryNavigator = ({
  categoryTree = new Map(),
  selectCategory,
  showCount = true,
  markOnlyLeafNodeSelection = false,
}: Props) => {
  if (!categoryTree || categoryTree?.size === 0) {
    return <Fragment />
  }
  const values = categoryTree.values()
  return (
    <aside aria-label="articles-category" className="aside">
      <nav className="lnb">
        <ul id="tree">
          <li className="on">
            <p className="toggle head">
              <span>분야별</span>
              <i></i>
            </p>
            <ul>
              <SelectedIdProvider>
                <CategoryNavigatorTree
                  categories={values}
                  selectCategory={selectCategory}
                  showCount={showCount}
                  markOnlyLeafNodeSelection={markOnlyLeafNodeSelection}
                />
              </SelectedIdProvider>
            </ul>
          </li>
        </ul>
      </nav>
    </aside>
  )
}

export default CategoryNavigator
