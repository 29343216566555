import React, { ChangeEvent, forwardRef, Ref, useEffect, useState } from 'react'

import withValidate from './enhancer/withValidate'

export type PropsInputBox = {
  label: string
  type: string
  value?: string
  readOnly?: boolean
  errorMessage: string
  placeholder?: string
  onValidate: (value: string) => void
}

const InputBox = forwardRef((props: PropsInputBox, ref: Ref<HTMLInputElement>) => {
  const [text, setText] = useState<string>(props.value ?? '')

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value)
  }

  useEffect(() => {
    if (props.value) {
      setText(props.value)
    }
  }, [props.value])

  return (
    <div className={`validate-input ${props.errorMessage ? 'error' : ''}`}>
      <input
        aria-label={props.label}
        type={props.type}
        onBlur={() => props.onValidate(text)}
        onChange={onChangeHandler}
        value={text}
        ref={ref}
        readOnly={props.readOnly}
        placeholder={props.placeholder ?? ''}
      />
      {props.errorMessage && <div className="message">{props.errorMessage}</div>}
    </div>
  )
})

const ValidatedInput = withValidate(InputBox)

export default ValidatedInput
