import Journal from '../domain/Journal'
import EmptyJournal from '../../styles/assets/image/journal_blank.png'

type Props = {
  journal: Journal
}

const JournalDetails = ({ journal }: Props) => {
  const journalDetailComponent = (title: string, detail: string | undefined) => {
    if (detail && detail.trim() !== '') {
      return (
        <li>
          <dl>
            <dt>{title}</dt>
            <dd>{detail}</dd>
          </dl>
        </li>
      )
    }
    return <></>
  }

  return (
    <>
      <figure className="journal-cover">
        <img src={journal.image ?? EmptyJournal} alt="journal" />
      </figure>
      <ul className="journal-info">
        {journalDetailComponent('발행국가', journal.country)}
        {journalDetailComponent('발행기관', journal.publisher)}
        {journalDetailComponent('발행주기', journal.publishCycleKorean)}
        {journalDetailComponent('ISSN', journal.issn)}
        {journalDetailComponent('Print ISSN', journal.printIssn)}
        {journalDetailComponent('Online ISSN', journal.electronicIssn)}
      </ul>
    </>
  )
}

export default JournalDetails
