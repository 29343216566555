import { isEmpty, isNotEmpty } from '../../common/utils/TextUtils'
import Article from '../domain/Article'

export default class ArticleExportConverter {
  protected getJoinedKeywords(article: Article, separator: string): string {
    const keywords = article.keywords
    const subKeywords = article.subKeywords
    const filteredKeywords = keywords
      .filter((keyword) => keyword && keyword.trim() !== '')
      .map((keyword) => keyword.replace('<mark>', '').replace('</mark>', ''))
    const filteredSubKeywords = subKeywords
      .filter((keyword) => keyword && keyword.trim() !== '')
      .map((keyword) => keyword.replace('<mark>', '').replace('</mark>', ''))

    if (filteredKeywords.length === 0 && filteredSubKeywords.length === 0) {
      return ''
    }

    if (filteredKeywords.length > 0 && filteredSubKeywords.length === 0) {
      return filteredKeywords.join(separator)
    }

    if (filteredKeywords.length === 0 && filteredSubKeywords.length > 0) {
      return filteredSubKeywords.join(separator)
    }

    return `${filteredKeywords.join(separator)}${separator}${filteredSubKeywords.join(separator)}`
  }

  protected getValidAuthorNames(article: Article): string[] {
    return article.authors
      .map((author) => this.getValidData(author.name, author.subName))
      .filter((authorName) => authorName.trim() !== '')
  }

  protected getValidData(main: string | undefined | null, sub: string | undefined | null): string {
    if (isEmpty(main) && isEmpty(sub)) {
      return ''
    }

    if (isNotEmpty(main) && isEmpty(sub)) {
      return main!
    }

    if (isEmpty(main) && isNotEmpty(sub)) {
      return sub!
    }

    return `${main} (${sub})`
  }
}
