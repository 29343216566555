import SearchFilterValue from './SearchFilterValue'

export default interface SelectedSearchFilter {
  searchText: string
  categories: SearchFilterValue<number>[]
  journals: SearchFilterValue<number>[]
  authors: SearchFilterValue<number>[]
  publishYears: SearchFilterValue<string>[]
  keywords: SearchFilterValue<string>[]
  sources: SearchFilterValue<string>[]
}

export const isDifferentCondition = (
  conditionA: SelectedSearchFilter,
  conditionB: SelectedSearchFilter
) => {
  const differentSearchText = conditionA.searchText !== conditionB.searchText
  const differentCategories = isDifferentSearchFilterValue(
    conditionA.categories,
    conditionB.categories
  )
  const differentJournals = isDifferentSearchFilterValue(conditionA.journals, conditionB.journals)
  const differentAuthors = isDifferentSearchFilterValue(conditionA.authors, conditionB.authors)
  const differentPublishYears = isDifferentSearchFilterValue(
    conditionA.publishYears,
    conditionB.publishYears
  )
  const differentKeywords = isDifferentSearchFilterValue(conditionA.keywords, conditionB.keywords)
  const differentSources = isDifferentSearchFilterValue(conditionA.sources, conditionB.sources)
  return (
    differentSearchText ||
    differentCategories ||
    differentJournals ||
    differentAuthors ||
    differentPublishYears ||
    differentKeywords ||
    differentSources
  )
}

export const isDifferentSearchFilterValue = (
  valuesA: SearchFilterValue<number | string>[],
  valuesB: SearchFilterValue<number | string>[]
) => {
  if (valuesA.length !== valuesB.length) {
    return true
  }

  valuesA.forEach((valueA, index) => {
    const valueB = valuesB[index]
    if (valueA.key !== valueB.key || valueA.value !== valueB.value) {
      return true
    }
  })

  return false
}
