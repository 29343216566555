import React, { useCallback, useContext, useEffect, useState } from 'react'
import Location from '../../common/component/Location'
import { Context as CategoryContext } from '../../article/provider/CategoryProvider'
import CategoryNavigator from '../../article/component/CategoryNavigator'
import Category from '../../article/domain/Category'
import { LanguageType, trendKeywordRepository } from '../repository/TrendKeywordRepository'
import Page from '../../common/domain/Page'
import TrendKeyword from '../domain/TrendKeyword'
import { usePageable } from '../../common/hooks/usePageable'
import Pagination from '../../common/component/Pagination'
import RelatedArticles from '../component/RelatedArticles'
import CreateDOM from 'react-dom/client'
import '../../styles/components/_trend-issue-popup.scss'
import { isEmpty } from '../../common/utils/TextUtils'

const TrendIssue = () => {
  const { categoryTree } = useContext(CategoryContext)!
  const { pageInfo, setupPage } = usePageable()

  const [keywords, setKeywords] = useState<TrendKeyword[]>([])
  const [categoryMap, setCategoryMap] = useState<Map<number, Category>>(new Map())
  const [selectedCategoryId, setSelectedCategoryId] = useState<number>(3)
  const [selectedCategoryPath, setSelectedCategoryPath] = useState<string[]>([])
  const [selectedLanguage, setSelectedLanguage] = useState<LanguageType>('ALL')

  const buildCategoryPathToShow = useCallback(
    (paths: string[]) => (paths ? paths.filter((path) => path !== 'ROOT').join('/') : ''),
    []
  )

  const openRelatedArticlesModal = useCallback(
    (
      keyword: string,
      frequencyId: string,
      publishYear: '2018' | '2019' | '2020' | '2021' | '2022' | '2023'
    ) => {
      const popupWindow = window.open('', '_blank', 'width=1200,height=800')!
      popupWindow.document.write('<div id="popup-root"></div>')

      const styleElement = popupWindow.document.createElement('style')
      styleElement.appendChild(
        document.createTextNode(`
            header.popup { display: flex; padding: 20px; margin-left: 20px; margin-right: 20px; background: #ECEBFF; }
            header.popup dt {border-bottom:solid 1px #000;}
            header.popup dl + dl { margin-left: 30px; }
            header.popup dl dt { font-size: 14px; font-weight: 600; color: #999); }
            header.popup dl dd { margin-top: 8px; font-size: 18px; font-weight: 600; color: #502ACC; margin-inline-start: 0px; }
            main.popup { padding: 10px 20px 20px; }
            div.table-wrapper { border: 1px solid #E1E1E1; border-radius: 10px; overflow: hidden; }
            div.table-wrapper table { width: 100%; }
            div.table-wrapper table.f14 { font-size: 14px; }
            div.table-wrapper table thead th { padding: 10px 6px; background: #F5F5F5; border-bottom: 1px solid #E1E1E1; font-weight: 600; }
            div.table-wrapper table tbody tr td { padding: 10px 6px; transition: all 0.3s; }
            div.table-wrapper table tbody tr td.number { text-align: center; }
            div.table-wrapper table tbody tr td:hover { background: #F5F5F5; }
            div.table-wrapper table tbody tr:not(:last-child) td { border-bottom: 1px solid #E1E1E1; }
            div.table-wrapper table thead th + th,
            div.table-wrapper table tbody td + td { border-left: 1px solid #E1E1E1; }
            div.table-wrapper table a.link { color: #2380FF; text-decoration: underline; }
            div.table-wrapper table button.new-open { padding: 0; background: none; border: 0 none; font-weight: 400; color: #2380FF; }
            div.table-wrapper table button.new-open:hover { color: #502ACC; }
          `)
      )
      popupWindow.document.head.appendChild(styleElement)

      const root = CreateDOM.createRoot(popupWindow.document.getElementById('popup-root')!)

      root.render(
        <RelatedArticles
          keyword={keyword}
          categoryName={buildCategoryPathToShow(selectedCategoryPath)}
          frequencyId={frequencyId}
          publishYear={publishYear}
        />
      )
    },
    [selectedCategoryPath, buildCategoryPathToShow]
  )

  const selectCategory = (categoryId: number, paths: string[]) => {
    const selectedCategory = categoryMap.get(categoryId)
    if (selectedCategory?.parentId !== 1) {
      setSelectedCategoryId(categoryId)
      setSelectedCategoryPath(paths)
      window.scrollTo(0, 0)
    }
  }

  const getTrendKeywordSuccessHandler = useCallback(
    (currentPage: number) => (keywordPage: Page<TrendKeyword>) => {
      setKeywords(keywordPage.content)
      setupPage(keywordPage, currentPage)
    },
    // eslint-disable-next-line
        [selectedCategoryId]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      trendKeywordRepository
        .getTrendKeywords({
          page: currentPage,
          size: 20,
          categoryId: selectedCategoryId,
          language: selectedLanguage,
        })
        .then(getTrendKeywordSuccessHandler(currentPage))
    },
    [selectedCategoryId, selectedLanguage, getTrendKeywordSuccessHandler]
  )

  const buildCategoryMap = (root: Category, map: Map<number, Category>) => {
    map.set(root.id, root)
    if (root.children) {
      root.children.forEach((child) => {
        buildCategoryMap(child, map)
      })
    }
  }

  const isEmptyKeyword = (keyword: TrendKeyword) => {
    return (
      keyword.keyword2018Id === -1 &&
      isEmpty(keyword.keyword2018) &&
      keyword.keyword2019Id === -1 &&
      isEmpty(keyword.keyword2019) &&
      keyword.keyword2020Id === -1 &&
      isEmpty(keyword.keyword2020) &&
      keyword.keyword2021Id === -1 &&
      isEmpty(keyword.keyword2021) &&
      keyword.keyword2022Id === -1 &&
      isEmpty(keyword.keyword2022) &&
      keyword.keyword2023Id === -1 &&
      isEmpty(keyword.keyword2023)
    )
  }

  useEffect(() => {
    moveToPage(0)
  }, [moveToPage, selectedCategoryId, selectedLanguage])

  useEffect(() => {
    if (categoryTree && categoryTree.children) {
      const newCategoryMap = new Map()
      buildCategoryMap(categoryTree, newCategoryMap)
      setCategoryMap(newCategoryMap)
      setSelectedCategoryPath(newCategoryMap.get(selectedCategoryId).paths)
    }
    moveToPage(0)
    // eslint-disable-next-line
    }, [categoryTree]);

  return (
    <main>
      <div className="container">
        <Location paths={['트렌드이슈']} />
        <h1 className="head">트렌드이슈</h1>
        <div className="result-summary above">
          <p className="sum">학문 분야별 최근 트렌드 이슈를 분석합니다.</p>
        </div>
        <div className="content-wrap">
          <CategoryNavigator
            key={1}
            categoryTree={categoryTree?.children}
            selectCategory={selectCategory}
            showCount={false}
            markOnlyLeafNodeSelection={true}
          />
          <section className="content">
            <div className="result-summary">
              <div className="search-condition">
                {selectedCategoryPath.length > 0 && (
                  <dl>
                    <dt>선택 분야</dt>
                    <dd aria-label="selected-category-name">
                      {buildCategoryPathToShow(selectedCategoryPath)}
                    </dd>
                  </dl>
                )}
                <dl>
                  <dt>년도</dt>
                  <dd>2018년 ~ 2023년</dd>
                </dl>
              </div>
              <fieldset className="sort">
                <select
                  className="language-select"
                  onChange={(event) => setSelectedLanguage(event.target.value as LanguageType)}
                  defaultValue="ALL"
                >
                  <option value="ALL">전체</option>
                  <option value="KOREAN">한국어</option>
                  <option value="ENGLISH">영어</option>
                  <option value="CHINESE">한자</option>
                </select>
              </fieldset>
            </div>
            {keywords.find((keyword) => !isEmptyKeyword(keyword)) ? (
              <>
                <div className="table-wrapper above">
                  <table>
                    <thead>
                      <tr>
                        <th>Seq</th>
                        <th>2018</th>
                        <th>2019</th>
                        <th>2020</th>
                        <th>2021</th>
                        <th>2022</th>
                        <th>2023</th>
                      </tr>
                    </thead>
                    <tbody>
                      {keywords.map((keyword, index) => (
                        <tr key={`keyword_${index}`}>
                          <td className="number">{keyword.seq}</td>
                          <td>
                            <button
                              type="button"
                              className="new-open"
                              data-url="#"
                              onClick={() =>
                                openRelatedArticlesModal(
                                  keyword.keyword2018,
                                  keyword.frequency2018Id,
                                  '2018'
                                )
                              }
                            >
                              {keyword.keyword2018}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="new-open"
                              data-url="#"
                              onClick={() =>
                                openRelatedArticlesModal(
                                  keyword.keyword2019,
                                  keyword.frequency2019Id,
                                  '2019'
                                )
                              }
                            >
                              {keyword.keyword2019}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="new-open"
                              data-url="#"
                              onClick={() =>
                                openRelatedArticlesModal(
                                  keyword.keyword2020,
                                  keyword.frequency2020Id,
                                  '2020'
                                )
                              }
                            >
                              {keyword.keyword2020}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="new-open"
                              data-url="#"
                              onClick={() =>
                                openRelatedArticlesModal(
                                  keyword.keyword2021,
                                  keyword.frequency2021Id,
                                  '2021'
                                )
                              }
                            >
                              {keyword.keyword2021}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="new-open"
                              data-url="#"
                              onClick={() =>
                                openRelatedArticlesModal(
                                  keyword.keyword2022,
                                  keyword.frequency2022Id,
                                  '2022'
                                )
                              }
                            >
                              {keyword.keyword2022}
                            </button>
                          </td>
                          <td>
                            <button
                              type="button"
                              className="new-open"
                              data-url="#"
                              onClick={() =>
                                openRelatedArticlesModal(
                                  keyword.keyword2023,
                                  keyword.frequency2023Id,
                                  '2023'
                                )
                              }
                            >
                              {keyword.keyword2023}
                            </button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
              </>
            ) : (
              <div className="result-summary above">
                <p className="sum">해당 분야에 대한 정보가 없습니다.</p>
              </div>
            )}
          </section>
        </div>
      </div>
    </main>
  )
}

export default TrendIssue
