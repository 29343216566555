import React, { useCallback, useEffect, useState } from 'react'
import Button from '../../common/component/Button'
import AuthorArticles from '../domain/AuthorArticles'
import ArticleAuthor from '../domain/ArticleAuthor'
import { useNavigate } from 'react-router-dom'
import AuthorArticle from '../domain/AuthorArticle'

interface Props {
  authors: ArticleAuthor[]
  authorArticles: AuthorArticles[]
}

const OtherArticlesForAuthors = ({ authors, authorArticles }: Props) => {
  const navigate = useNavigate()
  const [expandMap, setExpandMap] = useState<Map<number, boolean>>(new Map())

  const moveToOtherArticle = useCallback(
    (id: number) => {
      navigate(`/articles/${id}`)
    },
    [navigate]
  )

  const authorNameComponent = useCallback(
    (authorId: number) => {
      const author = authors.find((author) => author.id === authorId)
      if (author) {
        return (
          <span className="author">
            <div>
              <p className="h1">{author?.name}</p>
              <p className="h2">{author?.subName}</p>
            </div>
            <Button
              className="strong"
              onClick={() => {
                const newMap = new Map(expandMap)
                newMap.set(authorId, !expandMap.get(authorId))
                setExpandMap(newMap)
              }}
              ariaLabel={`btn-open-other-articles-for-${authorId}`}
            >
              {expandMap.get(authorId) ? '접기' : '열기'}
            </Button>
          </span>
        )
      }
      return <span></span>
    },
    [authors, expandMap]
  )

  const articleListComponent = useCallback(
    (articles: AuthorArticle[]) => {
      return (
        <ul>
          {articles.map((article) => (
            <li key={`other-article-${article.id}`} onClick={() => moveToOtherArticle(article.id)}>
              <span className="other-article">
                <p className="title">
                  <span className="h1">{article.title}</span>
                  <span className="h2">{article.subTitle}</span>
                </p>
                <p className="publish">
                  <span className="journal">{article.journal}</span>
                  <span className="year">{` ${article.publishYear}년`}</span>
                </p>
              </span>
            </li>
          ))}
        </ul>
      )
    },
    [moveToOtherArticle]
  )

  useEffect(() => {
    setExpandMap(new Map(authorArticles.map((otherArticle) => [otherArticle.authorId, false])))
  }, [authorArticles])

  return (
    <aside className="aside">
      {authorArticles.length > 0 && (
        <div className="others">
          <h1>이 저자(들)의 다른 논문</h1>
          <ul className="list">
            {authorArticles.map((authorArticle) => (
              <li key={`author-${authorArticle.authorId}`}>
                {authorNameComponent(authorArticle.authorId)}
                {expandMap.has(authorArticle.authorId) &&
                  expandMap.get(authorArticle.authorId) &&
                  articleListComponent(authorArticle.articles)}
              </li>
            ))}
          </ul>
        </div>
      )}
    </aside>
  )
}

export default OtherArticlesForAuthors
