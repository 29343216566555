type Props = { openModal: () => void }

const WorkspaceTopSection = ({ openModal }: Props) => {
  return (
    <div className="top-section">
      <div className="search">
        <input type="text" placeholder="검색어를 입력해주세요" />
        <button aria-label="search" />
      </div>
      <button className="project-creation" onClick={() => openModal()}>
        새 프로젝트
      </button>
    </div>
  )
}

export default WorkspaceTopSection
