import React, { Fragment, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import Location from '../../common/component/Location'

import InformationMenu from '../component/InformationMenu'
import ServiceGuide from '../component/ServiceGuide'
import SearchGuide from '../component/SearchGuide'
import MyPageGuide from '../component/MyPageGuide'

const ServiceInformation = () => {
  const { type } = useParams()

  const component = useMemo(() => {
    switch (type) {
      case 'service':
        return <ServiceGuide />
      case 'search':
        return <SearchGuide />
      case 'my-page':
        return <MyPageGuide />
      default:
        return <Fragment />
    }
  }, [type])

  const menuName = useMemo(() => {
    switch (type) {
      case 'service':
        return '서비스 이용방법'
      case 'search':
        return '자료검색'
      case 'my-page':
        return '마이 페이지'
      default:
        return '서비스 이용방법'
    }
  }, [type])

  return (
    <main>
      <div className="container">
        <Location paths={['이용안내', menuName]} />
        <div className="content-wrap">
          <InformationMenu />
          <section className="content">{component}</section>
        </div>
      </div>
    </main>
  )
}

export default ServiceInformation
