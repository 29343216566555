import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Location from '../../common/component/Location'
import NiceSelect from '../../common/component/NiceSelect'
import Button from '../../common/component/Button'
import Pagination from '../../common/component/Pagination'
import { BbsButton, BbsReportButton } from '../component/BbsProtectedButton'

import Page from '../../common/domain/Page'
import { usePageable } from '../../common/hooks/usePageable'

import InformationMenu from '../component/InformationMenu'
import BbsItem from '../domain/BbsItem'
import { bbsRepository } from '../repository/BbsRepository'
import BbsTableReports from '../component/BbsTableReports'
import BbsTable from '../component/BbsTable'

type ParamType = {
  size: string
  sort: string
  searchType: string
  key: string
  value: string
}

const DEFAULT_PARAMS: ParamType = {
  size: '10',
  sort: 'desc',
  searchType: '',
  key: 'title',
  value: '',
}

const BbsBoard = () => {
  const { type } = useParams()
  const navigate = useNavigate()

  const { pageInfo, setupPage } = usePageable()
  const [bbsItems, setBbsItems] = useState<BbsItem[]>([])

  const [size, setSize] = useState<string>(DEFAULT_PARAMS.size)
  const [sort, setSort] = useState<string>(DEFAULT_PARAMS.sort)
  const [searchType, setSearchType] = useState<string>(DEFAULT_PARAMS.searchType)
  const [key, setKey] = useState<string>(DEFAULT_PARAMS.key)
  const [value, setValue] = useState<string>(DEFAULT_PARAMS.value)

  const getBbsItemsSuccessHandler = useCallback(
    (currentPage: number) => (bbsItems: Page<BbsItem>) => {
      setBbsItems(bbsItems.content)
      setupPage(bbsItems, currentPage)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number, params?: ParamType) => {
      if (type) {
        bbsRepository
          .getBulletinBoardItems(type, {
            page: currentPage,
            size: params ? +params.size : +size,
            sort: `createdAt,${params ? params.sort : sort}`,
            searchType: params ? params.searchType : searchType,
            key: params ? params.key : key,
            value: params ? params.value : value,
          })
          .then(getBbsItemsSuccessHandler(currentPage))
      }
    },
    [type, size, sort, searchType, key, value, getBbsItemsSuccessHandler]
  )

  const moveToDetail = (id: number) => {
    navigate(`/bbs/${type}/${id}`)
  }

  const moveToEditor = useCallback(() => {
    navigate(`/bbs/edit/${type}`)
  }, [type, navigate])

  useEffect(() => {
    setSize(DEFAULT_PARAMS.size)
    setSort(DEFAULT_PARAMS.sort)
    setSearchType(DEFAULT_PARAMS.searchType)
    setKey(DEFAULT_PARAMS.key)
    setValue(DEFAULT_PARAMS.value)
    moveToPage(0, DEFAULT_PARAMS)
    // eslint-disable-next-line
    }, [type])

  const location = useMemo(() => {
    switch (type) {
      case 'notices':
        return '공지사항'
      case 'faqs':
        return 'FAQ'
      case 'newses':
        return '뉴스'
      case 'reports':
        return '오류신고/개선의견/문의'
      default:
        return '공지사항'
    }
  }, [type])

  const searchOptions = useMemo(() => {
    switch (type) {
      case 'faqs':
        return ['분류', '검색', '사이트이용', '회원가입/로그인']
      case 'newses':
        return ['분류', '뉴스', '소개']
      case 'reports':
        return ['분류', '오류신고', '개선의견', '문의']
      default:
        return []
    }
  }, [type])

  const searchValues = useMemo(() => {
    switch (type) {
      case 'faqs':
        return ['', 'search', 'usage', 'join/login']
      case 'newses':
        return ['', 'news', 'intro']
      case 'reports':
        return ['', 'error', 'improvement', 'question']
      default:
        return []
    }
  }, [type])

  const selectOptions = useMemo(() => {
    const optionKeys = searchValues
    const optionValues = searchOptions
    const optionsForSelect = new Map(
      optionKeys.map((optionKey, index) => [optionKey, optionValues[index]])
    )
    return optionsForSelect
  }, [searchOptions, searchValues])

  const search = () => {
    moveToPage(0)
  }

  const SubmitButton = useMemo(
    () =>
      type === 'reports' ? (
        <BbsReportButton onClick={moveToEditor}>작성</BbsReportButton>
      ) : (
        <BbsButton onClick={moveToEditor}>작성</BbsButton>
      ),
    [type, moveToEditor]
  )

  return (
    <main>
      <div className="container">
        <Location paths={[location]} />
        <div className="content-wrap">
          <InformationMenu />
          <section className="content">
            <h1 className="normal-head">{location}</h1>
            <div className="result-summary above">
              {selectOptions.size > 0 ? (
                <NiceSelect
                  ariaLabel="type-box"
                  options={selectOptions}
                  onSelectCallback={(v) => setSearchType(v)}
                />
              ) : (
                <div></div>
              )}
              <fieldset className="sort">
                <NiceSelect
                  ariaLabel="search-box"
                  options={
                    new Map([
                      ['title', '제목'],
                      ['content', '내용'],
                    ])
                  }
                  onSelectCallback={(v) => setKey(v)}
                />
                <input
                  type="text"
                  aria-label="search-input"
                  placeholder="검색어를 입력해주세요"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                />
                {type !== 'notices' && (
                  <NiceSelect
                    ariaLabel="sort-box"
                    options={
                      new Map([
                        ['desc', '내림차순'],
                        ['asc', '오름차순'],
                      ])
                    }
                    onSelectCallback={(v) => setSort(v)}
                  />
                )}
                <NiceSelect
                  ariaLabel="page-size-box"
                  options={
                    new Map([
                      ['10', '10개'],
                      ['20', '20개'],
                      ['50', '50개'],
                      ['100', '100개'],
                    ])
                  }
                  onSelectCallback={(v) => setSize(v)}
                />
                <Button ariaLabel="search-button" onClick={search}>
                  조회
                </Button>
              </fieldset>
            </div>
            {type === 'reports' ? (
              <BbsTableReports items={bbsItems} moveToDetail={moveToDetail} />
            ) : (
              <BbsTable
                items={bbsItems}
                moveToDetail={moveToDetail}
                isWideCategory={type === 'faqs'}
              />
            )}
            <div className="button-wrap destro">{SubmitButton}</div>
            <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
          </section>
        </div>
      </div>
    </main>
  )
}
export default BbsBoard
