import AxiosMainRepository from './AxiosMainRepository'

import { ResourceTotalCount } from '../domain/ResourceTotalCount'
import { HomeSlideTab } from '../domain/HomeSlideTab'

export interface MainRepository {
  getResourceTotalCount(): Promise<ResourceTotalCount>

  getHomeSlideImages(): Promise<HomeSlideTab[]>
}

export const mainRepository = new AxiosMainRepository()
