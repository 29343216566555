import Article, { getPublishingInfo } from '../domain/Article'
import AbstractsComponent from './AbstractsComponent'
import TitleComponent from './TitleComponent'
import { removeDuplicatesFromArrays } from '../../common/utils/DuplicationRemover'
import { useCallback, useContext } from 'react'
import { Context as SearchConditionContext } from '../provider/SearchConditionProvider'
import { useNavigate } from 'react-router-dom'
import { isNotEmpty } from '../../common/utils/TextUtils'

interface ArticleProp {
  article: Article
}

function ArticleComponent({ article }: ArticleProp) {
  const searchCondition = useContext(SearchConditionContext)!
  const navigator = useNavigate()

  const hasAbstractsContent = useCallback(() => {
    const hasAbstracts =
      article.abstractsContent !== null &&
      article.abstractsContent !== undefined &&
      article.abstractsContent.trim() !== ''
    const hasSubAbstracts =
      article.subAbstractsContent !== null &&
      article.subAbstractsContent !== undefined &&
      article.subAbstractsContent.trim() !== ''
    return hasAbstracts || hasSubAbstracts
  }, [article])

  return (
    <li>
      <a href={`/articles/${article.id}`} target="_blank" rel="noreferrer">
        <TitleComponent title={article.title} subTitle={article.subTitle} />
      </a>
      <div className="info box">
        {article.articleSource.contentId.toLowerCase() === 'koreapatent' ? (
          <dl>
            <dt>출처</dt>
            <dd>
              <p>
                특허
                {isNotEmpty(article.articleSource.volumeIssue) && (
                  <span className="sub">{` ${article.articleSource.volumeIssue}`}</span>
                )}
              </p>
            </dd>
          </dl>
        ) : (
          <>
            <dl>
              <dt>출처</dt>
              <dd>
                <p>
                  <a
                    aria-label="journal-link"
                    href={`/journals/${article.articleSource.journalId}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {article.articleSource.contentId} {'《'}
                    {article.articleSource.journal}
                    {'》 '}
                    {article.articleSource.publisher}{' '}
                    <span className="sub">{getPublishingInfo(article)}</span>
                  </a>
                </p>
              </dd>
            </dl>
            {isNotEmpty(article.doi) && (
              <dl>
                <dt>DOI</dt>
                <dd>
                  <p>
                    <a href={article.doi} target="_blank" rel="noreferrer">
                      {article.doi}
                    </a>
                  </p>
                </dd>
              </dl>
            )}
          </>
        )}

        {article.authors && article.authors.length > 0 && (
          <dl>
            <dt>저자</dt>
            <dd>
              <ul className="author">
                {article.authors.map((author, index) => (
                  <li key={`author-${author.id}-${index}`}>
                    <span className="main">{author.getAuthorName()}</span>
                  </li>
                ))}
              </ul>
            </dd>
          </dl>
        )}
        {hasAbstractsContent() && (
          <dl>
            <dt>초록</dt>
            <dd>
              <AbstractsComponent
                abstracts={article.abstractsContent}
                subAbstracts={article.subAbstractsContent}
              />
            </dd>
          </dl>
        )}
        {article.categoryNames && article.categoryNames.length > 0 && (
          <dl>
            <dt>분야</dt>
            <dd>
              <ul className="keyword">
                {article.categoryNames.map((categoryName, index) => (
                  <li
                    key={`${categoryName}-${index}`}
                    dangerouslySetInnerHTML={{ __html: categoryName }}
                  />
                ))}
              </ul>
            </dd>
          </dl>
        )}
        {((article.keywords && article.keywords.filter((k) => k.trim() !== '').length > 0) ||
          (article.subKeywords &&
            article.subKeywords.filter((k) => k.trim() !== '').length > 0)) && (
          <dl>
            <dt>키워드</dt>
            <dd>
              <ul className="keyword">
                {Array.from(removeDuplicatesFromArrays(article.keywords, article.subKeywords)).map(
                  (keyword, index) => (
                    <li
                      key={`${keyword}-${index}`}
                      dangerouslySetInnerHTML={{ __html: keyword }}
                      onClick={() => {
                        searchCondition.clearConditions()
                        searchCondition.setSearchText(keyword)
                        searchCondition.setManualSearchTrigger(!searchCondition.manualSearchTrigger)
                        navigator('/article-search')
                      }}
                    />
                  )
                )}
              </ul>
            </dd>
          </dl>
        )}
      </div>
      <div className="func box">
        <a href={article.articleUrl} target="_brank" className="website">
          사이트로 이동
        </a>
        <a href={`/articles/${article.id}`} target="_blank" rel="noreferrer" className="detail">
          상세 보기
        </a>
      </div>
    </li>
  )
}

export default ArticleComponent
