type Props = {
  isLogin?: boolean
}

const SnsLogin = ({ isLogin = false }: Props) => {
  return (
    <ul className="sns">
      <li>
        <button
          type="button"
          className="naver"
          aria-label="naver-button"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_SERVER_URL}/oauth2/authorization/naver`
          }}
        >
          네이버 아이디로 {isLogin ? '로그인' : '회원가입'}
        </button>
      </li>
      <li>
        <button
          type="button"
          className="kakao"
          aria-label="kakao-button"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_SERVER_URL}/oauth2/authorization/kakao`
          }}
        >
          카카오 아이디로 {isLogin ? '로그인' : '회원가입'}
        </button>
      </li>
      <li>
        <button
          type="button"
          className="google"
          aria-label="google-button"
          onClick={() => {
            window.location.href = `${process.env.REACT_APP_SERVER_URL}/oauth2/authorization/google`
          }}
        >
          구글 아이디로 {isLogin ? '로그인' : '회원가입'}
        </button>
      </li>
    </ul>
  )
}

export default SnsLogin
