import React, { ChangeEvent, Fragment, MouseEvent, useCallback, useEffect, useState } from 'react'
import Category from '../domain/Category'
import { formatNumber } from '../../common/utils/NumberFormatter'

type CategoryTreeType = Map<number, Category> | null | undefined

interface Props {
  topLevelCategoryMap: Map<number, Category>
  setSelectedCategories: (selectedCategoryIds: number[]) => void
  selectedIds: number[]
}

const CategorySelectorCheckbox = ({
  topLevelCategoryMap,
  setSelectedCategories,
  selectedIds,
}: Props) => {
  const [parentCategoryCheckedMap, setParentCategoryCheckedMap] = useState<Map<number, boolean>>(
    new Map()
  )
  const [childCategoryCheckedMap, setChildCategoryCheckedMap] = useState<Map<number, boolean>>(
    new Map()
  )
  const [categoryArray, setCategoryArray] = useState<Category[]>([])
  const [treeOpenArray, setTreeOpenArray] = useState<boolean[]>([])
  const [selectedCategoryIds, setSelectedCategoryIds] = useState<Set<number>>(new Set(selectedIds))

  const initialize = useCallback(() => {
    if (topLevelCategoryMap) {
      const selectedIdSet = new Set(selectedIds)
      const parentArray: Category[] = Array.from(topLevelCategoryMap.values())
      const childrenArray: Category[] = []
      parentArray.forEach((parent) => {
        const values = parent.children?.values()
        if (!values) {
          return
        }
        Array.from(values).forEach((child) => childrenArray.push(child))
      })

      const falseArray = new Array(parentArray.length).fill(false)
      setCategoryArray(parentArray)
      setTreeOpenArray(falseArray)

      const falseMapForParentCategories = new Map(
        parentArray.map((category) =>
          selectedIdSet.has(category.id) ? [category.id, true] : [category.id, false]
        )
      )
      setParentCategoryCheckedMap(falseMapForParentCategories)

      const falseMapForChildrenCategories = new Map(
        childrenArray.map((category) =>
          selectedIdSet.has(category.id) ? [category.id, true] : [category.id, false]
        )
      )
      setChildCategoryCheckedMap(falseMapForChildrenCategories)

      setSelectedCategoryIds(selectedIdSet)
    }
  }, [topLevelCategoryMap, selectedIds])

  useEffect(() => {
    initialize()
  }, []) // eslint-disable-line

  const treeOpenHandler = (index: number) => (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    const tempTreeOpenArray = treeOpenArray.slice()
    tempTreeOpenArray[index] = !tempTreeOpenArray[index]
    setTreeOpenArray(tempTreeOpenArray)
  }

  const parentCategoryCheckHandler = (id: number) => (event: ChangeEvent<HTMLInputElement>) => {
    const newSelectedCategoryIds = new Set(selectedCategoryIds)
    if (event.target.checked) {
      for (const childId of Array.from(topLevelCategoryMap.get(id)!.children!.keys())) {
        if (childCategoryCheckedMap.has(childId)) {
          newSelectedCategoryIds.delete(childId)
        }
      }
      newSelectedCategoryIds.add(id)
    } else {
      newSelectedCategoryIds.delete(id)
    }
    setSelectedCategoryIds(newSelectedCategoryIds)
    setSelectedCategories(Array.from(newSelectedCategoryIds.values()))

    setParentCategoryCheckedMap((prev) => {
      const newMap = new Map(prev)
      newMap.set(id, event.target.checked)
      return newMap
    })

    const parent = topLevelCategoryMap.get(id)
    const children = parent?.children
    if (children) {
      children.forEach((child) => {
        childCategoryCheckedMap.set(child.id, event.target.checked)
      })
    }
    setChildCategoryCheckedMap((prev) => new Map(prev))
  }

  const childCategoryCheckHandler =
    (id: number, parentId: number) => (event: ChangeEvent<HTMLInputElement>) => {
      setChildCategoryCheckedMap((prev) => {
        const newMap = new Map(prev)
        newMap.set(id, event.target.checked)
        return newMap
      })

      const newSelectedCategoryIds = new Set(selectedCategoryIds)
      if (event.target.checked) {
        const siblings = topLevelCategoryMap.get(parentId)?.children
        if (siblings) {
          const uncheckedSiblings = Array.from(siblings.keys()).filter(
            (childId) => childId !== id && !childCategoryCheckedMap.get(childId)
          )
          if (uncheckedSiblings.length === 0) {
            setParentCategoryCheckedMap((prev) => {
              const newMap = new Map(prev)
              newMap.set(parentId, event.target.checked)
              return newMap
            })
            for (const childId of Array.from(siblings.keys())) {
              if (newSelectedCategoryIds.has(childId)) {
                newSelectedCategoryIds.delete(childId)
              }
            }
            newSelectedCategoryIds.add(parentId)
          } else {
            newSelectedCategoryIds.add(id)
          }
        }
      } else {
        setParentCategoryCheckedMap((prev) => {
          const newMap = new Map(prev)
          newMap.set(parentId, event.target.checked)
          return newMap
        })

        if (newSelectedCategoryIds.has(parentId)) {
          newSelectedCategoryIds.delete(parentId)
        }
        if (newSelectedCategoryIds.has(id)) {
          newSelectedCategoryIds.delete(id)
        }
        for (const childId of Array.from(topLevelCategoryMap.get(parentId)!.children!.keys())) {
          if (childId !== id && childCategoryCheckedMap.get(childId)) {
            newSelectedCategoryIds.add(childId)
          }
        }
      }
      setSelectedCategoryIds(newSelectedCategoryIds)
      setSelectedCategories(Array.from(newSelectedCategoryIds.values()))
    }

  const hasChildren = (categoryTree: CategoryTreeType) => {
    if (!categoryTree) {
      return false
    }
    return categoryTree.size > 0
  }

  if (!topLevelCategoryMap) {
    return <Fragment />
  }

  return (
    <Fragment>
      {categoryArray
        .filter((category) => category.articleCount > 0)
        .map((category: Category, index) => (
          <li
            className={`${treeOpenArray[index] ? 'on' : ''}`}
            key={`category-parent-${category.id}`}
          >
            <p>
              {hasChildren(category.children) && (
                <i onClick={treeOpenHandler(index)} className="toggle" />
              )}
              <label className="checkbox">
                <input
                  aria-label={`category-checkbox-${category.id}`}
                  type="checkbox"
                  checked={parentCategoryCheckedMap.get(category.id)}
                  onChange={parentCategoryCheckHandler(category.id)}
                />
                <span className="label">
                  <i>
                    {category.name} ({formatNumber(category.articleCount)})
                  </i>
                </span>
              </label>
            </p>
            {hasChildren(category.children) && (
              <ul className={`${treeOpenArray[index] ? '' : 'close'}`}>
                {Array.from(category.children!.values())
                  .filter((child) => child.articleCount > 0)
                  .map((childCategory) => (
                    <li key={`category-child-${childCategory.id}`}>
                      <p>
                        <label className="checkbox">
                          <input
                            aria-label={`category-checkbox-${childCategory.id}`}
                            type="checkbox"
                            checked={childCategoryCheckedMap.get(childCategory.id)}
                            onChange={childCategoryCheckHandler(childCategory.id, category.id)}
                          />
                          <span className="label">
                            <i>
                              {childCategory.name} ({formatNumber(childCategory.articleCount)})
                            </i>
                          </span>
                        </label>
                      </p>
                    </li>
                  ))}
              </ul>
            )}
          </li>
        ))}
    </Fragment>
  )
}

export default CategorySelectorCheckbox
