import { ReactNode } from 'react'

const LinkButton = (props: { path?: string; onClick?: () => void; children: ReactNode }) => {
  return (
    <>
      {props.path ? (
        <button className="link-button" onClick={props.onClick}>
          <a href={props.path}>{props.children}</a>
        </button>
      ) : (
        <button className="link-button" onClick={props.onClick}>
          {props.children}
        </button>
      )}
    </>
  )
}

export default LinkButton
