import { ChangeEvent, Fragment, useEffect, useState } from 'react'

const CheckBox = ({
  onChange,
  ariaLabel,
  className,
  isAllChecked = false,
  checked = false,
}: {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
  ariaLabel?: string
  className?: string
  isAllChecked?: boolean
  checked?: boolean
}) => {
  const [checkedState, setCheckedState] = useState(checked)

  useEffect(() => {
    setCheckedState(isAllChecked)
  }, [isAllChecked])

  useEffect(() => {
    setCheckedState(checked)
  }, [checked])

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setCheckedState(event.target.checked)
    onChange(event)
  }

  return (
    <Fragment>
      <input
        aria-label={ariaLabel}
        type="checkbox"
        className={`${className}`}
        checked={checkedState}
        onChange={onChangeHandler}
      ></input>
    </Fragment>
  )
}

export default CheckBox
