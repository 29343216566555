import React from 'react'

const InitialWorkspace = ({ openModal }: { openModal: () => void }) => {
  return (
    <div className="initial-workspace">
      <div className="info">생성된 프로젝트가 없습니다.</div>
      <button className="project-creation" onClick={openModal}>
        새 프로젝트 만들기
      </button>
    </div>
  )
}

export default InitialWorkspace
