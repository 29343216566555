import TopicTreeNode from '../domain/TopicTreeNode'
import { Fragment, ReactNode } from 'react'
import { RightSection } from '../component/TopicTreeManagement'

export function searchSelectedTopic(
  selectedId: string,
  current: TopicTreeNode
): TopicTreeNode | null {
  if (current.id === selectedId) {
    return current
  }
  if (!current.children) {
    return null
  }
  const currentChild = current.children[selectedId]
  if (currentChild) {
    return currentChild
  }
  for (const value of Object.values(current.children)) {
    const result = searchSelectedTopic(selectedId, value)
    if (result) {
      return result
    }
  }
  return null
}

export function drawTreeAside(
  treeNodeMap: { [key in string]: TopicTreeNode },
  selectedId: string,
  onSelectTopic: (id: string) => void,
  onSetCurrentRightSection: (rightSection: RightSection) => void
): ReactNode {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => onSelectTopic(value.id)}
        >
          <li>{value.name}</li>
          {selectedId === value.id && (
            <div className="tree-item-buttons">
              <button
                className="icon edit"
                aria-label="edit-button"
                onClick={(event) => {
                  event.stopPropagation()
                  onSetCurrentRightSection('modify')
                }}
              />
              <button
                className="icon delete"
                aria-label="delete-button"
                onClick={(event) => {
                  event.stopPropagation()
                  onSetCurrentRightSection('delete')
                }}
              />
              <button
                className="icon add"
                aria-label="add-button"
                onClick={(event) => {
                  event.stopPropagation()
                  onSetCurrentRightSection('create')
                }}
              />
            </div>
          )}
        </div>
        {value.children && (
          <ul className="children">
            {drawTreeAside(value.children, selectedId, onSelectTopic, onSetCurrentRightSection)}
          </ul>
        )}
      </Fragment>
    )
  })
}

export function drawTreeInMoveModal(
  treeNodeMap: { [key in string]: TopicTreeNode },
  selectedId: string,
  onSelectTopic: (id: string) => void
) {
  return Object.values(treeNodeMap).map((value) => {
    return (
      <Fragment key={value.id}>
        <div
          className={`tree-node-item children ${selectedId === value.id && 'on'}`}
          onClick={() => onSelectTopic(value.id)}
        >
          <li>{value.name}</li>
        </div>
        {value.children && (
          <ul className="children">
            {drawTreeInMoveModal(value.children, selectedId, onSelectTopic)}
          </ul>
        )}
      </Fragment>
    )
  })
}
