import Page from '../../common/domain/Page'
import MyArticle from '../../tacto/domain/MyArticle'

export const stubMyArticle: MyArticle = {
  id: 1,
  title: '생명공학과 유전자 조작',
  content:
    '21세기는 생명공학 시대이다. 생명공학은 급속도로 발전하고 있으며 지금 이 순간에도 세계 각지에서 많은 연구와 그 결과물이 나오고 있다.',
  contentSize: 376,
  isCompleted: false,
  updatedAt: 1728796375255,
}

export const stubMyArticlesPage = (stub: MyArticle[]): Page<MyArticle> => {
  return {
    content: stub,
    pageable: { pageNumber: 0 },
    size: 1,
    totalElements: 1,
    totalPages: 1,
  }
}
