import React, { ChangeEvent } from 'react'

type Props = {
  isAllChecked: boolean
  onAllCheckHandler: (event: ChangeEvent<HTMLInputElement>) => void
}

const AllCheckBox = ({ isAllChecked, onAllCheckHandler }: Props) => {
  return (
    <label className="checkbox">
      <input
        id="all-check"
        type="checkbox"
        aria-label="checkbox-all"
        checked={isAllChecked}
        onChange={onAllCheckHandler}
      />
      <span>전체선택</span>
    </label>
  )
}

export default AllCheckBox
