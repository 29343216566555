export default class ArticleAuthor {
  id: number
  name?: string | null
  subName?: string | null
  authorSequence: number

  constructor(
    id: number,
    name: string | null | undefined,
    subName: string | null | undefined,
    authorSequence: number
  ) {
    this.id = id
    this.name = name
    this.subName = subName
    this.authorSequence = authorSequence
  }

  getAuthorName(): string {
    return `${this.name}${this.getSubName()}`
  }

  getSubName(): string {
    return this.subName ? ` ${this.subName}` : ''
  }
}
