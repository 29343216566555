import React, { useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { usePageable } from '../../common/hooks/usePageable'
import Pagination from '../../common/component/Pagination'
import ModalWindow from '../../common/component/ModalWindow'
import WorkspaceTopSection from '../../common/component/WorkspaceTopSection'

import { getProjects } from '../repository/TactoProjectRepository'
import TactoProjectItem from '../domain/TactoProjectItem'
import CreateProject from './CreateProject'

const TactoProjectList = () => {
  const { pageInfo, setupPage } = usePageable()
  const navigate = useNavigate()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [projectItems, setProjectItems] = useState<TactoProjectItem[]>([])

  const moveToPage = useCallback(
    (currentPage: number) => {
      getProjects({ size: 10, page: currentPage }).then((itemsPage) => {
        setProjectItems(itemsPage.content)
        setupPage(itemsPage, currentPage)
      })
    },
    [setupPage]
  )

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageInfo])

  useEffect(() => {
    moveToPage(0)
    // eslint-disable-next-line
  }, [])

  const moveToProjectPage = useCallback(
    (id: number) => {
      navigate(`/tacto/projects/${id}`)
    },
    [navigate]
  )

  return (
    <div>
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <CreateProject
          fetchProjects={() => moveToPage(pageInfo.currentPage)}
          closeModal={() => setModalOpen(false)}
        />
      </ModalWindow>
      <div className="project-list">
        <WorkspaceTopSection openModal={() => setModalOpen(true)} />
        <div className="project-items">
          {projectItems.map((item) => (
            <div className="project-item" key={item.id} onClick={() => moveToProjectPage(item.id)}>
              <div className="name">{item.name}</div>
              <div className="description">{item.description}</div>
              <div className="info-wrap">
                <div className="etc-info">
                  <div className="topic-info">
                    <div>{item.topicSummary.mainTopic}</div>
                    <div>{item.topicSummary.topicCount}개의 토픽</div>
                  </div>
                  <div className="count-info">
                    <div>
                      메모 <span>{item.memoCount}</span>
                    </div>
                    <div className="divider" />
                    <div>
                      아티클 <span>{item.articleCount}</span>
                    </div>
                    <div className="divider" />
                    <div>
                      자료 <span>{item.materialCount}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
    </div>
  )
}

export default TactoProjectList
