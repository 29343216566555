import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react'

import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

import Attachment from '../domain/Attachment'
import { deleteAttachment, getAttachment, saveAttachment } from '../repository/MaterialsRepository'
import { Context } from '../provider/TactoProjectProvider'

type Props = {
  materialId: number
  materialTitle: string
  closeModal: () => void
}

const MaterialAttachment = ({ materialId, materialTitle, closeModal }: Props) => {
  const { refreshItems } = useContext(Context)!
  const [attachment, setAttachment] = useState<Attachment>({ id: 0, url: '' })
  const [file, setFile] = useState<File>()

  const fetchAttachment = useCallback(() => {
    getAttachment(materialId).then((response) => setAttachment(response))
  }, [materialId])

  useEffect(() => {
    fetchAttachment()
  }, [fetchAttachment])

  const onSave = useCallback(() => {
    if (!file) {
      alert('선택한 파일이 없습니다.')
      return
    }
    saveAttachment(materialId, file).then(refreshItems).finally(fetchAttachment)
  }, [file, materialId, refreshItems, fetchAttachment])

  const onFileSelector = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files
    if (files && files.length > 0) {
      setFile(files[0])
    }
  }, [])

  const onDelete = useCallback(() => {
    deleteAttachment(attachment.id).then(refreshItems).finally(fetchAttachment)
  }, [attachment.id, fetchAttachment, refreshItems])

  return (
    <div className="material-attachment-modal" aria-label="attachment-modal">
      <div className="title">PDF 첨부</div>
      <div className="material-title">{materialTitle}</div>
      {attachment.url ? (
        <div className="pdf-viewer">
          <button className="delete-button" onClick={onDelete}>
            <div className="delete-icon" />
            삭제
          </button>
          <embed src={attachment.url} aria-label="pdf-viewer" type="application/pdf" />
        </div>
      ) : (
        <>
          <div className="item">
            <div className="label">파일 선택</div>
            <label htmlFor="attachment-file" className="attachment-file-selector">
              <input
                id="attachment-file"
                type="file"
                aria-label="file-selector"
                accept="application/pdf"
                onChange={onFileSelector}
              />
              <div className="add-button" />
            </label>
          </div>
          <div className="buttons">
            <CancelButton
              name="취소"
              onClick={() => {
                closeModal()
                refreshItems()
              }}
            />
            <ConfirmButton name="저장" onClick={onSave} />
          </div>
        </>
      )}
    </div>
  )
}

export default MaterialAttachment
