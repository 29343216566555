import React from 'react'

type Prop = {
  writerName: string
  content: string
  createdAt: string
}
const BbsItemComment = ({ writerName, content, createdAt }: Prop) => {
  return (
    <div className="bbs-comment-box">
      <ul className="bbs-info">
        <li key="writer">{writerName}</li>
        <li key="createdAt">{createdAt}</li>
      </ul>
      <p className="bbs-comment">{content}</p>
    </div>
  )
}

export default BbsItemComment
