import React, { useMemo, useState } from 'react'
import Location from '../../common/component/Location'
import DefaultWorkspace from '../component/DefaultWorkspace'
import TactoProjectList from '../component/TactoProjectList'

type Memu =
  | 'DefaultWorkspace'
  | 'TactoProjectList'
  | 'TopicTree'
  | 'Memo'
  | 'Article'
  | 'Material'
  | 'Trash'

const TactoWorkspace = () => {
  const [selectedMenu, setSelectedMenu] = useState<Memu>('DefaultWorkspace')
  const RenderedContent = useMemo(() => {
    switch (selectedMenu) {
      case 'TactoProjectList':
        return <TactoProjectList />
      default:
        return <DefaultWorkspace />
    }
  }, [selectedMenu])

  return (
    <main>
      <div className="container">
        <Location paths={['마이페이지', '워크스페이스']} />
        <h1 className="head">워크스페이스</h1>
        <div className="content-wrap">
          <div className="aside">
            <div className="tacto-menus">
              <div
                className={`tacto-menu ${selectedMenu === 'TactoProjectList' && 'on'}`}
                onClick={() => setSelectedMenu('TactoProjectList')}
              >
                <div className={`icon project ${selectedMenu === 'TactoProjectList' && 'on'}`} />
                <div>프로젝트</div>
              </div>
              <div
                className={`tacto-menu ${selectedMenu === 'TopicTree' && 'on'}`}
                onClick={() => setSelectedMenu('TopicTree')}
              >
                <div className={`icon tree ${selectedMenu === 'TopicTree' && 'on'}`} />
                <div>토픽트리</div>
              </div>
              <div
                className={`tacto-menu ${selectedMenu === 'Memo' && 'on'}`}
                onClick={() => setSelectedMenu('Memo')}
              >
                <div className={`icon memo ${selectedMenu === 'Memo' && 'on'}`} />
                <div>메모</div>
              </div>
              <div
                className={`tacto-menu ${selectedMenu === 'Article' && 'on'}`}
                onClick={() => setSelectedMenu('Article')}
              >
                <div className={`icon article ${selectedMenu === 'Article' && 'on'}`} />
                <div>아티클</div>
              </div>
              <div
                className={`tacto-menu ${selectedMenu === 'Material' && 'on'}`}
                onClick={() => setSelectedMenu('Material')}
              >
                <div className={`icon material ${selectedMenu === 'Material' && 'on'}`} />
                <div>자료</div>
              </div>
              <div
                className={`tacto-menu ${selectedMenu === 'Trash' && 'on'}`}
                onClick={() => setSelectedMenu('Trash')}
              >
                <div className={`icon trash ${selectedMenu === 'Trash' && 'on'}`} />
                <div>휴지통</div>
              </div>
            </div>
          </div>
          <section className="content tacto-workspace">{RenderedContent}</section>
        </div>
      </div>
    </main>
  )
}

export default TactoWorkspace
