import RecentTactoProject from '../domain/RecentTactoProject'
import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import WorkspaceTopSection from '../../common/component/WorkspaceTopSection'

type Props = {
  recentProjects: RecentTactoProject[]
  openModal: () => void
}

const RecentWorkspace = ({ recentProjects, openModal }: Props) => {
  const navigate = useNavigate()

  const moveToProjectDetail = useCallback(
    (id: number) => {
      navigate(`/tacto/projects/${id}`)
    },
    [navigate]
  )

  return (
    <div className="recent-workspace">
      <WorkspaceTopSection openModal={openModal} />
      <div aria-label="recent-projects" className="recent-items-wrap">
        <div className="title">최근 프로젝트</div>
        <div className="recent-items">
          {recentProjects.map((recentProject) => (
            <div
              className="recent-item"
              key={recentProject.id}
              onClick={() => moveToProjectDetail(recentProject.id)}
            >
              <div className="name">{recentProject.name}</div>
              <div className="updated-at">{recentProject.lastUpdatedDate} 수정</div>
            </div>
          ))}
        </div>
      </div>
      <div className="recent-items-wrap">
        <div className="title">최근 토픽</div>
        <div className="recent-items"></div>
      </div>
      <div className="recent-items-wrap">
        <div className="title">최근 메모</div>
        <div className="recent-items"></div>
      </div>
      <div className="recent-items-wrap">
        <div className="title">최근 아티클</div>
        <div className="recent-items"></div>
      </div>
      <div className="recent-items-wrap">
        <div className="title">최근 자료</div>
        <div className="recent-items"></div>
      </div>
    </div>
  )
}

export default RecentWorkspace
