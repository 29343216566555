import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/main.scss'

import App from './App'
import reportWebVitals from './reportWebVitals'

import { BrowserRouter } from 'react-router-dom'
import { setupAxiosErrorResponseFormat } from './setup'
import ScrollToTop from './common/component/ScrollToTop'

setupAxiosErrorResponseFormat()

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <BrowserRouter>
    <ScrollToTop />
    <App />
  </BrowserRouter>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
