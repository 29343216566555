import Article from '../domain/Article'
import * as Excel from 'xlsx'
import FileSaver from 'file-saver'
import ExcelExportConverter from './ExcelExportConverter'
import ArticleExporter from './ArticleExporter'

export default class ExcelArticleExporter implements ArticleExporter {
  private fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  private fileName = 'article_data.xlsx'
  private gridHeader = [
    'title',
    'journal',
    'publisher',
    'publish_year',
    'authors',
    'abstracts',
    'keywords',
  ]

  exportArticle(articles: Article[]) {
    const converter = new ExcelExportConverter()
    const sheet = Excel.utils.aoa_to_sheet([this.gridHeader])
    articles
      .map((article) => converter.convert(article))
      .forEach((convertedArticle) => {
        Excel.utils.sheet_add_aoa(
          sheet,
          [
            [
              convertedArticle.title,
              convertedArticle.journal,
              convertedArticle.publisher,
              convertedArticle.publishYear,
              convertedArticle.authors,
              convertedArticle.abstracts,
              convertedArticle.keywords,
            ],
          ],
          { origin: -1 }
        )

        sheet['!cols'] = [{ wpx: 200 }, { wpx: 200 }]
      })

    const workBook = { Sheets: { data: sheet }, SheetNames: ['data'] }
    const excelButter = Excel.write(workBook, { bookType: 'xlsx', type: 'array' })
    const excelFile = new Blob([excelButter], { type: this.fileType })
    FileSaver.saveAs(excelFile, this.fileName)
  }
}
