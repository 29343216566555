import ArticleAuthor from './ArticleAuthor'
import ArticleSource from './ArticleSource'
import ArticlePatentClaim from './ArticlePatentClaim'
import ArticleOtherUrl from './ArticleOtherUrl'

export default interface Article {
  id: number
  articleType: string | null
  title: string
  subTitle?: string | null
  entireTitle: string
  keywords: string[]
  subKeywords: string[]
  abstractsTitle: string | null
  abstractsContent: string
  subAbstractsTitle: string | null
  subAbstractsContent?: string | null
  entireAbstracts: string
  categoryNames: string[]
  authors: ArticleAuthor[]
  articleUrl: string
  doi: string
  articleSource: ArticleSource
  otherUrls: ArticleOtherUrl[]
  patentClaims: ArticlePatentClaim[]
}

export const getPublishingInfo = (article: Article) => {
  const hasVolumeIssue =
    article.articleSource.volumeIssue && article.articleSource.volumeIssue.trim() !== ''
  const volumeIssueInfo = hasVolumeIssue ? `/ Volume: ${article.articleSource.volumeIssue} ` : ''
  const hasTotalPage: boolean = article.articleSource.totalPage !== 0
  const pageInfo = hasTotalPage
    ? `/ Page: ${article.articleSource.startPage} - ${article.articleSource.endPage}, 총 ${article.articleSource.totalPage} 페이지`
    : ''
  const publishYearString = `${article.articleSource.publishYear}년 발행`
  const publishYearNumber = Number(article.articleSource.publishYear)
  const isFuturePublishYear = isNaN(publishYearNumber)
    ? false
    : new Date().getFullYear() < publishYearNumber
  return ` ${publishYearString}${isFuturePublishYear ? ' 예정' : ''} ${volumeIssueInfo} ${pageInfo}`
}
