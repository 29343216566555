import { Fragment, ReactNode, useEffect } from 'react'

const ModalWindow = ({
  children,
  isOpen,
  closeModal,
}: {
  children: ReactNode
  isOpen: boolean
  closeModal: () => void
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'unset'
    }
  }, [isOpen])

  return (
    <Fragment>
      {isOpen && <div id="dim" onClick={closeModal} />}
      <dialog open={isOpen} aria-label="modal">
        {isOpen && (
          <div className="modal">
            <div className="header">
              <button className="close-button" aria-label="close-button" onClick={closeModal} />
            </div>
            {children}
          </div>
        )}
      </dialog>
    </Fragment>
  )
}

export default ModalWindow
