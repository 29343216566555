import React from 'react'

interface AbstractsComponentProps {
  abstracts: string
  subAbstracts: string | null | undefined
}

const AbstractsComponent = ({ abstracts, subAbstracts }: AbstractsComponentProps) => {
  if (abstracts.trim() !== '') {
    return (
      <>
        <p dangerouslySetInnerHTML={{ __html: abstracts }} />
        <p>
          <span className="sub" dangerouslySetInnerHTML={{ __html: subAbstracts || '' }} />
        </p>
      </>
    )
  } else {
    return (
      <>
        <p dangerouslySetInnerHTML={{ __html: subAbstracts || '' }} />
      </>
    )
  }
}

export default AbstractsComponent
