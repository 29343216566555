export const isEmpty = (value: string | undefined | null): boolean => {
  return value === undefined || value === null || value.trim() === ''
}

export const isNotEmpty = (value: string | undefined | null): boolean => {
  return value !== undefined && value !== null && value.trim() !== ''
}

export const forceString = (value: string | undefined | null): string => {
  return isNotEmpty(value) ? value! : ''
}
