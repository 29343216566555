import axios from 'axios'

import UserStorageRepository from './UserStorageRepository'
import Page from '../../common/domain/Page'
import Article from '../../article/domain/Article'
import ArticleAuthor from '../../article/domain/ArticleAuthor'

export interface ArticlesParams {
  page: number
  size: number
}

class AxiosUserStorageRepository implements UserStorageRepository {
  async addArticles(storageId: number, articles: number[]): Promise<void> {
    await axios.post(`/app/api/user-storages/${storageId}`, articles, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
    return Promise.resolve(undefined)
  }

  async getArticlesInStorage(
    storageId: number,
    searchText: string,
    params: ArticlesParams
  ): Promise<Page<Article>> {
    const { data } = await axios.get(`/app/api/user-storages/${storageId}`, {
      params: {
        ...params,
        keyword: searchText,
      },
    })
    return {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      size: data.size,
      pageable: {
        pageNumber: data.pageable.pageNumber,
      },
      content: data.content.map((article: Article) => {
        article.authors = article.authors.map(
          (author) =>
            new ArticleAuthor(author.id, author.name, author.subName, author.authorSequence)
        )
        return article
      }),
    }
  }

  async deleteArticles(storageId: number, articles: number[]): Promise<void> {
    await axios.delete(`/app/api/user-storages/${storageId}`, {
      params: {
        articles: articles.join(','),
      },
    })
    return Promise.resolve(undefined)
  }
}

export default AxiosUserStorageRepository
