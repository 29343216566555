import Article from '../domain/Article'
import AuthorArticles from '../domain/AuthorArticles'
import AxiosArticleRepository from './AxiosArticleRepository'
import ArticleSearchResponse from '../domain/ArticleSearchResponse'
import ArticleCategoryResponse from '../domain/ArticleCategoryResponse'
import ArticleJournalResponse from '../domain/ArticleJournalResponse'
import PageRequest from '../../common/domain/PageRequest'

export interface ArticlesParams {
  categoryId?: number | null
  authorId?: number | null
}

export default interface ArticleRepository {
  getArticlesInCategory(
    params: ArticlesParams,
    pageRequest: PageRequest
  ): Promise<ArticleCategoryResponse>

  getArticle(id: number): Promise<Article>

  getOtherArticlesByAuthors(
    excludeArticleId: number,
    authorIds: number[]
  ): Promise<AuthorArticles[]>

  getArticlesInJournal(
    journalId: number,
    publishYears: string[],
    pageRequest: PageRequest
  ): Promise<ArticleJournalResponse>

  searchInArticles(
    searchText: string,
    categoryIds: number[],
    journalIds: number[],
    authorIds: number[],
    publishYears: string[],
    keywords: string[],
    sources: string[],
    pageRequest: PageRequest
  ): Promise<ArticleSearchResponse>
}

export const articleRepository = new AxiosArticleRepository()
