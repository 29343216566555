import Article from '../domain/Article'
import ArticleExporter from './ArticleExporter'
import RefWorksExportConverter from './RefWorksExportConverter'
import FileSaver from 'file-saver'

export default class RefWorksArticleExporter implements ArticleExporter {
  private fileType = 'text/plain;charset=utf-8'
  private fileName = 'article_data.txt'

  exportArticle(articles: Article[]): void {
    const converter = new RefWorksExportConverter()
    const textContents = articles.map((article) => converter.convert(article)).join('\n')
    const textFile = new Blob([textContents], { type: this.fileType })
    FileSaver.saveAs(textFile, this.fileName)
  }
}
