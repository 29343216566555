import * as React from 'react'

import PageInfo from '../domain/PageInfo'

export interface PageInfoParam {
  pageInfo: PageInfo
  moveToPage: (page: number) => void
}

function Pagination({ pageInfo, moveToPage }: PageInfoParam) {
  function getStartPage() {
    return Math.floor(pageInfo.currentPage / 10) * 10 + 1
  }

  function callMovePage(page: number) {
    const actualPage = page - 1
    moveToPage(actualPage)
  }

  function moveToNextPage() {
    const startPage = getStartPage()
    const targetPage = startPage + 10
    if (targetPage <= pageInfo.totalPages) {
      callMovePage(targetPage)
    } else {
      moveToLastPage()
    }
  }

  function moveToLastPage() {
    if (pageInfo.currentPage < pageInfo.totalPages - 1) {
      callMovePage(pageInfo.totalPages)
    }
  }

  function moveToPreviousPage() {
    const startPage = getStartPage()
    const targetPage = startPage - 10
    if (targetPage >= 0) {
      callMovePage(targetPage)
    } else {
      moveToFirstPage()
    }
  }

  function moveToFirstPage() {
    if (pageInfo.currentPage > 0) {
      callMovePage(1)
    }
  }

  function getPageList(): number[] {
    const startPage = getStartPage()
    const endPage = Math.min(startPage + 9, pageInfo.totalPages)
    const length = endPage - startPage + 1
    return Array.from({ length }, (_, index) => index + startPage)
  }

  return (
    <div className="pagination">
      <span onClick={moveToFirstPage} className="move first">
        처음
      </span>
      <span onClick={moveToPreviousPage} className="move prev">
        이전
      </span>
      {getPageList().map((page) => (
        <span
          key={`page-${page}`}
          className={`${pageInfo.currentPage === page - 1 ? 'active' : ''} `}
          onClick={() => page - 1 !== pageInfo.currentPage && callMovePage(page)}
        >
          {page}
        </span>
      ))}
      <span onClick={moveToNextPage} className="move next">
        다음
      </span>
      <span onClick={moveToLastPage} className="move last">
        마지막
      </span>
    </div>
  )
}

export default Pagination
