import React, { useCallback, useEffect, useState } from 'react'

import ModalWindow from '../../common/component/ModalWindow'

import InitialWorkspace from './InitialWorkspace'
import RecentWorkspace from './RecentWorkspace'
import CreateProject from './CreateProject'
import RecentTactoProject from '../domain/RecentTactoProject'
import { getRecentProjects } from '../repository/TactoProjectRepository'

const DefaultWorkspace = () => {
  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [recentProjects, setRecentProjects] = useState<RecentTactoProject[]>([])

  const fetchProjects = useCallback(() => {
    getRecentProjects().then((projects) => setRecentProjects(projects))
  }, [])

  useEffect(() => {
    fetchProjects()
  }, [fetchProjects])

  const openModal = () => setModalOpen(true)
  const closeModal = () => setModalOpen(false)

  return (
    <>
      <ModalWindow isOpen={modalOpen} closeModal={closeModal}>
        <CreateProject fetchProjects={fetchProjects} closeModal={closeModal} />
      </ModalWindow>
      {recentProjects.length === 0 ? (
        <InitialWorkspace openModal={openModal} />
      ) : (
        <RecentWorkspace recentProjects={recentProjects} openModal={openModal} />
      )}
    </>
  )
}

export default DefaultWorkspace
