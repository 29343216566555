import React from 'react'
import RingLoader from 'react-spinners/RingLoader'

export interface Props {
  isLoading: boolean
  componentToRender: JSX.Element
}

const RingLoadingProgress = ({ isLoading, componentToRender }: Props) => {
  return isLoading ? (
    <div>
      <RingLoader size={30} color="#C63DEE" />
      <p style={{ marginTop: '10px' }} className="sum">
        검색중입니다...
      </p>
    </div>
  ) : (
    componentToRender
  )
}

export default RingLoadingProgress
