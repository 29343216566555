import axios from 'axios'
import JoinRequest from '../domain/request/JoinRequest'
import JoinRepository from './JoinRepository'

class AxiosJoinRepository implements JoinRepository {
  async requestJoin(request: JoinRequest): Promise<boolean> {
    await axios.post('/auth/api/user', {
      email: request.email,
      password: request.password,
      nickName: request.nickName,
      emailReceiveAgreement: request.emailReceiveAgreement,
    })
    return true
  }
}

export default AxiosJoinRepository
