import Button from './Button'

export interface HeadCharParam {
  headChar: string
  headCharValue: string[]
  setHeadChar: (headChar: string[]) => void
  className?: string
}

function HeadCharButton({ headChar, headCharValue, setHeadChar, className }: HeadCharParam) {
  return (
    <>
      <Button className={className} onClick={() => setHeadChar(headCharValue)}>
        {headChar}
      </Button>
    </>
  )
}

export default HeadCharButton
