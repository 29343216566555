import React from 'react'
import { useNavigate } from 'react-router-dom'
import { serviceDescription } from '../../common/constant/ServiceConstant'

function JoinRequested() {
  const navigate = useNavigate()
  return (
    <main className="member">
      <hgroup aria-label="main-logo" onClick={() => navigate('/main')}>
        <h1>K·DISCOVERY</h1>
        <h2>{serviceDescription}</h2>
      </hgroup>
      <section>
        <h1>회원가입</h1>
        <p className="announce join-02">
          회원가입 요청이 완료되었습니다.
          <br />
          이메일을 확인하여 회원가입을 완료해주세요.
        </p>
      </section>
    </main>
  )
}

export default JoinRequested
