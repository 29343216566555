import { Fragment, MouseEvent, useCallback, useContext, useEffect, useState } from 'react'

import Category from '../domain/Category'
import { formatNumber } from '../../common/utils/NumberFormatter'
import { Context as SelectedIdContext } from '../provider/SelectedIdProvider'

type CategoryTreeType = Map<number, Category> | null | undefined

interface Props {
  categories?: IterableIterator<Category>
  selectCategory: (id: number, paths: string[]) => void
  showCount: boolean
  markOnlyLeafNodeSelection: boolean
}

const CategoryNavigatorTree = ({
  categories,
  selectCategory,
  showCount,
  markOnlyLeafNodeSelection,
}: Props) => {
  const { selectedId, setSelectedId } = useContext(SelectedIdContext)!
  const [categoryArray, setCategoryArray] = useState<Category[]>([])
  const [openCheckArray, setOpenCheckArray] = useState<boolean[]>([])

  const hasChildren = (categoryTree: CategoryTreeType) => {
    if (!categoryTree) {
      return false
    }
    return categoryTree.size > 0
  }

  const treeOpenHandler = (index: number) => (event: MouseEvent<HTMLLIElement>) => {
    event.stopPropagation()
    const tempTreeOpenArray = openCheckArray.slice()
    tempTreeOpenArray[index] = !tempTreeOpenArray[index]
    setOpenCheckArray(tempTreeOpenArray)
  }

  const initialize = useCallback(() => {
    if (categories) {
      const array: Category[] = Array.from(categories)
      const isOpenArray = new Array(array.length).fill(false)
      setCategoryArray(array)
      setOpenCheckArray(isOpenArray)
    }
  }, [categories])

  useEffect(() => {
    initialize()
  }, []); // eslint-disable-line

  if (!categories) {
    return <Fragment />
  }

  return (
    <Fragment>
      {categoryArray.map((category: Category, index) => (
        <li className={`${openCheckArray[index] ? 'on' : ''}`} key={`category-${category.id}`}>
          <p>
            {hasChildren(category.children) && (
              <i onClick={treeOpenHandler(index)} className="toggle" />
            )}
            <a href="#!" className={selectedId === category.id ? 'on' : ''}>
              <span
                onClick={() => {
                  if (
                    markOnlyLeafNodeSelection &&
                    (category.children === null || category.children.size === 0)
                  ) {
                    setSelectedId(category.id)
                    selectCategory(category.id, category.paths)
                  } else if (!markOnlyLeafNodeSelection) {
                    setSelectedId(category.id)
                    selectCategory(category.id, category.paths)
                  }
                }}
              >
                <span className="label">{category.name}</span>
                {showCount && <span className="number">{formatNumber(category.articleCount)}</span>}
              </span>
            </a>
          </p>
          {hasChildren(category.children) && (
            <ul className={`${openCheckArray[index] ? '' : 'close'}`}>
              <CategoryNavigatorTree
                categories={category.children?.values()}
                selectCategory={selectCategory}
                showCount={showCount}
                markOnlyLeafNodeSelection={markOnlyLeafNodeSelection}
              />
            </ul>
          )}
        </li>
      ))}
    </Fragment>
  )
}

export default CategoryNavigatorTree
