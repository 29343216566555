import { useCallback, useContext, useEffect, useState } from 'react'

import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

import MaterialDetail from '../domain/MaterialDetail'
import { getMaterialDetail, updateMaterialDetail } from '../repository/MaterialsRepository'
import { Context } from '../provider/TactoProjectProvider'

type Props = {
  id: number
  closeModal: () => void
}

const initialMaterial: MaterialDetail = {
  abstractsContent: '',
  authors: '',
  doi: '',
  id: 0,
  journal: '',
  journalType: '',
  memoContent: '',
  memoId: 0,
  memoTitle: '',
  publishYear: '',
  publisher: '',
  title: '',
}

const MaterialDetails = ({ id, closeModal }: Props) => {
  const { refreshItems } = useContext(Context)!
  const [materialDetail, setMaterialDetail] = useState<MaterialDetail>(initialMaterial)

  useEffect(() => {
    getMaterialDetail(id).then((detail) => setMaterialDetail(detail))
  }, [id])

  const onSave = useCallback(() => {
    updateMaterialDetail(materialDetail).then(refreshItems).finally(closeModal)
  }, [materialDetail, refreshItems, closeModal])

  const onChangeMaterialDetail = useCallback(
    (key: string, value: string) => {
      setMaterialDetail({ ...materialDetail, [key]: value })
    },
    [materialDetail]
  )

  return (
    <div className="material-details-modal">
      <div className="title">상세 정보</div>
      <div className="content">
        <div className="item">
          <div className="label">DOI</div>
          <input
            type="text"
            onChange={(e) => onChangeMaterialDetail('doi', e.target.value)}
            aria-label="doi"
            value={materialDetail.doi}
          />
        </div>
        <div className="item">
          <div className="label">제목</div>
          <input
            type="text"
            onChange={(e) => onChangeMaterialDetail('title', e.target.value)}
            aria-label="title"
            value={materialDetail.title}
          />
        </div>
        <div className="items">
          <div className="item">
            <div className="label">발행연도</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('publishYear', e.target.value)}
              aria-label="publishYear"
              value={materialDetail.publishYear}
            />
          </div>
          <div className="item">
            <div className="label">기관명</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('publisher', e.target.value)}
              aria-label="publisher"
              value={materialDetail.publisher}
            />
          </div>
        </div>
        <div className="items">
          <div className="item">
            <div className="label">학술지명</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('journal', e.target.value)}
              aria-label="journal"
              value={materialDetail.journal}
            />
          </div>
          <div className="item">
            <div className="label">유형</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('journalType', e.target.value)}
              aria-label="journalType"
              value={materialDetail.journalType}
            />
          </div>
        </div>
        <div className="item">
          <div className="label">저자</div>
          <input
            type="text"
            onChange={(e) => onChangeMaterialDetail('authors', e.target.value)}
            aria-label="authors"
            value={materialDetail.authors}
          />
        </div>
        <div className="item">
          <div className="label">초록</div>
          <textarea
            onChange={(e) => onChangeMaterialDetail('abstractsContent', e.target.value)}
            aria-label="abstractsContent"
            value={materialDetail.abstractsContent}
          />
        </div>
        <div className="memo-section">
          <div className="item">
            <div className="label">메모 제목</div>
            <input
              type="text"
              onChange={(e) => onChangeMaterialDetail('memoTitle', e.target.value)}
              aria-label="memoTitle"
              value={materialDetail.memoTitle}
            />
          </div>
          <div className="item">
            <div className="label">메모 내용</div>
            <textarea
              onChange={(e) => onChangeMaterialDetail('memoContent', e.target.value)}
              aria-label="memoContent"
              value={materialDetail.memoContent}
            />
          </div>
        </div>
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="저장" onClick={onSave} />
      </div>
    </div>
  )
}

export default MaterialDetails
