import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Location from '../../common/component/Location'
import HeadCharButtonGroup from '../../common/component/HeadCharButtonGroup'
import Button from '../../common/component/Button'
import Pagination from '../../common/component/Pagination'
import Page from '../../common/domain/Page'
import { usePageable } from '../../common/hooks/usePageable'

import Journal from '../domain/Journal'
import { journalRepository } from '../repository/JournalRepository'
import { Context as SearchTextContext } from '../../article/provider/SearchConditionProvider'
import CategoryNavigator from '../../article/component/CategoryNavigator'
import Category from '../../article/domain/Category'
import { Context as CategoryContext } from '../../article/provider/CategoryProvider'
import RingLoadingProgress from '../../common/component/RingLoadingProgress'
import JournalListComponent from '../component/JournalListComponent'

type SelectedCategory = {
  id: number
  paths: string[]
}

const Journals = () => {
  const [searchParams] = useSearchParams()
  const { pageInfo, setupPage } = usePageable()
  const { manualSearchTrigger } = useContext(SearchTextContext)!
  const { categoryTree } = useContext(CategoryContext)!

  const [isLoading, setIsLoading] = useState(false)
  const nameRef = useRef<HTMLInputElement>(null)
  const [headChar, setHeadChar] = useState<string[]>([])
  const [journals, setJournals] = useState<Journal[]>([])
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory | null>(null)
  const [instantCategoryTree, setInstanceCategoryTree] = useState<Category | null>(null)
  const [categoryRerender, setCategoryRerender] = useState<number>(1)

  const getJournalsSuccessHandler = useCallback(
    (currentPage: number) => (journalsPage: Page<Journal>) => {
      setJournals(journalsPage.content)
      setupPage(journalsPage, currentPage)
      setIsLoading(false)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      setIsLoading(true)
      journalRepository
        .getJournals({
          page: currentPage,
          size: 10,
          name: nameRef.current?.value ?? '',
          headCharacter: headChar,
          categoryId: selectedCategory?.id,
        })
        .then(getJournalsSuccessHandler(currentPage))
    },
    [headChar, getJournalsSuccessHandler, selectedCategory]
  )

  const getJournalCounts = useCallback(() => {
    journalRepository.getJournalCountPerCategory().then((journalCounts) => {
      if (categoryTree !== null && categoryTree.children !== null) {
        const newCategoryTree: Category = {
          ...categoryTree!,
        }
        const categoryCountMap = new Map(
          journalCounts.map((count) => [count.categoryId, count.count])
        )
        newCategoryTree.children!.forEach((topLevel) => {
          let topLevelCount = categoryCountMap.has(topLevel.id)
            ? categoryCountMap.get(topLevel.id)!
            : 0
          topLevel.children?.forEach((nextLevel) => {
            nextLevel.articleCount = categoryCountMap.has(nextLevel.id)
              ? categoryCountMap.get(nextLevel.id)!
              : 0
            topLevelCount += nextLevel.articleCount
          })
          topLevel.articleCount = topLevelCount
        })
        setInstanceCategoryTree(newCategoryTree)
      }
    })
  }, [categoryTree])

  const searchByName = () => {
    moveToPage(0)
  }

  const selectCategory = (id: number, paths: string[]) => {
    setHeadChar([])
    nameRef.current!.value = ''
    setSelectedCategory({
      id,
      paths,
    })
  }

  const searchConditionString = useCallback(() => {
    const categoryPath = selectedCategory?.paths[selectedCategory?.paths.length - 1]
    const headCharString = headChar.join(',')
    const journalName =
      nameRef.current?.value && nameRef.current?.value !== '' && nameRef.current.value
    const conditions: string[] = []
    if (categoryPath) conditions.push(`카테고리: ${categoryPath}`)
    if (headCharString) conditions.push(`머리글자: ${headCharString}`)
    if (journalName) conditions.push(`저널명: ${journalName}`)
    return conditions.join(' / ')
  }, [selectedCategory, headChar, nameRef])

  const initSearchCondition = () => {
    setSelectedCategory(null)
    setHeadChar([])
    setCategoryRerender((prev) => prev + 1)
    if (nameRef.current) {
      nameRef.current.value = ''
    }
  }

  useEffect(() => {
    const name = searchParams.get('name')
    if (initialLoad && name) {
      nameRef.current!.value = name
      setInitialLoad(false)
    }
    if (headChar.length > 0) {
      nameRef.current!.value = ''
    }
    moveToPage(0)
  }, [headChar, moveToPage, initialLoad, searchParams])

  useEffect(() => {
    const name = searchParams.get('name')
    if (name) {
      nameRef.current!.value = name
      moveToPage(0)
    }
    // eslint-disable-next-line
  }, [manualSearchTrigger, searchParams])

  useEffect(() => {
    getJournalCounts()
  }, [categoryTree, getJournalCounts])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pageInfo])

  return (
    <main>
      <div className="container">
        <Location
          paths={
            selectedCategory != null
              ? Array.prototype.concat(
                  ['저널'],
                  selectedCategory?.paths?.filter((item) => item !== 'ROOT')
                )
              : ['저널']
          }
        />
        <h1 className="head-pointer" onClick={initSearchCondition}>
          저널
        </h1>
        <div className="content-wrap">
          <CategoryNavigator
            key={categoryRerender}
            categoryTree={instantCategoryTree?.children}
            selectCategory={selectCategory}
            showCount={true}
            markOnlyLeafNodeSelection={false}
          />
          <section className="content">
            <HeadCharButtonGroup headChar={headChar} setHeadChar={setHeadChar} />
            <div className="result-summary above">
              <p></p>
              <fieldset className="sort">
                <input
                  type="text"
                  aria-label="journals-search-input"
                  defaultValue=""
                  ref={nameRef}
                  placeholder="저널명을 입력해 주세요"
                  onKeyUp={(e) => {
                    if (e.key === 'Enter') searchByName()
                  }}
                />
                <Button ariaLabel="journals-search-button" onClick={searchByName}>
                  검색
                </Button>
              </fieldset>
            </div>
            <RingLoadingProgress
              isLoading={isLoading}
              componentToRender={
                <>
                  <ul className="journal-list">
                    {journals.map((journal) => (
                      <JournalListComponent key={`journal-${journal.id}`} journal={journal} />
                    ))}
                  </ul>
                  {journals.length > 0 ? (
                    <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
                  ) : (
                    <div className="result-summary" aria-label="no-search-result">
                      <p className="sum">
                        <strong className="number">
                          {searchConditionString().trim() !== '' && searchConditionString().trim()}
                        </strong>
                        에 대한 검색 결과가 없습니다
                        <button style={{ color: 'blue' }} onClick={initSearchCondition}>
                          (검색 조건 초기화)
                        </button>
                      </p>
                    </div>
                  )}
                </>
              }
            />
          </section>
        </div>
      </div>
    </main>
  )
}

export default Journals
