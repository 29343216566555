import * as React from 'react'
import { useCallback, useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import ModalWindow from '../../common/component/ModalWindow'
import Location from '../../common/component/Location'
import Advertisement from '../../common/component/Advertisement'
import Button from '../../common/component/Button'
import AllCheckBox from '../../common/component/AllCheckBox'
import CheckBox from '../../common/component/CheckBox'
import Pagination from '../../common/component/Pagination'
import RingLoadingProgress from '../../common/component/RingLoadingProgress'
import { useCheckbox } from '../../common/hooks/useCheckbox'
import { usePageable } from '../../common/hooks/usePageable'

import MyArticlesStorageBox from '../../user/component/MyArticlesStorageBox'
import { Context as UserContext } from '../../user/provider/UserInfoProvider'
import { userStorageRepository } from '../../user/repository/UserStorageRepository'

import ArticleComponent from '../../article/component/ArticleComponent'
import Article from '../../article/domain/Article'
import { articleRepository } from '../../article/repository/ArticleRepository'
import ArticleCategoryResponse from '../../article/domain/ArticleCategoryResponse'
import ArticleExporter from '../../article/utils/ArticleExporter'
import ExportArticlesDropdown from '../../article/component/ExportArticlesDropdown'
import Author from '../domain/Author'
import { authorRepository } from '../repository/AuthorRepository'

function AuthorDetail() {
  const { isLoggedIn } = useContext(UserContext)!
  const { id } = useParams()
  const { pageInfo, setupPage } = usePageable()

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState(false)
  const [author, setAuthor] = useState<Author>()
  const [articles, setArticles] = useState<Article[]>([])
  const { isAllChecked, checkedItems, onAllCheckHandler, onCheckedHandler, clearAllChecked } =
    useCheckbox()

  const getArticlesSuccessHandler = useCallback(
    (currentPage: number) => (response: ArticleCategoryResponse) => {
      setArticles(response.articles.content)
      setupPage(response.articles, currentPage)
      setIsLoading(false)
    },
    [setupPage]
  )

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (author) {
        setIsLoading(true)
        articleRepository
          .getArticlesInCategory(
            {
              authorId: author.authorId,
            },
            {
              page: currentPage,
              size: 10,
            }
          )
          .then(getArticlesSuccessHandler(currentPage))
      }
    },
    [author, getArticlesSuccessHandler]
  )

  const openArticlesSaveModal = () => {
    if (!isLoggedIn) {
      global.alert('로그인이 필요합니다.')
      return
    }
    if (checkedItems.size === 0) {
      global.alert('보관함에 저장할 학술논문을 선택해주세요.')
      return
    }
    setModalOpen(true)
  }

  const successAddArticlesHandler = () => {
    alert('학술논문이 선택하신 보관함에 저장되었습니다.')
    setModalOpen(false)
    clearAllChecked()
  }

  const selectStorage = (storageId: number) => {
    userStorageRepository
      .addArticles(storageId, Array.from(checkedItems))
      .then(successAddArticlesHandler)
  }

  useEffect(() => {
    if (id) {
      authorRepository.getAuthor(parseInt(id)).then((author) => {
        setAuthor(author)
      })
    }
  }, [id])

  useEffect(() => {
    moveToPage(0)
  }, [moveToPage])

  return (
    <main>
      <ModalWindow isOpen={modalOpen} closeModal={() => setModalOpen(false)}>
        <MyArticlesStorageBox isModifiable={false} selectStorage={selectStorage} />
      </ModalWindow>
      <div className="container">
        <Location paths={['저자']} />
        <div className="content-wrap reverse">
          <Advertisement />
          <section className="content">
            <div className="result-summary above">
              <div className="func">
                <AllCheckBox
                  isAllChecked={isAllChecked}
                  onAllCheckHandler={(e) => onAllCheckHandler(e, articles)}
                />
                <Button
                  className="keep"
                  ariaLabel="storage-save-button"
                  onClick={openArticlesSaveModal}
                >
                  보관함에 저장
                </Button>
                <ExportArticlesDropdown
                  ariaLabel="export"
                  onSelectCallback={(articleExporter: ArticleExporter) => {
                    if (checkedItems.size === 0) {
                      alert('내보낼 논문을 선택하세요')
                      return
                    }
                    const selectedArticles: Article[] = articles.filter((article) =>
                      checkedItems.has(article.id)
                    )
                    articleExporter.exportArticle(selectedArticles)
                  }}
                />
              </div>
            </div>
            <RingLoadingProgress
              isLoading={isLoading}
              componentToRender={
                <>
                  <h1 className="author-head">
                    <span className="ko">{author?.representationalName}</span>
                    <span className="en">{` ${author?.englishName}`}</span>
                  </h1>
                  <div className="result-summary above">
                    <p className="sum">
                      총 <strong className="number">{pageInfo.totalElements}</strong>
                      편의 논문이 있습니다.
                    </p>
                  </div>
                  <ul className="thesis-list">
                    {articles.map((article) => {
                      return (
                        <div key={article.id} className="thesis-item">
                          <CheckBox
                            onChange={(event) => onCheckedHandler(event, article.id)}
                            className="sgl-check unit"
                            ariaLabel={`checkbox-${article.id}`}
                            isAllChecked={isAllChecked}
                            checked={checkedItems.has(article.id)}
                          />
                          <ArticleComponent article={article} />
                        </div>
                      )
                    })}
                  </ul>
                  <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
                </>
              }
            />
          </section>
        </div>
      </div>
    </main>
  )
}

export default AuthorDetail
