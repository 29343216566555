import Location from '../../common/component/Location'
import InformationMenu from '../component/InformationMenu'
import React from 'react'

const Introduction = () => (
  <main>
    <div className="container">
      <Location paths={['이용안내', 'K-DISCOVERY 소개']} />
      <div className="content-wrap">
        <InformationMenu />
        <section className="content">
          <h1 className="normal-head">K-DISCOVERY 소개</h1>
          <div className="guide-wrap">
            <p className="attention">
              K-DISCOVERY는 연구자 중심의 학술정보 플랫폼입니다.
              <br />
              K-DISCOVERY는 국내 및 해외 학술저널(및 향후 특허까지) 정보를 제공하는 온라인 서비스로,
              제목, 저자, 초록 등의 서지정보 열람은 물론 저널/저자/키워드 분석까지 제공함으로써
              연구주제 탐색부터 동향 분석, 논문작성 방향 설정까지 지원합니다.
            </p>
            <p>
              K-DISCOVERY는 최신 세만틱웹 기술을 이용하여 인터넷상의 학술논문을 분석하는
              서비스입니다. 강력한 통합검색과 트렌드 이슈를 통해 학술연구에 큰 도움을 줄 수 있을
              것입니다. K-DISCOVERY에서 분석하는 학술정보는 유료저널과 무료저널이 모두 포함되어
              있습니다. 다만 목록과 검색결과에 출처와 OA여부가 표시될 예정입니다.
            </p>
            <p>
              학술 정보를 연구하는 사람들이 느끼는 가장 큰 불편 중의 하나는 흩어져 있는 학술정보를
              여러 사이트에서 검색해야 한다는 점입니다. 포털 사이트를 이용하더라도 결국에는 이
              사이트, 저 사이트를 검색해야 원하는 정보를 찾을 수 있습니다. K-DISCOVERY는 하나의
              사이트에서 중요한 학술정보를 모두 찾을 수 있는 것을 목표로 하고 있습니다. 아직은
              통합검색할 수 있는 사이트수가 많지 않지만 점차적으로 사이트 수를 늘려갈 것이며, 국내
              특허와 해외 특허도 통합검색 서비스를 제공할 예정입니다.
            </p>
            <p>
              학술 정보를 연구하는 사람들이 느끼는 또다른 불편함은 같은 논문, 같은 저널이 사이트마다
              표현방식이 다르다는 점입니다. K-DISCOVERY에서는 사이트마다 다른 것처럼 보이는 저널과
              논문을 식별하여 학술 연구의 편의성을 높인다는 것을 목표의 하나로 삼고 있습니다.
            </p>
            <p>
              예컨대 Journal of Industrial and Engineering Chemistry(한국공업화학회), Journal of the
              Korean Statistical Society(한국통계학회), Journal of Ginseng Research(고려인삼학회),
              Journal of Asia-Pacific Entomology(한국응용곤충학회), Current Applied
              Physics(한국물리학회), Nuclear Engineering and Technology(한국원자력학회), Asian
              Nursing Research(한국간호과학회) 등의 저널은 KCI와 Sciencedirect에서 모두 서비스되고
              있습니다. K-DISCOVERY는 이같이 저널을 식별하여 같은 논문을 두 번 찾지 않도록 지원할
              예정입니다.
            </p>
            <p>
              학술 정보를 연구하는 사람들이 느끼는 또 하나의 불편함은 내가 원하는 주제를 찾기가 쉽지
              않다는 점입니다. Kdiscovery는 저자들이 각기 다른 방식으로 표현한 키워드의 동의어를
              식별하여 트렌드 이슈로 제공합니다. 예컨대 가격 탄력성, 가격탄력성 같이 띄어쓰기가 다른
              키워드나 『가례』, 가례와 같이 다르게 표현된 키워드를 통일하여 분석서비스를
              제공합니다. 이는 오직 K-DISCOVERY만 가능한 서비스입니다.
            </p>
            <h2 className="normal-head centro">K-DISCOVERY DATABASE</h2>
            <ul className="numeric">
              <li>
                <dl>
                  <dt>논문</dt>
                  <dd>500만편의 논문</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>저널</dt>
                  <dd>8,000종의 저널</dd>
                </dl>
              </li>
              <li>
                <dl>
                  <dt>저자</dt>
                  <dd>100만명의 저자</dd>
                </dl>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </main>
)
export default Introduction
