import Button from '../../common/component/Button'
import InputEmail from '../../common/component/InputEmail'
import { useRef, useState } from 'react'
import { userRepository } from '../repository/UserRepository'
import { validateEmailFormat } from '../../common/utils/FormatChecker'
import { useNavigate } from 'react-router-dom'
import { serviceDescription } from '../../common/constant/ServiceConstant'

const FindPassword = () => {
  const navigate = useNavigate()
  const [errorMessage, setErrorMessage] = useState('')
  const emailRef = useRef<HTMLInputElement>(null)

  const submitHandler = () => {
    const email = emailRef.current?.value ?? ''
    if (!validateEmailFormat(email)) {
      alert('잘못된 형식의 이메일입니다.')
      return
    }
    userRepository
      .resetPassword(email)
      .then(() => {
        alert('링크가 이메일로 발송되었습니다.')
        navigate('/main')
      })
      .catch((error) => setErrorMessage(error.message))
  }

  return (
    <main className="member">
      <hgroup>
        <h1>K·DISCOVERY</h1>
        <h2>{serviceDescription}</h2>
      </hgroup>
      <section>
        <h1>비밀번호 찾기</h1>
        <p className="announce password-01">
          회원가입 시 등록하신 메일주소로
          <br />
          비밀번호 재설정 링크가 발송됩니다.
        </p>
        <div>
          <ul className="my-form full">
            <li>
              <InputEmail ref={emailRef} validationCallback={() => setErrorMessage('')} />
              {errorMessage && <p className="alert">{errorMessage}</p>}
            </li>
          </ul>
          <Button onClick={submitHandler} className="strong" ariaLabel="submit-button">
            확인
          </Button>
        </div>
        <ul className="note">
          <li>고객님의 이메일 정보로 비밀번호 재설정 링크가 발송됩니다.</li>
          <li>회원가입 시 입력한 이메일 주소를 알고 계셔야 비밀번호 재설정이 가능합니다.</li>
        </ul>
      </section>
    </main>
  )
}

export default FindPassword
