import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const InformationMenu = () => {
  const navigator = useNavigate()
  const location = useLocation()

  return (
    <aside className="aside">
      <nav className="lnb">
        <ul id="tree">
          <li
            onClick={() => {
              navigator('/introduction')
            }}
          >
            <p className="head">
              <span className={`${location.pathname === '/introduction' && 'on'}`}>
                K-DISCOVERY 소개
              </span>
            </p>
          </li>
          <li
            onClick={() => {
              navigator('/bbs/notices')
            }}
          >
            <p className="head">
              <span className={`${location.pathname === '/bbs/notices' && 'on'}`}>공지사항</span>
            </p>
          </li>
          <li
            onClick={() => {
              navigator('/bbs/newses')
            }}
          >
            <p className="head">
              <span className={`${location.pathname === '/bbs/newses' && 'on'}`}>뉴스</span>
            </p>
          </li>
          <li
            onClick={() => {
              navigator('/bbs/faqs')
            }}
          >
            <p className="head">
              <span
                className={`${location.pathname === '/bbs/faqs' && 'on'}`}
                aria-label="info-menu-btn-faq"
              >
                FAQ
              </span>
            </p>
          </li>
          <li
            onClick={() => {
              navigator('/bbs/reports')
            }}
          >
            <p className="head">
              <span className={`${location.pathname === '/bbs/reports' && 'on'}`}>
                오류신고/개선의견/문의
              </span>
            </p>
          </li>
          <li className="on">
            <p className="toggle head">
              <span>이용안내</span>
              <i></i>
            </p>
            <ul>
              <li onClick={() => navigator('/information/service')}>
                <p>
                  <span className={`${location.pathname === '/information/service' && 'on'} label`}>
                    서비스 이용방법
                  </span>
                </p>
              </li>
              <li onClick={() => navigator('/information/search')}>
                <p>
                  <span className={`${location.pathname === '/information/search' && 'on'} label`}>
                    자료검색 안내
                  </span>
                </p>
              </li>
              <li onClick={() => navigator('/information/my-page')}>
                <p>
                  <span className={`${location.pathname === '/information/my-page' && 'on'} label`}>
                    마이페이지 안내
                  </span>
                </p>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
    </aside>
  )
}

export default InformationMenu
