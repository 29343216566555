import React, { useContext } from 'react'
import AdvancedSearchHandlerComponent from '../../article/component/AdvancedSearchHandlerComponent'
import { Context as AdvancedSearchContext } from '../provider/AdvancedSearchModalProvider'
import { Context as SearchConditionContext } from '../../article/provider/SearchConditionProvider'
import { useNavigate } from 'react-router-dom'

const AdvancedSearchModal = () => {
  const navigator = useNavigate()
  const { isOpen, closeModal } = useContext(AdvancedSearchContext)!
  const searchCondition = useContext(SearchConditionContext)!
  const searchWithFormula = (formulaText: string) => {
    searchCondition.clearConditions()
    searchCondition.setSearchText(formulaText)
    searchCondition.setManualSearchTrigger(!searchCondition.manualSearchTrigger)
    navigator('/article-search')
    closeModal()
  }
  return (
    <>
      <AdvancedSearchHandlerComponent
        isOpen={isOpen}
        searchCallback={searchWithFormula}
        closeCallback={closeModal}
      />
    </>
  )
}

export default AdvancedSearchModal
