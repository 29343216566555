import React, { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Location from '../../common/component/Location'
import HeadCharButtonGroup from '../../common/component/HeadCharButtonGroup'
import Advertisement from '../../common/component/Advertisement'
import Button from '../../common/component/Button'
import Pagination from '../../common/component/Pagination'
import Page from '../../common/domain/Page'
import { usePageable } from '../../common/hooks/usePageable'

import Publisher from '../domain/Publisher'
import { publisherRepository } from '../repository/PublisherRepository'
import { Context as SearchTextContext } from '../../article/provider/SearchConditionProvider'
import { isNotEmpty } from '../../common/utils/TextUtils'

function Publishers() {
  const { pageInfo, setupPage } = usePageable()
  const [searchParams] = useSearchParams()
  const { manualSearchTrigger } = useContext(SearchTextContext)!

  const nameRef = useRef<HTMLInputElement>(null)
  const [publishers, setPublishers] = useState<Publisher[]>([])
  const [headChar, setHeadChar] = useState<string[]>([])
  const [openJournals, setOpenJournals] = useState<Set<number>>(new Set())
  const [initialLoad, setInitialLoad] = useState<boolean>(true)
  const [searchTarget, setSearchTarget] = useState('')

  const setTargetHeadChar = useCallback((headChars: string[]) => {
    nameRef.current!.value = ''
    setSearchTarget(headChars.join(', '))
    setHeadChar(headChars)
  }, [])

  const getPublisherSuccessHandler = useCallback(
    (currentPage: number) => (publisherPage: Page<Publisher>) => {
      setPublishers(publisherPage.content)
      setupPage(publisherPage, currentPage)
    },
    [setupPage]
  )

  const searchWithName = () => {
    setHeadChar([])
    setSearchTarget(nameRef.current?.value || '')
    moveToPage(0)
  }

  const moveToPage = useCallback(
    (currentPage: number) => {
      publisherRepository
        .getPublishers({
          page: currentPage,
          size: 10,
          searchName: nameRef.current?.value ?? '',
          nation: '',
          headCharacter: headChar.join(','),
        })
        .then(getPublisherSuccessHandler(currentPage))
    },
    [headChar, getPublisherSuccessHandler]
  )

  const showJournal = (publisherId: number) => {
    if (openJournals.has(publisherId)) {
      openJournals.delete(publisherId)
    } else {
      openJournals.add(publisherId)
    }
    setOpenJournals(new Set(openJournals))
  }

  useEffect(() => {
    const name = searchParams.get('name')
    if (initialLoad && name) {
      nameRef.current!.value = name
      setInitialLoad(false)
    }
    moveToPage(0)
  }, [headChar, moveToPage, initialLoad, searchParams])

  useEffect(() => {
    const name = searchParams.get('name')
    if (name) {
      nameRef.current!.value = name
      moveToPage(0)
    }
    // eslint-disable-next-line
  }, [manualSearchTrigger, searchParams])

  return (
    <main>
      <div className="container">
        <Location paths={['발행기관']} />
        <h1 className="head">발행기관</h1>
        <div className="content-wrap reverse">
          <Advertisement />
          <section className="content">
            <>
              <HeadCharButtonGroup headChar={headChar} setHeadChar={setTargetHeadChar} />
              <div className="result-summary above">
                <p></p>
                <fieldset className="sort">
                  <input
                    type="text"
                    ref={nameRef}
                    placeholder="발행기관명을 입력해 주세요"
                    aria-label="publisher-search-input"
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') searchWithName()
                    }}
                  />
                  <Button ariaLabel="publisher-search-button" onClick={searchWithName}>
                    검색
                  </Button>
                </fieldset>
              </div>
              {publishers.length > 0 ? (
                <>
                  <ul className="table-type institution-list">
                    {publishers.map((publisher) => {
                      return (
                        <li key={`publisher-${publisher.publisherId}`}>
                          <div className="info">
                            <dl className="name-ko" title={publisher.name}>
                              <dt>발행기관</dt>
                              <dd>{publisher.name.trim()}</dd>
                            </dl>
                            <dl className="button">
                              <dt>저널</dt>
                              <dd>
                                <button
                                  type="button"
                                  className="toggle"
                                  onClick={() => showJournal(publisher.publisherId)}
                                >
                                  저널보기
                                </button>
                              </dd>
                            </dl>
                            <dl className="region">
                              <dt>지역</dt>
                              <dd>{publisher.nation}</dd>
                            </dl>
                            <dl className="amount">
                              <dt>논문 수</dt>
                              <dd className="non-text">{publisher.totalArticleCount}</dd>
                            </dl>
                          </div>
                          <div
                            className={`journal ${
                              openJournals.has(publisher.publisherId) ? 'on' : ''
                            }`}
                          >
                            <ul>
                              {publisher.journals.map((item, index) => (
                                <li key={`journals-${item.journalId}-${index}`}>
                                  <a
                                    aria-label={`journal-link-${item.journalId}`}
                                    href={`/journals/${item.journalId}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    <span>{item.journal}</span>
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </li>
                      )
                    })}
                  </ul>
                  <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
                </>
              ) : (
                <div className="result-summary" aria-label="no-search-result">
                  <p className="sum">
                    {isNotEmpty(searchTarget) ? (
                      <>
                        <strong className="number">"{searchTarget}"</strong>에 대한 검색 결과가
                        없습니다
                      </>
                    ) : (
                      <>검색 결과가 없습니다</>
                    )}
                  </p>
                </div>
              )}
            </>
          </section>
        </div>
      </div>
    </main>
  )
}

export default Publishers
