import * as React from 'react'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import ArticleCountSummary from '../domain/ArticleCountSummary'
import { formatNumber } from '../../common/utils/NumberFormatter'
import RingLoader from 'react-spinners/RingLoader'
import { isNotEmpty } from '../../common/utils/TextUtils'

type Props = {
  title: string
  options: ArticleCountSummary<string>[]
  setSelectedOptions: (selectedOptions: Map<string, string>) => void
  selectedIds: string[]
  isLoading: boolean
}
export const FilterOptionSelector = ({
  title,
  options,
  setSelectedOptions,
  selectedIds,
  isLoading,
}: Props) => {
  const [limitHeight, setLimitHeight] = useState(true)
  const [optionCheckedMap, setOptionCheckedMap] = useState<Map<string, boolean>>(new Map())
  const [optionMap, setOptionMap] = useState<Map<string, string>>(new Map())

  const initialize = useCallback(() => {
    if (options) {
      const falseMap = new Map(options.map((option) => [option.key, false]))
      setOptionCheckedMap(falseMap)
    }
  }, [options])

  const checkboxChangeHandler = (key: string) => (event: ChangeEvent<HTMLInputElement>) => {
    const selectedMap = new Map(optionCheckedMap)
    selectedMap.set(key, event.target.checked)

    const selectedOptions: Map<string, string> = Array.from(selectedMap.keys())
      .filter((key) => selectedMap.get(key))
      .reduce((map, key) => {
        map.set(key, optionMap.get(key))
        return map
      }, new Map())

    setSelectedOptions(selectedOptions)
    setOptionCheckedMap(selectedMap)
  }

  useEffect(() => {
    setOptionMap(new Map(options.map((option) => [option.key, option.value])))
  }, [options])

  useEffect(() => {
    if (selectedIds.length === 0) {
      initialize()
    } else {
      selectedIds.forEach((selectedId) => {
        optionCheckedMap.set(selectedId, true)
      })
    }
    }, [selectedIds]); // eslint-disable-line

  useEffect(() => {
    initialize()
    }, []); // eslint-disable-line

  return (
    <ul id="tree-check" style={{ marginTop: '10px' }}>
      <li className="on">
        <p className="head">
          <span>{title}</span>
        </p>
        <ul className={limitHeight ? 'limit-height' : ''}>
          {isLoading ? (
            <div style={{ display: 'flex' }}>
              <div style={{ margin: '0 auto' }}>
                <RingLoader size={20} color="#C63DEE" />
              </div>
            </div>
          ) : options.filter((option) => option.value.trim() !== '').length > 0 ? (
            options
              .filter((option) => isNotEmpty(option.key) && isNotEmpty(option.value))
              .map((option) => (
                <li key={`option-title-${option.key}`}>
                  <p>
                    <label className="checkbox">
                      <input
                        aria-label={`option-checkbox-${option.key}`}
                        type="checkbox"
                        checked={optionCheckedMap.get(option.key) || false}
                        onChange={checkboxChangeHandler(option.key)}
                      />
                      <span className="label">
                        <i>
                          {option.value === 'KoreaPatent' ? '특허' : option.value} (
                          {formatNumber(option.count)})
                        </i>
                      </span>
                    </label>
                  </p>
                </li>
              ))
          ) : (
            ''
          )}
        </ul>
        <button onClick={() => setLimitHeight(!limitHeight)} type="button" className="list-toggle">
          {limitHeight ? '펼쳐보기' : '줄이기'}
        </button>
      </li>
    </ul>
  )
}
