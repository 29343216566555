import { useCallback } from 'react'
import { deleteMaterials } from '../repository/MaterialsRepository'
import CancelButton from '../../common/component/CancelButton'
import ConfirmButton from '../../common/component/ConfirmButton'

type Props = {
  ids: Set<number>
  fetchMaterials: () => void
  closeModal: () => void
}

const DeleteTopicMaterial = ({ ids, fetchMaterials, closeModal }: Props) => {
  const onDeleteHandler = useCallback(() => {
    deleteMaterials(Array.from(ids)).then(fetchMaterials).finally(closeModal)
  }, [ids, fetchMaterials, closeModal])

  return (
    <div className="delete-topic-material-modal">
      <div className="title">자료 삭제</div>
      <div className="message">
        <div className="alert-icon" />
        <div>선택한 자료를 삭제합니다.</div>
      </div>
      <div className="buttons">
        <CancelButton name="취소" onClick={closeModal} />
        <ConfirmButton name="삭제" onClick={onDeleteHandler} />
      </div>
    </div>
  )
}

export default DeleteTopicMaterial
