import "./loader.js";
import "owl.carousel";

const initOwlCarousel = (query) => {
    const owl = $(query); // eslint-disable-line
    owl.owlCarousel({
        center: false,
        loop: true,
        margin: 0,
        dots: false,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        responsiveClass: true,
        responsive: {
            0: {
                items: 2
            },
            480: {
                items: 3
            },
            640: {
                items: 4
            },
            769: {
                items: 8
            }
        }
    });
};

export {initOwlCarousel};
