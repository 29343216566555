import { MouseEvent } from 'react'

export type Props = {
  name: string
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void
  isFitContent?: boolean
}

const ConfirmButton = ({ name, onClick, isFitContent = false }: Props) => {
  return (
    <button className={`tacto confirm ${isFitContent ? 'fit-content' : ''}`} onClick={onClick}>
      {name}
    </button>
  )
}

export default ConfirmButton
