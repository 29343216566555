import AxiosTrendKeywordRepository from './AxiosTrendKeywordRepository'
import Page from '../../common/domain/Page'
import TrendKeyword from '../domain/TrendKeyword'
import RelatedArticle from '../domain/RelatedArticle'

export type LanguageType = 'KOREAN' | 'ENGLISH' | 'CHINESE' | 'ALL'

export interface TrendKeywordParams {
  page: number
  size: number
  categoryId: number
  language: LanguageType
}

export interface RelatedArticlesParams {
  page: number
  size: number
  keyword: string
  frequencyId: string
}

export default interface TrendKeywordRepository {
  getTrendKeywords(params: TrendKeywordParams): Promise<Page<TrendKeyword>>

  getRelatedArticles(params: RelatedArticlesParams): Promise<Page<RelatedArticle>>
}

export const trendKeywordRepository = new AxiosTrendKeywordRepository()
