import Article from '../domain/Article'
import ArticleExporter from './ArticleExporter'
import BibTexExportConverter from './BibTexExportConverter'
import FileSaver from 'file-saver'

export default class BibTexArticleExporter implements ArticleExporter {
  private fileType = 'text/plain;charset=utf-8'
  private fileName = 'article_data.bib'

  exportArticle(articles: Article[]) {
    const converter = new BibTexExportConverter()
    const textContents = articles.map((article) => converter.convert(article)).join(',')
    const textFile = new Blob([textContents], { type: this.fileType })
    FileSaver.saveAs(textFile, this.fileName)
  }
}
