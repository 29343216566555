import Page from '../../common/domain/Page'
import Author from '../domain/Author'
import AxiosAuthorRepository from './AxiosAuthorRepository'

export interface AuthorRequest {
  page: number
  size: number
  name: string
  headCharacter: string
}

export default interface AuthorRepository {
  getAuthors(params: AuthorRequest): Promise<Page<Author>>

  getAuthor(id: number): Promise<Author>
}

export const authorRepository = new AxiosAuthorRepository()
