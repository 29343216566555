import React, { forwardRef, Ref, useState } from 'react'
import { PropsInputBox } from '../ValidateInput'

export interface ValidateProps {
  label: string
  type: string
  value?: string
  readOnly?: boolean
  validators: { validator: (text: string) => boolean; errorMessage: string }[]
  placeholder?: string
}

const withValidate = (
  WrappedComponent: React.ForwardRefExoticComponent<
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    React.PropsWithoutRef<PropsInputBox> & React.RefAttributes<HTMLInputElement>
  >
) =>
  forwardRef((props: ValidateProps, ref: Ref<HTMLInputElement>) => {
    const { validators } = props
    const [errorMessage, setErrorMessage] = useState<string>('')

    const onValidate = (text: string) => {
      const error = validators?.find((item) => !item.validator(text))
      if (error) {
        setErrorMessage(error.errorMessage)
      } else {
        setErrorMessage('')
      }
    }

    const newProps = {
      ...props,
      ref,
      onValidate,
      errorMessage,
    }

    return <WrappedComponent {...newProps} />
  })

export default withValidate
