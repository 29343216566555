import React, { Fragment } from 'react'
import { useNavigate } from 'react-router-dom'

import MyArticlesStorageBox from './MyArticlesStorageBox'

type Props = {
  myArticles?: boolean
}

const UserInfoMenu = ({ myArticles }: Props = { myArticles: false }) => {
  const navigate = useNavigate()

  const moveToUserInfo = () => {
    navigate('/user-info')
  }

  const moveToPasswordChange = () => {
    navigate('/user-info/password')
  }

  const moveToUserStorages = (storageId: number) => {
    navigate(`/user-info/storages/${storageId}`)
  }

  return (
    <aside className="aside">
      <nav className="lnb">
        <ul id="tree">
          {!myArticles && (
            <Fragment>
              <li onClick={moveToUserInfo}>
                <p className="head">
                  <span className="on">개인정보수정</span>
                </p>
              </li>
              <li onClick={moveToPasswordChange}>
                <p className="head">
                  <span>비밀번호변경</span>
                </p>
              </li>
            </Fragment>
          )}
          {myArticles && (
            <li className="on">
              <p className="toggle head">
                <span>보관함관리</span>
                <i></i>
              </p>
              <MyArticlesStorageBox selectStorage={moveToUserStorages} />
            </li>
          )}
        </ul>
      </nav>
    </aside>
  )
}

export default UserInfoMenu
