import axios from 'axios'

import Page from '../../common/domain/Page'
import Publisher from '../domain/Publisher'
import PublisherRepository, { PublisherRequest } from './PublisherRepository'

class AxiosPublisherRepository implements PublisherRepository {
  async getPublishers(params: PublisherRequest): Promise<Page<Publisher>> {
    const { data } = await axios.get('/app/api/publishers', {
      params,
    })
    return {
      totalPages: data.totalPages,
      totalElements: data.totalElements,
      size: data.size,
      pageable: {
        pageNumber: 1,
      },
      content: data.content,
    }
  }
}

export default AxiosPublisherRepository
