import React, { MouseEvent, useCallback, useContext, useEffect, useState } from 'react'

import Pagination from '../../common/component/Pagination'
import { usePageable } from '../../common/hooks/usePageable'

import { getMaterialsWithMemo } from '../repository/MaterialsRepository'
import { updatedDate } from '../util/time-formatter'

import Material from '../domain/Material'
import TactoProjectDetail from '../domain/TactoProjectDetail'
import { Context } from '../provider/TactoProjectProvider'

type Props = {
  projectDetail: TactoProjectDetail
  openMaterialDetail: (material: Material) => void
  openAttachment: (e: MouseEvent<HTMLButtonElement>, material: Material) => void
  openMemo: (e: MouseEvent<HTMLButtonElement>, material: Material) => void
}

const MemosSection = ({ projectDetail, openMaterialDetail, openAttachment, openMemo }: Props) => {
  const { registerRefreshItems } = useContext(Context)!
  const [keyword, setKeyword] = useState<string>('')
  const [materials, setMaterials] = useState<Material[]>([])
  const { pageInfo, setupPage } = usePageable()

  const moveToPage = useCallback(
    (currentPage: number) => {
      if (projectDetail) {
        getMaterialsWithMemo(projectDetail.id, keyword, { page: currentPage, size: 10 }).then(
          (response) => {
            setupPage(response, currentPage)
            setMaterials(response.content)
          }
        )
      }
    },
    [projectDetail, keyword, setupPage]
  )

  const onSearchHandler = useCallback(() => moveToPage(0), [moveToPage])

  useEffect(() => {
    moveToPage(0)
    registerRefreshItems(() => moveToPage(0))
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <div className="tacto-project">
        <div className="topic-info-section">
          <div aria-label="section-title" className="title">
            메모
          </div>
        </div>
        <div className="topic-top-section">
          <div className="buttons" />
          <div className="search">
            <input
              type="text"
              placeholder="검색어를 입력해주세요"
              onChange={(e) => setKeyword(e.target.value)}
            />
            <button aria-label="search" onClick={onSearchHandler} />
          </div>
        </div>
        <div className="project-item-list">
          {materials.map((material) => (
            <div key={material.id} className="project-item-wrap">
              <div className="material" onClick={() => openMaterialDetail(material)}>
                <div className="title">{material.title}</div>
                <div className="content">
                  <div className="left-container">
                    <div>{material.publishYear}</div>
                    <div className="divider" />
                    <div>{material.publisher}</div>
                    <div className="divider" />
                    <div>{material.authors}</div>
                  </div>
                </div>
                <div className="attachment-memo-container">
                  <button
                    onClick={(e) => openAttachment(e, material)}
                    className={material.attachmentId ? 'existed' : ''}
                  >
                    <div className="icon attachment" />
                    첨부
                  </button>
                  <button
                    onClick={(e) => openMemo(e, material)}
                    className={material.memoId ? 'existed' : ''}
                  >
                    <div className="icon memo" />
                    메모
                  </button>
                  <div className="memo-title">{material.memoTitle}</div>
                </div>
                <div className="updated-at">{updatedDate(material.updatedAt)}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Pagination pageInfo={pageInfo} moveToPage={moveToPage} />
    </>
  )
}

export default MemosSection
