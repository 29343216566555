import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import NiceSelect from '../../common/component/NiceSelect'
import Location from '../../common/component/Location'
import InformationMenu from '../component/InformationMenu'
import { BbsButton, BbsReportButton } from '../component/BbsProtectedButton'

import { Context as UserContext } from '../../user/provider/UserInfoProvider'

import { imageHandler, quillModule } from '../../common/utils/QuillModule'
import { bbsRepository } from '../repository/BbsRepository'
import BbsItem from '../domain/BbsItem'
import { isEmpty } from '../../common/utils/TextUtils'

const DEFAULT_BBS_ITEM = {
  id: 0,
  title: '',
  content: '내용',
  status: '',
  type: '',
  createdDate: '',
  readCount: 0,
  commentCount: 0,
  userId: 0,
  writer: '',
  comments: [],
}

const BbsEditor = () => {
  const { type } = useParams()
  const [searchParam] = useSearchParams()
  const navigate = useNavigate()

  const { userInfo } = useContext(UserContext)!
  const quillRef = useRef<ReactQuill>(null)

  const location = useMemo(() => {
    switch (type) {
      case 'notices':
        return '공지사항'
      case 'faqs':
        return 'FAQ'
      case 'newses':
        return '뉴스'
      case 'reports':
        return '오류신고/개선의견/문의'
      default:
        return '공지사항'
    }
  }, [type])

  const searchOptions = useMemo(() => {
    switch (type) {
      case 'notices':
        return ['공지', '광고']
      case 'faqs':
        return ['검색', '사이트이용', '회원가입/로그인']
      case 'newses':
        return ['뉴스', '소개']
      case 'reports':
        return ['오류신고', '개선의견', '문의']
      default:
        return ['공지', '광고']
    }
  }, [type])

  const searchValues = useMemo(() => {
    switch (type) {
      case 'notices':
        return ['notice', 'ads']
      case 'faqs':
        return ['search', 'usage', 'join/login']
      case 'newses':
        return ['news', 'intro']
      case 'reports':
        return ['error', 'improvement', 'question']
      default:
        return ['notice', 'ads']
    }
  }, [type])

  const selectOptions = useMemo(() => {
    const optionKeys = searchValues
    const optionValues = searchOptions
    const optionsForSelect = new Map(
      optionKeys.map((optionKey, index) => [optionKey, optionValues[index]])
    )
    return optionsForSelect
  }, [searchOptions, searchValues])

  const [option, setOption] = useState<string>(searchOptions[0])
  const [detailType, setDetailType] = useState<string>(searchValues[0])
  const [bbsItem, setBbsItem] = useState<BbsItem>(DEFAULT_BBS_ITEM)

  const submit = useCallback(() => {
    if (isEmpty(bbsItem.title)) {
      alert('제목을 입력하세요.')
      return
    }
    if (isEmpty(bbsItem.content)) {
      alert('내용을 입력하세요.')
      return
    }
    const id = searchParam.get('id')
    let resultPromise: Promise<void>
    const bulletinBoardItemCommand = {
      type: type!,
      detailType,
      title: bbsItem.title,
      content: bbsItem.content ?? '',
    }
    if (id) {
      resultPromise = bbsRepository.updatePost(+id, {
        ...bulletinBoardItemCommand,
        writer: bbsItem.writer,
      })
    } else {
      resultPromise = bbsRepository.createPost({
        ...bulletinBoardItemCommand,
        writer: userInfo!.nickName,
      })
    }
    resultPromise.then(() => navigate(-1))
  }, [searchParam, type, detailType, bbsItem, userInfo, navigate])

  useEffect(() => {
    if (quillRef.current) {
      const editor = quillRef.current.getEditor()
      const toolbar = quillRef.current.getEditor().getModule('toolbar')
      toolbar.addHandler('image', imageHandler(editor))
    }
  }, [])

  useEffect(() => {
    const id = searchParam.get('id')
    if (id) {
      bbsRepository.getBulletinBoardDetail(+id).then((detail) => {
        const index = searchOptions.indexOf(detail.type)
        setOption(searchOptions[index])
        setDetailType(searchValues[index])
        setBbsItem(detail)
      })
    }
    // eslint-disable-next-line
  }, [searchParam])

  const SubmitButton = useMemo(() => {
    return type === 'reports' ? (
      <BbsReportButton writerUserId={bbsItem.userId} onClick={submit}>
        등록
      </BbsReportButton>
    ) : (
      <BbsButton writerUserId={bbsItem.userId} onClick={submit}>
        등록
      </BbsButton>
    )
  }, [type, submit, bbsItem])

  const CancelButton = useMemo(() => {
    return type === 'reports' ? (
      <BbsReportButton writerUserId={bbsItem.userId} onClick={() => navigate(-1)}>
        취소
      </BbsReportButton>
    ) : (
      <BbsButton writerUserId={bbsItem.userId} onClick={() => navigate(-1)}>
        취소
      </BbsButton>
    )
  }, [type, navigate, bbsItem])

  return (
    <main>
      <div className="container">
        <Location paths={[location]} />
        <div className="content-wrap">
          <InformationMenu />
          <section className="content">
            <h1 className="normal-head">{location}</h1>
            <div className="result-summary above">
              <fieldset className="post">
                <NiceSelect
                  ariaLabel="type-box"
                  options={selectOptions}
                  onSelectCallback={(value) => setDetailType(value)}
                  selectedValue={option}
                />
              </fieldset>
              <ul className="bbs-form">
                <input
                  type="text"
                  aria-label="title-input"
                  placeholder="제목을 입력해주세요"
                  value={bbsItem.title}
                  onChange={(event) =>
                    setBbsItem((prevState) => ({ ...prevState, title: event.target.value }))
                  }
                />
              </ul>
            </div>
            <ReactQuill
              ref={quillRef}
              theme="snow"
              value={bbsItem.content}
              onChange={(value) => setBbsItem((prevState) => ({ ...prevState, content: value }))}
              modules={quillModule}
            />
            <div className="button-wrap destro">
              {SubmitButton}
              {CancelButton}
            </div>
          </section>
        </div>
      </div>
    </main>
  )
}

export default BbsEditor
