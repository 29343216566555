import AxiosJournalRepository from './AxiosJournalRepository'

import Page from '../../common/domain/Page'
import Journal from '../domain/Journal'
import JournalCount from '../domain/JournalCount'

export interface JournalRequest {
  page: number
  size: number
  name?: string
  headCharacter?: string[]
  categoryId?: number
}

export interface JournalRepository {
  getJournals(params: JournalRequest): Promise<Page<Journal>>

  getJournal(journalId: number): Promise<Journal>

  getJournalCountPerCategory(): Promise<JournalCount[]>
}

export const journalRepository = new AxiosJournalRepository()
