import HeadCharButton from './HeadCharButton'
import { headCharacterMap } from '../constant/AuthorConstant'

export interface HeadCharButtonGroupParam {
  headChar: string[]
  setHeadChar: (headChar: string[]) => void
}

function HeadCharButtonGroup({ headChar, setHeadChar }: HeadCharButtonGroupParam) {
  return (
    <div className="filter-letter above">
      {Array.from(headCharacterMap.entries()).map((entry, index) => {
        const isSelected = headChar.join(',') === entry[1].join(',')
        return (
          <HeadCharButton
            key={`head-char-${index}`}
            className={isSelected ? 'on' : ''}
            headChar={entry[0]}
            headCharValue={entry[1]}
            setHeadChar={setHeadChar}
          />
        )
      })}
    </div>
  )
}

export default HeadCharButtonGroup
