import React, { useContext, useEffect, useState } from 'react'

import CoverCarousel from '../component/CoverCarousel'
import { ResourceTotalCount } from '../domain/ResourceTotalCount'
import { mainRepository } from '../repository/MainRepository'
import { Context as SearchConditionContext } from '../../article/provider/SearchConditionProvider'
import { useNavigate } from 'react-router-dom'
import NiceSelect from '../../common/component/NiceSelect'
import { serviceDescription } from '../../common/constant/ServiceConstant'
import BbsItem from '../../information/domain/BbsItem'
import { bbsRepository } from '../../information/repository/BbsRepository'
import { isEmpty, isNotEmpty } from '../../common/utils/TextUtils'

function Main() {
  const [resourceTotalCount, setResourceTotalCount] = useState<ResourceTotalCount>({
    journalsCount: 0,
    articlesCount: 0,
    publishersCount: 0,
    patentsCount: 0,
  })
  const [recentNewses, setRecentNewses] = useState<BbsItem[]>([])

  const searchConditions = useContext(SearchConditionContext)!
  const [searchText, setSearchText] = useState('')
  const [selectedSearchField, setSelectedSearchField] = useState<
    'article' | 'author' | 'journal' | 'publisher'
  >('article')

  useEffect(() => {
    mainRepository.getResourceTotalCount().then((response) => setResourceTotalCount(response))
  }, [])

  useEffect(() => {
    bbsRepository
      .getBulletinBoardItems('newses', {
        page: 0,
        size: 4,
        sort: `createdAt,desc`,
        searchType: '',
        key: 'title',
        value: '',
      })
      .then((response) => {
        setRecentNewses(response.content)
      })
  }, [])

  const navigator = useNavigate()

  const search = () => {
    if (isEmpty(searchText)) {
      alert('검색어를 입력하세요.')
      return
    }
    searchConditions.clearConditions()
    switch (selectedSearchField) {
      case 'article': {
        searchConditions.setSearchText(searchText)
        navigator('/article-search')
        break
      }
      case 'author': {
        navigator(`/authors?name=${encodeURI(searchText)}`)
        break
      }
      case 'journal': {
        navigator(`/journals?name=${encodeURI(searchText)}`)
        break
      }
      case 'publisher': {
        navigator(`/publishers?name=${encodeURI(searchText)}`)
        break
      }
    }
  }

  return (
    <main>
      <section className="neo-home-welcome">
        <div className="neo-container">
          <div className="neo-search">
            <fieldset aria-label="search-fields">
              <NiceSelect
                ariaLabel="search-type-select"
                options={
                  new Map([
                    ['article', '논문/특허'],
                    ['journal', '저널'],
                    ['author', '저자'],
                    ['publisher', '발행기관'],
                  ])
                }
                onSelectCallback={(value) => {
                  if (
                    value === 'article' ||
                    value === 'journal' ||
                    value === 'author' ||
                    value === 'publisher'
                  ) {
                    setSelectedSearchField(value)
                  }
                }}
              />
              <input
                type="text"
                onChange={(e) => setSearchText(e.target.value)}
                onKeyUp={(e) => {
                  if (e.key === 'Enter') search()
                }}
                placeholder="어떤 학술자료를 찾으시나요?"
                value={searchText}
              />
              {isNotEmpty(searchText) && (
                <button
                  className="clear"
                  onClick={() => setSearchText('')}
                  aria-label="clear-button"
                />
              )}
              <button className="search" onClick={search} aria-label="search-button" />
            </fieldset>
          </div>
          <ul aria-label="counts-summary" className="neo-summary">
            <li>
              <dl>
                <dt>논문</dt>
                <dd className="number">{resourceTotalCount.articlesCount.toLocaleString()}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>저널</dt>
                <dd className="number">{resourceTotalCount.journalsCount.toLocaleString()}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>발행기관</dt>
                <dd className="number">{resourceTotalCount.publishersCount.toLocaleString()}</dd>
              </dl>
            </li>
            <li>
              <dl>
                <dt>특허</dt>
                <dd className="number">{resourceTotalCount.patentsCount.toLocaleString()}</dd>
              </dl>
            </li>
          </ul>
          <div className="neo-intro">
            <hgroup>
              <h2>K·DISCOVERY</h2>
              <span>{serviceDescription}</span>
            </hgroup>
            <p className="description">
              AI-powered free research tool for scientific & humanities literature
            </p>
            <p>
              국내외 학술문헌/전문정보를 위한 인공지능 기반의 연구 지원 도구로 새로운 인사이트를
              제공합니다.
            </p>
          </div>
        </div>
      </section>
      <section className="neo-home-news">
        <div className="neo-container">
          <h2 className="neo-home">학술뉴스</h2>
          <ul className="neo-news">
            {recentNewses.map((news) => (
              <a
                key={`recent-news-${news.id}`}
                href={`/bbs/newses/${news.id}`}
                target="_blank"
                rel="noreferrer"
              >
                <dl>
                  <dt>{news.title}</dt>
                  <dd className="sub">{news.writer}</dd>
                  <dd className="sub">{news.createdDate}</dd>
                </dl>
              </a>
            ))}
          </ul>
          <a href={`/bbs/newses`} className="neo-news-more">
            <i>더 많은 뉴스</i>
          </a>
        </div>
      </section>
      <CoverCarousel />
    </main>
  )
}

export default Main
