import Article from '../domain/Article'
import ExcelArticleExporter from './ExcelArticleExporter'
import BibTexArticleExporter from './BibTexArticleExporter'
import EndNoteArticleExporter from './EndNoteArticleExporter'
import RefWorksArticleExporter from './RefWorksArticleExporter'

export default interface ArticleExporter {
  exportArticle(articles: Article[]): void
}

export type ExportType = 'Endnote' | 'RefWorks' | 'BibTex' | 'Excel'

export const exporterFactory = (type: ExportType): ArticleExporter => {
  switch (type) {
    case 'Excel':
      return excelArticleExporter
    case 'BibTex':
      return bibTexArticleExporter
    case 'Endnote':
      return endNoteArticleExporter
    case 'RefWorks':
      return refWorksArticleExporter
    default:
      return excelArticleExporter
  }
}
const excelArticleExporter = new ExcelArticleExporter()
const bibTexArticleExporter = new BibTexArticleExporter()
const endNoteArticleExporter = new EndNoteArticleExporter()
const refWorksArticleExporter = new RefWorksArticleExporter()
